import { Autocomplete, CircularProgress } from "@mui/material"
import {  memo } from "react"
import { useDispatch, useSelector } from 'react-redux'

import CustomInput from "../../components/inputs/CustomInput"
import { USER_ROLES } from "../../utils/constants"
import { findNameByRole, getAllowedRoles, titleCase } from "../../utils/helper"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"


import TeachersFields from "./techers/TechersField"
import DonorFields from "./donor/DonorFields"
import NGOFields from "./ngo/NGOFields"
import GovFields from "./gov/GovFields"




const CreateUi = ({ title, modalKey, isUpdate, fields, setFields, loading, onSubmit, isRolePredefined }) => {
    const { user } = useSelector(state => state)

    let allowedRoles = getAllowedRoles(user.data.role).map((key) => ({ _id: USER_ROLES[key], label: titleCase(key) }))



    const dispatch = useDispatch()
    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading && <CenteredBox><CircularProgress /></CenteredBox>}
            {
                <>
                    <CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.name}
                        onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                        type="text"
                        label={"Name*"}

                    />

                    {!isUpdate && <CustomInput
                        disabled={loading}
                        value={fields.email}
                        onChange={(e) => setFields({ ...fields, err: '', email: e.target.value })}
                        type="text"
                        label={"Email*"}

                    />}
                    {!isUpdate && <CustomInput
                        disabled={loading}
                        value={fields.password}
                        onChange={(e) => setFields({ ...fields, err: '', password: e.target.value })}

                        type="password"
                        label={"Password*"}

                    />}


                    {!isUpdate && !isRolePredefined && <Autocomplete

                        key={loading}
                        disableClearable
                        options={allowedRoles}
                        value={findNameByRole(fields.role)}
                        // id="user-role-id"
                        // name="user-role-id"
                        onChange={(e, newVal) => {
                            setFields({ ...fields, role: newVal ? newVal._id : null })
                        }}

                        sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                        renderInput={(params) => <CustomInput placeholder="Select Role*" {...params} label="Select Role*" />}
                    />}
                    {
                        ![ USER_ROLES.TEACHER, USER_ROLES.DONOR, USER_ROLES.NGO, USER_ROLES.GOVERMENT].includes(fields.role) && <>
                            <CustomInput
                                disabled={loading}
                                value={fields.phone}
                                onChange={(e) => setFields({ ...fields, err: '', phone: e.target.value })}

                                type="number"
                                label={"Phone*"}

                            />
                            

                        </>
                    }
                   

                    {
                        !isUpdate && fields.role == USER_ROLES.TEACHER && <TeachersFields fields={fields} setFields={setFields} loading={loading} />
                    }

                    {
                        !isUpdate && fields.role == USER_ROLES.DONOR && <DonorFields fields={fields} setFields={setFields} loading={loading} />
                    }

                    {
                        !isUpdate && fields.role == USER_ROLES.NGO && <NGOFields fields={fields} setFields={setFields} loading={loading} />
                    }

                    {
                        !isUpdate && fields.role == USER_ROLES.GOVERMENT && <GovFields fields={fields} setFields={setFields} loading={loading} />
                    }




                </>}

        </CustomDialog>


    </>
}
export default memo(CreateUi)