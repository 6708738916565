import {  CircularProgress } from "@mui/material"
import {  memo } from "react"

import CustomInput from "../../../components/inputs/CustomInput"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"







const CreateUi = ({ title, modalKey, fetchLoading, isUpdate, fields, setFields, loading, onSubmit }) => {

    return <>

        <CustomDialog
            id={modalKey}
            loading={loading || fetchLoading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`Create ${title}`}
            closeText="Close"
            confirmText={`Create`}
        >

            {(loading || fetchLoading) && <CenteredBox><CircularProgress /></CenteredBox>}
            {!fetchLoading &&
                <>
                    <CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.name}
                        onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                        type="text"
                        label={" Name*"}

                    />
                     <CustomInput
                        
                        disabled={loading}
                        value={fields.email}
                        onChange={(e) => setFields({ ...fields, err: '', email: e.target.value })}
                        type="email"
                        label={" Email*"}

                    />
                     <CustomInput
                        
                        disabled={loading}
                        value={fields.phone}
                        onChange={(e) => setFields({ ...fields, err: '', phone: e.target.value })}
                        type="text"
                        label={" Phone No.*"}

                    />
                    <CustomInput
                        
                        disabled={loading}
                        value={fields.designation}
                        onChange={(e) => setFields({ ...fields, err: '', designation: e.target.value })}
                        type="text"
                        label={" Designation*"}

                    />


                </>}

        </CustomDialog>


    </>
}
export default memo(CreateUi)