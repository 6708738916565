import { Box, Paper, Typography,ButtonBase } from "@mui/material"
import { CenteredBox } from "../../components/layouts/OneViewBox"
function isInt(n) {
    return Number(n) === n && n % 1 === 0;
}
const countParser = (count) => {
    // if (count >= 1000) {
    //     return ((count / 1000)).toFixed(1) + "k"
    // }
    const parsedCount = Number(count);

    // Check if parsedCount is a number and handle the case when count is not a number
    if (isNaN(parsedCount)) {
        return count; // Return the original value if it's not a number
    }
    return isInt(parsedCount) ? parsedCount : parsedCount.toFixed(1);
    // return isInt(count) ? count : count?.toFixed(1);  
}
const Counts = ({ title, count,active, subtitle, unit,...props }) => {

    return <Paper component={props.onClick?ButtonBase :Box} active={active} sx={{ height: "100%",width:"100%" }}  {
        ...props
    }>

        <CenteredBox sx={{ flexDirection: "column"}} >
            <Typography variant="h2" lineHeight="100%" color="primary" py={1} sx={{ display: "flex", alignItems: "flex-end" }}>
                {countParser(count)} <Typography variant="h4" color="primary">{unit}</Typography>
            </Typography>
            <Typography variant="h6" align="center" >
                {title}
            </Typography>
            {subtitle && <Typography variant="caption" align="center" >
                {subtitle}
            </Typography>}
        </CenteredBox>

    </Paper>
}
export default Counts