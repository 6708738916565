import { Box, Chip, CircularProgress, ListItem } from "@mui/material"
import { useCallback, useState } from "react"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"

import NoDataComponent from "../../../components/layouts/NoDataComponent"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"
import { getCampApi } from "../../../apis/camp.api"
import { StyledSearchBar } from "../../../components/inputs/SearchBar"
import BeneficiaryDetailedViewController from "../../beneficiary/detailedview/DetailedViewController"
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"


const BeneficiaryFromCampUi = ({ filters, loading, setFilters, list, modalKey, onSubmit, title }) => {
    const dispatch = useDispatch()

    const onBenificaryClick = useCallback((beneficiaryId) => {
        dispatch(openModal(<BeneficiaryDetailedViewController
            id={beneficiaryId}

        />, "md", false, "beneficiary-detailed-view"))
    }, [])
    return <>
        <CustomDialog
            id={modalKey}
            loading={loading}
            title={title}
            closeText="Close"
            onSubmit={onSubmit}
            confirmText="Add"
            dialogProps={{ sx: { height: "1000px", overflow: "hidden" } }}

        >
            <Box sx={(theme) => ({ height: "100%", width: "100%", display: "flex", flexDirection: "column", overflow: "hidden" })}>
                <Box mt={1} mb={3}>

                    <AsyncDropDown


                        lazyFun={getCampApi}

                        OptionComponent={({ option, ...rest }) => {
                            return <ListItem {...rest}>{option.name}</ListItem >
                        }}
                        onChange={async (changedVal) => {
                            setFilters({ ...filters, camp: changedVal ? changedVal._id : null, })
                        }}
                        titleKey={'name'}
                        valueKey={"_id"}
                        InputComponent={(params) => <StyledSearchBar placeholder={"Select Camp"} {...params} margin="none" />}
                    />

                </Box>

                {loading ? <CenteredBox><CircularProgress size={30} /></CenteredBox> :

                    <>
                        <Box sx={{ display: "flex", flexWrap: "wrap", overflowY: "auto",  justifyContent: "flex-start", alignItems: "flex-start" }}>
                            {
                                list && list.length > 0 ? list.map((item) => <Box><Chip onClick={() => {
                                    onBenificaryClick(item._id)
                                }} sx={{ m: 1 }} label={item.name + ' ' + item.surname} key={item._id} /></Box>) : <NoDataComponent message="No Data Found" />
                            }
                        </Box>
                    </>
                }
            </Box>
        </CustomDialog>
    </>
}
export default BeneficiaryFromCampUi