import { useDispatch, useSelector } from "react-redux"
import CreateController from "../CreateController"
import { openModal } from "../../../store/actions/modalAction"
import { Button, IconButton, MenuItem } from "@mui/material"
import { Edit } from "@mui/icons-material"
import { BENIFICIARY_STATUS } from "../../../utils/beneficiary.constant"
import MODULES from "../../../utils/module.constant"

const BeneficiaryCreateButton = ({callBack=()=>{} }) => {
    const dispatch = useDispatch()

    const { user } = useSelector(state => state)
    const allowedModules = user.data.modules ?? []



    const onCreateButtonClick = () => {
        dispatch(openModal(<CreateController modal callBack={callBack} />, "lg", false, "beneficiary"))
    }
    if (

        !allowedModules.includes(MODULES.BENIFICIARY_UPDATE))
        return <></>

    return <Button variant="contained" onClick={onCreateButtonClick}>Create</Button>
}
export default BeneficiaryCreateButton