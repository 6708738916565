import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { addUserApi, getUserByIdApi, updateUserField } from "../../apis/user.api"
import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"
import { REGIONS_TYPE, USER_ROLES } from "../../utils/constants"
import { addSecretsApi, getSecretsByIdApi, updateSecretsField } from "../../apis/secrets.api"
import { findObjectKeyByValue } from "../../utils/helper"
import { addProductApi } from "../../apis/product.api"

const CreateController = ({ callBack = () => { }, id, type }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()

    const title = "Products "
    const modalKey = 'product'
    const createApi = addProductApi


    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        // id,
        name: '',
        // short_name: "",
        // type: type ?? REGIONS_TYPE.COUNTRY,
        parent: null,

    })
    const [originalDocument, setOriginalDocument] = useState({


    })


    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.name,
            field: ' Name',
        },
        // {
        //     required: true,
        //     value: fields.type,
        //     field: 'Region Type'
        // },

        // {

        //     value: fields.parent,
        //     field: (findObjectKeyByValue(fields.type, REGIONS_TYPE) ?? 'Parent ') + ' Region',
        // }
    ]), [fields])





    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi(fields),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }






    const onSubmit = async (e) => {
        e.preventDefault()


        createFunction()

    }



    return <CreateUi modalKey={modalKey} title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)