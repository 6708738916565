import { Autocomplete, Box, Divider, Grid, MenuItem, Paper, Skeleton, Typography, styled, useTheme } from "@mui/material"

import { Bar } from "react-chartjs-2"

const InclusionStateChartUi = ({ list, loading }) => {
    const theme = useTheme()
    const labels = ['I1', 'I2', 'I3'];
    const finalData = [0, 0, 0]


    if (list && Array.isArray(list) && list.length > 0) {
        for (let item of list) {
            finalData[item._id - 1] = item.count
        }
    }
    const options = {
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: true,
                color: "black",
                formatter: Math.round,
                anchor: "end",
                offset: -20,
                align: "start"
            }
        },
        scales: {
            x: {

                grid: {
                    // offset:false,
                    display: ""
                },

            },
            y: {

                min: 0,
                max: finalData.reduce((i, item) => { return i + item }, 5),
                grid: {

                    display: ""
                },

            }
        }
    }

    const data = {
        labels,
        datasets: [
            {
                label: 'Total Beneficiary',
                data: finalData,
                backgroundColor: theme.palette.primary.main,
                barPercentage: 1,
                base: 0
            }
        ],
    };


    if (loading) {
        return <Paper component={Box} p={2}>
            <Skeleton variant="rounded" height="300px" width="100%" >

            </Skeleton>
        </Paper>
    }
    if (!(list && Array.isArray(list) && list.length > 0)) {
        return <></>
    }
    return <>


        <Paper component={Box} p={3}>
            <Typography>
                Inclusion Progress
            </Typography>

            <Box mt={1} sx={{ minHeight: "300px" }}  className="table">
                <Bar options={options} data={data} style={{ height: "100%" }} />
            </Box>
            <Divider sx={{ mt: 2, mb: 2 }} mb={2} component={Box} mt={2} />
            <Grid container >
                <Grid item xs={1} spacing={2}>
                    <Typography variant="caption" fontWeight="bold">
                        I1 :
                    </Typography>
                </Grid>
                <Grid item xs={11}>
                    <Typography variant="caption" >
                        Perform Domestic Activity
                    </Typography>
                </Grid>


                <Grid item xs={1} spacing={2}>
                    <Typography variant="caption" fontWeight="bold">
                        I2 :
                    </Typography>
                </Grid>
                <Grid item xs={11}>
                    <Typography variant="caption" >
                    Group Leader - Taking Initiative
                    </Typography>
                </Grid>




                <Grid item xs={1} spacing={2}>
                    <Typography variant="caption" fontWeight="bold">
                        I3 :
                    </Typography>
                </Grid>
                <Grid item xs={11}>
                    <Typography variant="caption" >
                   Independent
                    </Typography>
                </Grid>



               
            </Grid>
        </Paper>
    </>
}
export default InclusionStateChartUi