import { memo, useCallback, useEffect, useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"



import { addCampApi, createCampImagesApi, getCampByIdApi, updateCampApi } from "../../../apis/camp.api"
import moment from "moment"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants"

const CreateController = ({ callBack=()=>{}, id,name }) => {
    const validate = useValidate()
    const dispatch = useDispatch()

    const modalKey = 'create-camp-images'
    const title = " of  "+ name +" Camp"

    const createApi = createCampImagesApi
    
    const getByIdApi = getCampByIdApi

    const [loading, setLoading] = useState(false)

    const [fetchLoading, setFetchLoading] = useState(false)

    const defaultImages = useMemo(()=>[
        {
            name: 'innogration_photo',
            url: ''
        },
        {
            name: 'team_photo',
            url: ''
        },
        {
            name: 'place_photo_1',
            url: ''
        },
        {
            name: 'place_photo_2',
            url: ''
        },
        {
            name: 'child_photo_1',
            url: ''
        },
        {
            name: 'child_photo_2',
            url: ''
        },
        {
            name: 'child_photo_3',
            url: ''
        },
        {
            name: 'other_1',
            url: ''
        },
        {
            name: 'other_2',
            url: ''
        },
        {
            name: 'other_3',
            url: ''
        },
    ],[])
    const [fields, setFields] = useState({
        err: '',
        camp_id: id,
        images: defaultImages




    })
    


    



    const createFunction = useCallback(async (name,url) => {
        dispatch(
            callApiAction(
                async () => await createApi({ camp_id:id,name,url }),
                async (response) => {
                    console.log("REPSPKME",response);
                    await callBack(response)
                    
                    
                },
                (err) => {
                    
                    dispatch(callSnackBar(err,SNACK_BAR_VARIETNS.error))
                }
            )
        )
    },[id,dispatch])


 



   

    const fetchById = (id) => {
        setFetchLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {

                    const images = [...defaultImages]
                    if (response.images && Array.isArray(response.images) && response.images.length > 0) {

                        for(let image of response.images)
                        {
                            
                            let i = 0
                            for(let defaultImage of images){

                                if(image.name==defaultImage.name){
                                    
                                    images[i].url = image.url
                                }
                                i++
                            }
                        }                    
                    }
                    setFields({  ...response,images,camp_id:id })
                    
                    setFetchLoading(false)

                },
                (err) => {
                    setFields({ ...fields, err })
                    setFetchLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])


    return <CreateUi createFunction={createFunction} modalKey={modalKey} title={title} fetchLoading={fetchLoading} isUpdate={id} loading={loading} fields={fields}  setFields={setFields} />
}
export default memo(CreateController)