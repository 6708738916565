import { Autocomplete, Box, Button, CircularProgress, MenuItem, Typography } from "@mui/material"
import { forwardRef, Fragment, memo, useRef } from "react"
import SignaturePad from 'react-signature-canvas'
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import ImageComponent from "../../../components/inputs/ImageComponent"





const UpdateSignatureUI = forwardRef(({ title, oldSignature, onClear, modalKey, fetchLoading, isUpdate, fields, setFields, loading, onSubmit }, ref) => {



    return <>

        <CustomDialog
            id={modalKey}
            loading={loading || fetchLoading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`Upload Signature`}
            closeText="Close"
            confirmText={`Upload Signature`}
        >

            {(loading || fetchLoading) && <CenteredBox><CircularProgress /></CenteredBox>}
            {!fetchLoading &&
                <>
                    <CenteredBox sx={{ flexDirection: "column" }}   >
                       {oldSignature && <Box>
                            <Typography>Existing Signature</Typography>
                            <ImageComponent imgStyle={{ height: "100px", width: "300px", objectFit: "fill" }} src={oldSignature} />
                        </Box>}
                        <Box>
                            <Typography>New Signature</Typography>
                            <SignaturePad height="100px" width="300px" canvasProps={{ height: "100px", width: "300px", style: { border: "1px solid black", } }}
                                ref={ref}
                            />
                        </Box>
                        <Box sx={{ height: "100%" }} >
                            <Button variant="contained" onClick={onClear}>
                                Clear
                            </Button>
                        </Box>
                    </CenteredBox>
                </>}

        </CustomDialog>


    </>
})
export default memo(UpdateSignatureUI)