import { memo, useCallback, useEffect, useRef, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";

import { closeModal, openModal } from "../../../store/actions/modalAction";

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import { Check, Delete, Edit, Info, MoreVert } from "@mui/icons-material";
import moment from "moment";
import fileDownload from "js-file-download";
import {
  BENIFICIARY_STATUS,
  EVENT_STATUS,
} from "../../../utils/beneficiary.constant"
import { getEventApi } from "../../../apis/event.api";
import ListBeneficiaryController from "./ListBeneficiaryController";

const ActionComponent = memo(({ params, setParams, modalKey }) => {
  const { user } = useSelector((state) => state);
  const allowedModules = user.data.modules ?? [];
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const onViewBeneficiaryVideos = () => {
    dispatch(
      openModal(
        <ListBeneficiaryController
          id={params._id}
        //   callBack={(count) => {
        //     setParams({
        //       ...params,
        //       beneficiaries: params.beneficiaries + count,
        //     });
        //   }}
        />,
        "md",
        false,
        "event-beneficiary"
      )
    );
  };

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Box sx={{ width: "100%", alignItems: "center", display: "flex" }}>
      <Tooltip title="Video Counts">
        <IconButton
          variant="contained"
          size="inherit"
          onClick={onViewBeneficiaryVideos}
        >
          <Info color="primary" />
        </IconButton>
      </Tooltip>
    </Box>
  );
});

const ListController = () => {
  const dispatch = useDispatch();

  const title = "Event Video Summary ";
  const modalKey = "video-summary";
  const fetchApi = getEventApi;

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "date",
        label: "Event Date",
        align: "left",
        sort: true,
        renderValue: (params, setParams) =>
          moment(params.date).format("DD/MM/YYYY"),
      },
      {
        id: 2,
        fieldName: "name",
        label: "Event Name",
        align: "left",
        sort: true,
      },
      {
        id: 3,
        fieldName: "status",
        label: "Status",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => (
          <Chip
            size="small"
            color={params.status == EVENT_STATUS.CLOSED ? "success" : "warning"}
            label={
              params.status == EVENT_STATUS.CLOSED
                ? "Completed"
                : "Not Completed"
            }
          />
        ),
      },
      {
        id: 4,

        fieldName: "action",
        minWidth: "200px",
        label: "Total Beneficiaries",
        align: "left",
        renderValue: (params, setParams) => params?.beneficiaries,
      },
      {
        id: 5,
        fieldName: "",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            modalKey={modalKey}
            params={params}
            setParams={setParams}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["name"],
    sort: "camp_date",
    donation_event_id: null,
    camp: null,
    country: null,
    state: null,
    district: null,
    status: null,
    taluka: null,

    udise_file: null,
    photo: null,
    aadhar_file: null,
    income_certi: null,

    sortDirection: -1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [exportLoading, setexportLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = (isExport) => {
    if (!isExport) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await fetchApi({ ...filters }),
          (response) => {
            setList(response);
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        )
      );
    } else {
      setexportLoading(true);
      dispatch(
        callApiAction(
          async () => await fetchApi({ ...filters, csv: true }),
          (response) => {
            fileDownload(response, "beneficiary.csv");
            setexportLoading(false);
          },
          (err) => {
            console.log(err);
            setexportLoading(false);
          },
          true
        )
      );
    }
  };
  
  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
          filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;
