import { memo, useCallback, useEffect, useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"
import CreateUi from "./CampDetailedViewUI"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"



import { addCampApi, createCampImagesApi, getCampByIdApi, updateCampApi } from "../../../apis/camp.api"


const CampDetailedViewUI = ({ callBack, id }) => {
    const validate = useValidate()
    const dispatch = useDispatch()

    const modalKey = 'camp-details'
    const title = " Camp Details"



    const getByIdApi = getCampByIdApi

    const [loading, setLoading] = useState(false)

    const [fetchLoading, setFetchLoading] = useState(true)

    const defaultImages = useMemo(() => [
        {
            name: 'innogration_photo',
            url: ''
        },
        {
            name: 'team_photo',
            url: ''
        },
        {
            name: 'place_photo_1',
            url: ''
        },
        {
            name: 'place_photo_2',
            url: ''
        },
        {
            name: 'child_photo_1',
            url: ''
        },
        {
            name: 'child_photo_2',
            url: ''
        },
        {
            name: 'child_photo_3',
            url: ''
        },
        {
            name: 'other_1',
            url: ''
        },
        {
            name: 'other_2',
            url: ''
        },
        {
            name: 'other_3',
            url: ''
        },
    ], [])

    const [data, setData] = useState(null)


    const [step, setStep] = useState(0)












    const fetchById = (id) => {
        setFetchLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id, withBeneficiaries: true }),
                async (response) => {

                    const images = [...defaultImages]
                    if (response.images && Array.isArray(response.images) && response.images.length > 0) {

                        for (let image of response.images) {

                            let i = 0
                            for (let defaultImage of images) {

                                if (image.name == defaultImage.name) {

                                    images[i].url = image.url
                                }
                                i++
                            }
                        }
                    }
                    setData({ ...response, images, camp_id: id })

                    setFetchLoading(false)

                },
                (err) => {
                    setData({ ...data, err })
                    setFetchLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])


    return <CreateUi modalKey={modalKey} step={step} setStep={setStep} title={title} fetchLoading={fetchLoading} isUpdate={id} loading={loading} data={data} setData={setData} />
}
export default memo(CampDetailedViewUI)