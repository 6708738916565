import { Box, CircularProgress, Tab, Tabs } from "@mui/material"
import { useState } from "react"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import EventDetails from "./EventDetails"
import UserProfileViewCommonController from "../../user/UserProfileViewCommonController"
import BeneficiaryDetails from "./BeneficiaryDetails"
import ImageDetails from "./ImageDetails"


const DetailedViewMain = ({ data, loading, setData, modalKey }) => {
    const [tab, setTab] = useState(0)
    const handleTabChange = (e, newVal) => {
        setTab(newVal)
    }


    return <>
        <CustomDialog
            id={modalKey}
            loading={loading}
            title={`Events Details`}
            closeText="Close"
            dialogProps={{width:"100%"}}

        >
            <Box sx={{ width: "100%",overflow:"hidden" }}>
                <Tabs
                    sx={{ width: '100%', position: "sticky", top: "0px", background: "white", borderBottom: 1, borderColor: "divider", zIndex: 11 }}
                    scrollButtons
                    allowScrollButtonsMobile

                    value={tab}
                    onChange={handleTabChange}
                >
                    <Tab label="Event Details" />
                    <Tab label="Beneficiaries" />
                    <Tab label="Gallery" />
                </Tabs>
            </Box>
            {loading ? <CenteredBox><CircularProgress size={30} /></CenteredBox> :
                <Box mt={3} sx={{ width: '100%' }}>
                    {
                        <EventDetails show={tab == 0} data={data} />
                    }
                    {
                        <BeneficiaryDetails show={tab == 1} data={data} />
                    }
                    {
                        <ImageDetails show={tab == 2} data={data} />
                    }

                </Box>}
        </CustomDialog>
    </>
}
export default DetailedViewMain