import { Autocomplete, Box, Button, ButtonBase, Chip, CircularProgress, Grid, IconButton, LinearProgress, MenuItem, Paper, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Typography, styled } from "@mui/material"
import { Fragment, memo, useCallback, useState } from "react"

import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { Delete, HideImageOutlined, Image, Info, People, Upload } from "@mui/icons-material"
import { createFullName, toTitleCase } from "../../../utils/helper"
import ImageComponent from "../../../components/ImageComponent"
import moment from "moment"
import { CAMP_STATUS } from "../../../utils/beneficiary.constant"
import { useDispatch, useSelector } from "react-redux"
import DetailedViewController from "../../beneficiary/detailedview/DetailedViewController"
import NoDataComponent from "../../../components/layouts/NoDataComponent"
import { openModal } from "../../../store/actions/modalAction"
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../../utils/constants"
import { deleteCampStaffField } from "../../../apis/camp.api"
import { callApiAction } from "../../../store/actions/commonAction"
import { callSnackBar } from "../../../store/actions/snackbarAction"


const ImageUploadComponent = memo(({ src, name = "" }) => {



    return <Box component={Paper} sx={{ width: "100%", background: "#e5e5e5" }} p={2}>
        <Typography variant="subtitle2" lineHeight="100%" >{toTitleCase(name?.replace(/_/g, " "))}</Typography>
        <Box component={Paper} elevation={0} mt={1} mb={1} sx={{ display: "flex", width: "100%", position: 'relative', paddingTop: '75%', border: "1px solid gray" }}>
            <Box sx={{ position: "absolute", top: "0px", left: "0px", right: "0px", width: "100%", height: "100%" }}>
                {src && src != "" ? <ImageComponent imgStyle={{ height: "100%", width: "100%", objectFit: "contain" }} src={src} /> : <CenteredBox>
                    <Image />
                </CenteredBox>}


            </Box>
        </Box>

    </Box>
})

const CampBasicDetailsComponent = memo(({ data, show }) => {
    return <Box sx={{ width: "100%", display: show ? "block" : "none" }} >
        <Box >
            <Grid container spacing={2} >
                <Grid item xs={12} sm={6} >
                    <Box elevation={0} component={Paper} border="1px solid gray" sx={{ height: "100%" }} p={2}>
                        <Grid container spacing={2} >
                            <Grid item xs={5} sm={5} >
                                <Typography variant="body1" lineHeight="130%" textTransform="capitalize" fontWeight="600" >Camp Name:</Typography>
                            </Grid>
                            <Grid item xs={7} sm={7} >
                                <Typography variant="body1" lineHeight="130%" textTransform="capitalize" fontWeight="500">{data?.name ?? "NA"}</Typography>
                            </Grid>


                            <Grid item xs={5} sm={5} >
                                <Typography variant="body1" lineHeight="130%" textTransform="capitalize" fontWeight="600" >Start Date:</Typography>
                            </Grid>
                            <Grid item xs={7} sm={7} >
                                <Typography variant="body1" lineHeight="130%" textTransform="capitalize" fontWeight="500">{data?.start_date ? moment(data.start_date).format('DD/MM/YYYY') : "NA"}</Typography>
                            </Grid>


                            <Grid item xs={5} sm={5} >
                                <Typography variant="body1" lineHeight="130%" textTransform="capitalize" fontWeight="600" >End Date:</Typography>
                            </Grid>
                            <Grid item xs={7} sm={7} >
                                <Typography variant="body1" lineHeight="130%" textTransform="capitalize" fontWeight="500">{data?.end_date ? moment(data.end_date).format('DD/MM/YYYY') : "NA"}</Typography>
                            </Grid>

                            <Grid item xs={5} sm={5} >
                                <Typography variant="body1" lineHeight="130%" textTransform="capitalize" fontWeight="600" >Status:</Typography>
                            </Grid>
                            <Grid item xs={7} sm={7} >
                                <Typography variant="body1" lineHeight="130%" textTransform="capitalize" fontWeight="500">{data?.staus == CAMP_STATUS.CLOSED ? <Chip color="success" size="small" label="Closed" /> : <Chip size="small" color="warning" label="Open" />}</Typography>
                            </Grid>


                        </Grid>
                    </Box>

                </Grid>
                <Grid item xs={12} sm={6} >
                    <Box elevation={0} component={Paper} border="1px solid gray" sx={{ height: "100%" }} p={2}>
                        <Grid container spacing={2}  >
                            <Grid item xs={5} sm={5} >
                                <Typography variant="body1" lineHeight="130%" textTransform="capitalize" fontWeight="600" >Camp Head:</Typography>
                            </Grid>
                            <Grid item xs={7} sm={7} >
                                <Typography variant="body1" lineHeight="130%" textTransform="capitalize" fontWeight="500">{data?.camp_head?.name}</Typography>
                            </Grid>


                            <Grid item xs={5} sm={5} >
                                <Typography variant="body1" lineHeight="130%" textTransform="capitalize" fontWeight="600" >Camp Coordinator:</Typography>
                            </Grid>
                            <Grid item xs={7} sm={7} >
                                <Typography variant="body1" lineHeight="130%" textTransform="capitalize" fontWeight="500">{data?.camp_coordinator?.name??"NA"}</Typography>
                            </Grid>


                            <Grid item xs={5} sm={5} >
                                <Typography variant="body1" lineHeight="130%" textTransform="capitalize" fontWeight="600" >Camp Address:</Typography>
                            </Grid>
                            <Grid item xs={7} sm={7} >
                                <Typography sx={{ width: "100%", textOverflow: "ellipsis" }} variant="body1" lineHeight="130%" textTransform="capitalize" fontWeight="500">{`${data?.venue}, ${data?.taluka?.name}, ${data?.district?.name}, ${data?.state?.name}, ${data?.country?.name} `}</Typography>
                            </Grid>








                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    </Box>
})
const ImagesListComponent = memo(({ data, show }) => {
    return <Box sx={{ width: "100%", display: show ? "block" : "none" }} >
        <Box>
            <Grid container spacing={2}>
                {
                    data?.images && data?.images?.map((item) => {
                        return <Grid item xs={6} md={4} key={item.name} >
                            <ImageUploadComponent src={item.url} name={item.name} />
                        </Grid>
                    })
                }
            </Grid>
        </Box>

    </Box>
})




const BeneficiaryRow = memo(
    ({ beneficiary_id, name, response, onBenificaryClick, index }) => {


        return <TableRow   >
            <TableCell>
                {index + 1}
            </TableCell>
            <TableCell sx={{ textTransform: "capitalize" }} >
                {name}
            </TableCell>

            <TableCell sx={{ textTransform: "capitalize" }} >
                {response?"Yes":"No"}
            </TableCell>



            <TableCell>
                <IconButton onClick={() => {
                    onBenificaryClick(beneficiary_id)
                }}>
                    <People />
                </IconButton>
            </TableCell>

        </TableRow>
    }
)

const BeneficiaryDetails = ({ data, show }) => {
    const dispatch = useDispatch()
    const onBenificaryClick = useCallback((beneficiaryId) => {
        dispatch(openModal(<DetailedViewController
            id={beneficiaryId}

        />, "md", false, "beneficiary-detailed-view"))
    }, [])
    return <>
        <Box sx={{ display: show ? "block" : "none", width: "100%" }}>
            <Typography fontWeight="bold" variant="body1">
                Total: {data?.beneficiaries && Array.isArray(data.beneficiaries) ? data.beneficiaries.length : 0}
            </Typography>





            <Table sx={{ width: "100%", overflowX: "scroll" }}>

                <TableHead sx={{ background: "#f2f2f2" }} >
                    <TableRow>
                        <TableCell sx={{ minWidth: "100px" }}>Index No</TableCell>
                        <TableCell sx={{ minWidth: "150px" }}>Name</TableCell>
                        <TableCell sx={{ minWidth: "150px" }}>Device Response</TableCell>

                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data?.beneficiaries && Array.isArray(data.beneficiaries) ? data.beneficiaries.map((item, index) => <BeneficiaryRow
                            index={index}
                            key={item._id}
                            beneficiary_id={item}
                            response={item.nu_device_responce}
                            name={createFullName(item.surname, item.name) ?? "NA"}

                            onBenificaryClick={onBenificaryClick}
                        />) : <NoDataComponent message="No Data Found" />
                    }

                </TableBody>
            </Table>
        </Box>
    </>
}



const StaffRow = memo(
    ({ name, email, phone, designation, index, id, camp_id }) => {

        const dispatch = useDispatch()
        const [data, setData] = useState({ name, email, phone, designation })
        const { user } = useSelector((state) => state)
        const [loading, setLoading] = useState(false)

        const deleteFun = useCallback(async () => {
            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await deleteCampStaffField({ camp_id: camp_id, staff_id: id }),
                    async (response) => {
                        setData({})

                        setLoading(false)
                    },
                    (err) => {
                        setLoading(false)
                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    }
                )
            )
        }, [id, dispatch])

        if (!data.name) {
            return <></>
        }
        return <TableRow   >
            <TableCell>
                {index + 1}
            </TableCell>
            <TableCell sx={{ textTransform: "capitalize" }} >
                {name}
            </TableCell>
            <TableCell>
                {email}
            </TableCell>
            <TableCell sx={{ textTransform: "capitalize" }} >
                {phone}
            </TableCell>
            <TableCell sx={{ textTransform: "capitalize" }} >
                {designation}
            </TableCell>
            <TableCell>
                {
                    loading && <CircularProgress  size={20} />
                }
                {user.data.role == USER_ROLES.ADMIN  && !loading && <IconButton onClick={deleteFun}>
                    <Delete color="error" />
                </IconButton>}
            </TableCell>

        </TableRow>
    }
)

const BeneficiaryStaffDetails = ({ data, show }) => {
    const dispatch = useDispatch()

    return <>
        <Box sx={{ display: show ? "block" : "none", width: "100%" }}>
            {/* <Typography fontWeight="bold" variant="body1">
                Total: {data?.staff && Array.isArray(data.staff) ? data.staff.length : 0}
            </Typography> */}





            <Table sx={{ width: "100%", overflowX: "scroll" }}>

                <TableHead sx={{ background: "#f2f2f2" }} >
                    <TableRow>
                        <TableCell sx={{ minWidth: "100px" }}>Index No</TableCell>
                        <TableCell sx={{ minWidth: "150px" }}>Name</TableCell>
                        <TableCell sx={{ minWidth: "150px" }}>Email</TableCell>
                        <TableCell sx={{ minWidth: "150px" }}>Phone</TableCell>
                        <TableCell sx={{ minWidth: "150px" }}>Designation</TableCell>

                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.staff && Array.isArray(data.staff) ? data.staff.map((item, index) => <StaffRow
                            index={index}
                            key={item._id}

                            name={item.name ?? "NA"}
                            email={item.email ?? "NA"}
                            phone={item.phone ?? "NA"}
                            designation={item.designation ?? "NA"}
                            camp_id={data._id}
                            id={item._id}


                        />) : <NoDataComponent message="No Data Found" />
                    }

                </TableBody>
            </Table>
        </Box>
    </>
}




const CampDetailedViewUI = ({ title, modalKey, fetchLoading, data, loading, step, setStep }) => {

    return <>

        <CustomDialog
            id={modalKey}
            loading={loading || fetchLoading}


            title={<Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", flex: 1, width: "100%" }}>
                <Tabs value={step} onChange={(e, newVal) => { setStep(newVal) }} aria-label="basic tabs example">
                    <Tab label="Camp Details" />
                    <Tab label="Beneficiaries" />
                    <Tab label="Supporting Staff" />
                    <Tab label="Images" />
                </Tabs>
            </Box>}

        >

            {(loading || fetchLoading) && <CenteredBox><CircularProgress /></CenteredBox>}
            {!fetchLoading &&
                <>

                    <CampBasicDetailsComponent data={data} show={step == 0} />
                    <BeneficiaryDetails data={data} show={step == 1} />
                    <BeneficiaryStaffDetails data={data} show={step == 2} />
                    <ImagesListComponent data={data} show={step == 3} />

                    {
                        <Box sx={{ width: "100%" }}>

                        </Box>
                    }

                </>}

        </CustomDialog>


    </>
}
export default memo(CampDetailedViewUI)