import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";





export const addBeneficiaryApi = async data => {
  const callResponse = await axios({
    url: endpoints.beneficiaryBaseUrl,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateBeneficiaryApi = async data => {
  const callResponse = await axios({
    url: endpoints.beneficiaryBaseUrl,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const validateBeneficiaryApi = async data => {
  const callResponse = await axios({
    url: endpoints.validateBeneficiaryBaseUrl,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const deleteBeneficiaryApi = async data => {
  const callResponse = await axios({
    url: endpoints.beneficiaryBaseUrl,
    method: "DELETE",
    headers: getHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getBeneficiaryByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.beneficiaryByIdBaseUrl,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getBeneficiaryDemoSheetApi = async params => {
  const callResponse = await axios({
    url: endpoints.beneficiaryDemoSheetUrl,
    method: "GET",
    headers: getHeaders(),
    params,
    responseType: "blob"
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const downloadBeneficiaryReport = async params => {
  const callResponse = await axios({
    url: endpoints.beneficiaryPdfReportBaseUrl,
    method: "get",
    headers: getHeaders(),
    responseType:"blob",
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getBeneficiaryApi = async params => {
  const callResponse = await axios({
    url: endpoints.beneficiaryBaseUrl,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};



export const getBeneficiaryByVideoAvailblityApi = async params => {
  const callResponse = await axios({
    url: endpoints.videoAvailableBeneficiaryUrl,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const getBeneficiaryVideoListApi = async params => {
  const callResponse = await axios({
    url: endpoints.videoList,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getMainBeneficiaryVideoListApi = async params => {
  const callResponse = await axios({
    url: endpoints.fetchVideos,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateBeneficiaryVideoRatingsApi = async data => {
  const callResponse = await axios({
    url: endpoints.videoRatings,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateBeneficiaryVideoRatingAndTagsApi = async data => {
  console.log("oooooooA",data);
  const callResponse = await axios({
    url: endpoints.videoRatingAndTags,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateBeneficiaryVideoUrlApi = async data => {
  const callResponse = await axios({
    url: endpoints.videoUrls,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateVideoStatusApi = async data => {
  console.log("DATA VIDEO",data)
  const callResponse = await axios({
    url: endpoints.videoStatus,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateVideoUrlApi = async data => {
  const callResponse = await axios({
    url: endpoints.updateVideos,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const getBeneficiaryCountByVideoAvaiblityApi = async params => {
  const callResponse = await axios({
    url: endpoints.videoAvailableCountBeneficiaryUrl,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getCountsOfDonationBeneficiaryApi = async params => {
  const callResponse = await axios({
    url: endpoints.beneficiaryCountOfDonation,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getBeneficiaryVideosCount = async params => {
  const callResponse = await axios({
    url: endpoints.videosCount,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getInReviewVideosCount = async params => {
  const callResponse = await axios({
    url: endpoints.inReviewVideos,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getFinalVideosCount = async params => {
  const callResponse = await axios({
    url: endpoints.finalVideos,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getApproveVideosCount = async params => {
  const callResponse = await axios({
    url: endpoints.managerVideos,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getRejectedVideosCount = async params => {
  const callResponse = await axios({
    url: endpoints.rejectVideos,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getTotalVideosCount = async params => {
  const callResponse = await axios({
    url: endpoints.totalVideos,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getCountsByRegionBeneficiaryApi = async params => {
  const callResponse = await axios({
    url: endpoints.beneficiaryCountByRegion,
    method: "GET",
    headers: getHeaders(),
    responseType: params.pdf ? "blob" : 'json',
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getDashboardApi = async params => {
  console.log("PARAMS",params)
  const callResponse = await axios({
    url: endpoints.dashboardUrl,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
