import { memo, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import UpdateVideoUi from "./UpdateVideoUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"
import { USER_ROLES } from "../../utils/constants"

import { updateVidoesApi } from "../../apis/followup.api"

const CreateController = ({ callBack = () => { }, id }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()

    const title = "Video "
    const modalKey = 'video-update'
    const updateApi = updateVidoesApi


    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        name: '',
        url: "",
        videos: "",
        version: "",
        allowed_role: USER_ROLES.SIT_TEAM

    })



    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.videos,
            field: 'Video Url '
        }]), [fields])





    const updateFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(fields),
                    async (response) => {

                        await callBack(fields)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }






    const onSubmit = async (e) => {
        e.preventDefault()


        updateFunction()

    }



    return <UpdateVideoUi modalKey={modalKey} title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)