import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material"
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useState } from "react"
const formControlStyle = (theme) => {

    return {
        // marginTop: theme.spacing
    }
}
const CustomInput = (props) => {

    const [type, setType] = useState(props.type ? props.type : "text")
    if (props.type === "password") {
        
        return (
            <FormControl sx={formControlStyle} margin="dense" fullWidth={true}
                mt={1}
                mb={1}
                variant="outlined" >
                {/* <InputLabel htmlFor="outlined-adornment-password">{props.label ? props.label : "password"}</InputLabel> */}
                <TextField

                    id="outlined-adornment-password"
                    className="row" 
                    {...props}
                    sx={(theme) => {
                        const css = { background: "white", borderTopLeftRadius: theme.shape.borderRadius, borderTopRightRadius: theme.shape.borderRadius }
                        if (props.sx) {
                            return { ...css, ...props.sx }
                        }

                        return css

                    }}
                    type={type}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => { setType(type === "password" ? "text" : "password") }}
                                    color="primary"
                                    edge="end"
                                >
                                    {type === "password" ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                    }}

                />
            </FormControl>
        )

    }
    if (props.type === "side-icon") {

        return (
            <FormControl sx={formControlStyle} margin="dense" fullWidth={true}
                mt={1}
                mb={1}
                variant="outlined">

                <TextField

                    id="outlined-adornment-password"
                    className="row"
                    label={props.label}
                    {...props}
                    type={props.inputType ? props.inputType : 'text'}
                    sx={(theme) => {
                        const css = { background: "white", borderTopLeftRadius: theme.shape.borderRadius, borderTopRightRadius: theme.shape.borderRadius }
                        if (props.sx) {
                            return { ...css, ...props.sx }
                        }

                        return css

                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => { props.on_side_btn_click() }}
                                color="secondary"
                                edge="end"
                            >
                                {props.side_icon}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        )

    }

    return (
        <TextField
        
            fullWidth={true}
            margin="dense"
            inputProps={{
                sx: {

                    borderRadius: "inherit"
                }
            }}
            sx={(theme) => {
                const css = { background: "white", borderTopLeftRadius: theme.shape.borderRadius, borderTopRightRadius: theme.shape.borderRadius }
                if (props.sx) {
                    return { ...css, ...props.sx }
                }

                return css

            }}
            {...props}
            
        />
    )

}
export default CustomInput