import axios from "axios";
import { getFileHeaders, getHeaders } from "../utils/helper";
import endpoints from "./endpoints";





export const addEventApi = async data => {
  const callResponse = await axios({
    url: endpoints.eventBaseUrl,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};



export const validateEventApi = async data => {
  const callResponse = await axios({
    url: endpoints.validateeventBaseUrl,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};



export const addBeneficiaryToEventApi = async data => {
  const callResponse = await axios({
    url: endpoints.addBeneficiaryToEvent,
    method: "PUT",
    headers: getFileHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const removeBeneficiaryToEventApi = async data => {
  const callResponse = await axios({
    url: endpoints.removeBeneficiaryToEvent,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const closeEventEventApi = async data => {
  const callResponse = await axios({
    url: endpoints.closeEvent,
    method: "PUT",
    headers: getFileHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const addImagesToEventApi = async data => {
  const callResponse = await axios({
    url: endpoints.addImagesToEvent,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const updateEventApi = async data => {
  const callResponse = await axios({
    url: endpoints.eventBaseUrl,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};



export const getEventByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.eventByIdBaseUrl,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getEventApi = async params => {
  const callResponse = await axios({
    url: endpoints.eventBaseUrl,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getRegionByEventApi = async params => {
  const callResponse = await axios({
    url: endpoints.regionbyeventUrl,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getBeneficiaryVideoByEventApi = async params => {
  const callResponse = await axios({
    url: endpoints.eventsWiseBeneficiaryVideos,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
