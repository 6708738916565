import { Box, Chip, Grid, Typography, styled } from "@mui/material"
import ImageComponent from "../../../components/inputs/ImageComponent"
import moment from "moment"
import UserProfileViewCommonController from "../../user/UserProfileViewCommonController"
import { getDonorByIdApi, getNgoByIdApi } from "../../../apis/user.api"
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { USER_ROLES } from "../../../utils/constants"

const Header = styled(Box)(({ theme }) => ({
    border: '1px solid ' + theme.palette.primary.main,
    padding: theme.spacing(2),
    background: theme.palette.secondary.main,
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderBottom: "0px",
    borderTopRightRadius: theme.shape.borderRadius * 2,
    width: "100%"
}))
const Body = styled(Box)(({ theme }) => ({
    border: '1px solid ' + theme.palette.primary.main,
    padding: theme.spacing(2),

    marginBottom: theme.spacing(2),

    borderBottomLeftRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
    width: "100%"
}))
const EventDetails = ({ data,show }) => {
    const dispatch = useDispatch()
    const onDonorClick = (id)=>{
        dispatch(openModal(<UserProfileViewCommonController id={id} role={USER_ROLES.DONOR} getFun={getDonorByIdApi} />, "md", false, "users-modal"))
   }
   const onNgoClick = (id)=>{
    dispatch(openModal(<UserProfileViewCommonController id={id} role={USER_ROLES.NGO} getFun={getNgoByIdApi} />, "md", false, "users-modal"))
}
    return <>
    <Box sx={{display:show?"block":'none'}}>
        <Header>
            <Typography fontWeight="bold" variant="body1">
                Events Details
            </Typography>
        </Header>
        <Body>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>

                    <Grid container spacing={2}>
                        <Grid item md={4} xs={6} >
                            <Typography fontWeight="bold" variant="body2">Name:</Typography>

                        </Grid>
                        <Grid item md={8} xs={6} >
                            <Typography variant="body2" textTransform="capitalize">{data.name}</Typography>
                        </Grid>


                        <Grid item md={4} xs={6} >
                            <Typography fontWeight="bold" variant="body2">Description:</Typography>

                        </Grid>
                        <Grid item md={8} xs={6} >
                            <Typography variant="body2">{data.description}</Typography>
                        </Grid>


                       



                        <Grid item md={4} xs={6} >
                            <Typography fontWeight="bold" variant="body2">Date:</Typography>

                        </Grid>
                        <Grid item md={8} xs={6} >
                            <Typography variant="body2">{moment(data.date).format("DD/MM/YYYY")}</Typography>
                        </Grid>


                        <Grid item md={4} xs={6} >
                            <Typography fontWeight="bold" variant="body2">Donors:</Typography>

                        </Grid>
                        <Grid item md={8} xs={6} >
                            <Typography variant="body2" textTransform="capitalize">{Array.isArray(data.donors) ? data.donors.map((item)=><Chip onClick={()=>{onDonorClick(item._id)}} size="small" sx={{mr:1,mb:1}} label={item.name} />):"NA"}</Typography>
                        </Grid>


                        <Grid item md={4} xs={6} >
                            <Typography fontWeight="bold" variant="body2">Ngos:</Typography>

                        </Grid>
                        <Grid item md={8} xs={6} >
                            <Typography variant="body2" textTransform="capitalize">{Array.isArray(data.ngos) ? data.ngos.map((item)=><Chip onClick={()=>{onNgoClick(item._id)}} size="small" sx={{mr:1,mb:1}} label={item.name} />):"NA"}</Typography>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CenteredBox sx={{ maxWidth: "100%" }}>
                        <ImageComponent  imgStyle={{height:"100%",objectFit:"contain",borderRadius:"100%"}}  sx={{height:"150px",width:"150px",borderRadius:"100%",border:"1px solid gray"}} src={data.logo}></ImageComponent>                    
                    </CenteredBox>
                </Grid>



            </Grid>
        </Body>



        <Header>
            <Typography fontWeight="bold" variant="body1">
                Address Details
            </Typography>
        </Header>
        <Body>
            <Grid container spacing={2}>
                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Country:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2" textTransform="capitalize">{data.country ? data.country.name : "NA"}</Typography>
                </Grid>


                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">State:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2" textTransform="capitalize">{data.state ? data.state.name : "NA"}</Typography>
                </Grid>




                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">District:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2">{data.district ? data.district.name : "NA"}</Typography>
                </Grid>


                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2" textTransform="capitalize">Taluka:</Typography>
                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2">{data.taluka ? data.taluka.name : "NA"}</Typography>
                </Grid>


             
                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Venue:</Typography>
                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2" textTransform="capitalize">{data.venue ? data.venue : "NA"}</Typography>
                </Grid>



            </Grid>
        </Body>    
        </Box>         
    </>
}
export default EventDetails