import { OpenInNew } from "@mui/icons-material"
import { Autocomplete, Button, ButtonGroup, CircularProgress, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"



import SubmitButton from "../../components/button/SubmitButton"
import { StyledSearchBar, UserSearchBarNormal } from "../../components/inputs/SearchBar"


import { USER_ROLES } from "../../utils/constants"
import DataTable from "../../components/tables/DataTable"
import { titleCase, toTitleCase } from "../../utils/helper"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list, roles, loading, onCreateBtnClick, columns }) => {

    const user = useSelector(state => state.user)

    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} >
                        <FilterTitleBox>
                            <Typography variant="h5" mb={2} >{title}</Typography>

                            <Box mb={2}>
                                <SubmitButton variant="contained" onClick={onCreateBtnClick} title={"Add User"} />
                            </Box>

                        </FilterTitleBox>


                        <FiltersBox mt={3} >
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} >
                                <Autocomplete
                                    disableClearable
                                    id="combo-box-demo"
                                    onChange={(e, newVal) => {
                                        setFilters({ ...filters, role: newVal ? newVal._id : null })
                                    }}
                                    options={[{ label: 'All', _id: null }, ...Object.keys(USER_ROLES).map((key) => ({ label: titleCase(key), _id: USER_ROLES[key] }))]}
                                    sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                                    renderInput={(params) => <StyledSearchBar placeholder="Select Role" {...params} size="small" />}
                                />
                            </PaddingBoxInDesktop>
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} pl={3} >
                                <StyledSearchBar fullWidth title="Search Name " size="small" placeholder={"Search Name "} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} />
                            </PaddingBoxInDesktop>



                        </FiltersBox>

                    </Box>

                    <Box sx={{ minHeight: "300px" }}  className="table">
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi