import CustomDialog from "../../components/layouts/common/CustomDialog"
import ListController from "./ListController"

const VideoListModal = (props) => {

    return <CustomDialog
        title="Video"
        id={'video-list'}
    >
        <ListController {...props} />
    </CustomDialog>
}
export default VideoListModal