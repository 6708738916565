import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import { getBeneficiaryByIdApi } from "../../../apis/beneficiary.api"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants"
import DetailedViewMain from "./DetailedViewMain"
import { getFollowUpByBenificaryApi } from "../../../apis/followup.api"

const DetailedViewController = ({ id }) => {
    const dispatch = useDispatch()
    const [data, setData] = useState({})
    const modalKey = 'beneficiary-detailed-view'
    const [loading, setLoading] = useState(false)
    const [followupLoading, setfollowupLoading] = useState(false)

    const fetchById = () => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getBeneficiaryByIdApi({ id }),
                async (response) => {
                    setData({ ...response })
                    setLoading(false)
                },
                (err) => {
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    setLoading(false)
                }
            )
        )
    }

    const fetchFollowUps = () => {
        setfollowupLoading(true)
        dispatch(
            callApiAction(
                async () => await getFollowUpByBenificaryApi({ beneficiary_id: id }),
                async (response) => {
                    setData({ ...data, followups: response ?? [] })
                    setfollowupLoading(false)
                },
                (err) => {
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    setfollowupLoading(false)
                }
            )
        )
    }
    useEffect(() => {
        fetchById()
    }, [id])

    return <DetailedViewMain
        followupLoading={followupLoading}
        fetchFollowUps={fetchFollowUps}
        modalKey={modalKey}
        data={data}
        setData={setData}
        loading={loading}
    />
}
export default DetailedViewController