import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"

import { addCampApi } from "../../apis/camp.api"
import { addEventApi, getEventByIdApi, updateEventApi } from "../../apis/event.api"
import moment from "moment"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"
import { callSnackBar } from "../../store/actions/snackbarAction"

const CreateController = ({ callBack = () => { }, id, type }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()

    const title = "Event "
    const modalKey = 'event'

    const createApi = addEventApi
    const getByIdApi = getEventByIdApi
    const updateApi = updateEventApi

    const [loading, setLoading] = useState(false)
    const [fetchLoading, setFetchLoading] = useState(false)
    const [fields, setFields] = useState({
        err: '',
        id,
        name: '',
        description: "",
        date: moment().toISOString(),
        logo: null,
        venue: '',
        country: null,
        state: null,
        district: null,
        taluka: null,
        donors: [],
        ngos: []


    })
    const [originalDocument, setOriginalDocument] = useState({


    })


    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.name,
            field: 'Event Name',
        },
        {
            required: true,
            value: fields.description,
            field: 'Event Description',
        },

        {
            required: true,
            value: fields.date,
            field: ' Date',
        },

        {
            required: true,
            value: fields.venue,
            field: 'Event Venue',
        },

        {
            required: true,
            value: fields.logo,
            field: 'Event Logo',
        },


        {
            required: true,
            value: fields.country,
            field: 'Country'
        },
        {
            required: true,
            value: fields.state,
            field: 'State'
        },
        {
            required: true,
            value: fields.district,
            field: 'District'
        },
        {
            required: true,
            value: fields.taluka,
            field: 'Taluka'
        },

        {
            required: true,
            value: fields.donors,
            custom: () => {
                if (fields.donors && Array.isArray(fields.donors) && fields.donors.length > 0) {
                    return true
                }
                return "Please Select Donors"
            }
        },

        {
            required: true,
            value: fields.ngos,
            custom: () => {
                if (fields.ngos && Array.isArray(fields.ngos) && fields.ngos.length > 0) {
                    return true
                }
                return "Please Select NGOs"
            }
        },
    ]), [fields])



    const validationSchemaForUpdate = useMemo(() => ([

        {
            required: true,
            value: fields.name,
            field: 'Event Name',
        },
        {
            required: true,
            value: fields.description,
            field: 'Event Description',
        },

        {
            required: true,
            value: fields.date,
            field: ' Date',
        },

        {
            required: true,
            value: fields.venue,
            field: 'Event Venue',
        },

        {
            required: true,
            value: fields.logo,
            field: 'Event Logo',
        },


        {
            required: true,
            value: fields.country,
            field: 'Country'
        },
        {
            required: true,
            value: fields.state,
            field: 'State'
        },
        {
            required: true,
            value: fields.district,
            field: 'District'
        },
        {
            required: true,
            value: fields.taluka,
            field: 'Taluka'
        },


    ]), [fields])





    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi(fields),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }





    const updateFunction = async () => {

        let updatedData = { id }
        const validationResponse = validate(validationSchemaForUpdate)

        if (validationResponse === true) {

            for (let field in originalDocument) {
                if (fields[field] != originalDocument[field]) {
                    const fieldVal = fields[field]
                    updatedData[field] = typeof fieldVal == "object" ? fieldVal['_id'] : fieldVal
                }
            }

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(updatedData),
                    async (response) => {

                        await callBack(response, updatedData)
                        setLoading(false)
                        dispatch(callSnackBar("Data updated successfully", SNACK_BAR_VARIETNS.suceess))
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )

        }  else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }



    const onSubmit = async (e) => {

        e.preventDefault()
        if (id)
            updateFunction()
        else
            createFunction()

    }

    const fetchById = (id) => {
        setFetchLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id, forUpdate: true }),
                async (response) => {


                    setFields({ ...fields, ...response })
                    setOriginalDocument(response)
                    setFetchLoading(false)

                },
                (err) => {
                    setFields({ ...fields, err })
                    setFetchLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])

    return <CreateUi fetchLoading={fetchLoading} modalKey={modalKey} title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)