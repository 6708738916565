import { Autocomplete, CircularProgress, ListItem, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo } from "react"
import { useDispatch, useSelector } from 'react-redux'

import SubmitButton from "../../components/button/SubmitButton"

import CustomInput from "../../components/inputs/CustomInput"
import { StyledSearchBar, UserSearchBarNormal } from "../../components/inputs/SearchBar"
import { closeModal } from "../../store/actions/modalAction"
import { REGIONS_TYPE, THIRD_PART_APPS_USED, USER_ROLES } from "../../utils/constants"
import { findNameByRole, findObjectKeyByValue, titleCase, toTitleCase } from "../../utils/helper"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getOpenSolarUserApi } from "../../apis/user.api"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { getRegionApi } from "../../apis/region.api"



const CreateUi = ({ title, modalKey, isUpdate, fields, setFields, loading, onSubmit }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    {<CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.name}
                        onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                        type="text"
                        label={"Document Name*"}

                    />}
                       {<CustomInput
                     
                        disabled={loading}
                        value={fields.url}
                        onChange={(e) => setFields({ ...fields, err: '', url: e.target.value })}
                        type="text"
                        label={"URL*"}

                    />}
                    {<CustomInput
                      
                        disabled={loading}
                        value={fields.version}
                        onChange={(e) => setFields({ ...fields, err: '', version: e.target.value })}
                        type="text"
                        label={"Version*"}

                    />}



                    {!isUpdate && <Autocomplete

                        disableClearable

                        value={fields.allowed_role ? findObjectKeyByValue(fields.allowed_role, USER_ROLES) : null}

                        onChange={(e, newVal) => {
                            setFields({ ...fields, allowed_role: newVal ? newVal._id : null, parent: null })
                        }}
                        options={[...Object.keys(USER_ROLES).map((key) => ({ label: titleCase(key), _id: USER_ROLES[key] }))]}
                        sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                        renderInput={(params) => <CustomInput placeholder="Select User*" {...params} label="Select User*" />}
                    />}

                   

                </>}

        </CustomDialog>
    </>
}
export default memo(CreateUi)