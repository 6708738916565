import { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ListUi from "./ListVideoUi";
import { callApiAction } from "../../../../store/actions/commonAction";
import { createFullName, findObjectKeyByValue } from "../../../../utils/helper";
import { closeModal, openModal } from "../../../../store/actions/modalAction";
import VideoViewer from "../../../videos/VideoViewer";
import { Draw, Edit, Info, People, VideoCall } from "@mui/icons-material";
import DetailedViewController from "../../detailedview/DetailedViewController";
import { Box, Chip, IconButton, Rating } from "@mui/material";
import {
  USER_ROLES,
  VIDEOS_STATUS,
  VIDEOS_TAGS,
} from "../../../../utils/constants";
import { getBeneficiaryByIdApi, getMainBeneficiaryVideoListApi } from "../../../../apis/beneficiary.api";
import { getFollowUpByIdApi } from "../../../../apis/followup.api";
import VideosLogDetails from "./VideosLogsDetailsUi";

const VideosLogsController = ({params}) => {
    const { user } = useSelector((state) => state);
    console.log("id",params)
    const dispatch = useDispatch();
    const title = "Followups Videos Logs";
    const modalKey = "videos-logs";
  
    const fetchApi = getFollowUpByIdApi;
  
    const [filters, setFilters] = useState({
      pageNo: 1,
      pageSize: 10,
      search: "",
      searchable: ["name", "udise_no"],
      sort: "videos.ratings",
      validated: true,
      nuResponse: null,
      donated: null,
      donation_event_id: null,
      tags:'',
      ratings:'',
      camp: null,
      country: null,
      state: null,
      district: null,
      status: null,
      taluka: null,
      sortDirection: -1,
      isNoVideo: false,
      product:null,
    });
  
    // do not change
  
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState({});
    
    const [countsLoading, setCountsLoading] = useState(false)
    const [count, setCount] = useState()
  
    const fetchList = () => {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await fetchApi({id:params._id}),
          (response) => {
            console.log("responsess",response)
            setList(response);
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        )
      );
    };
  
  //   const fetchCounts = () => {
  //     setCountsLoading(true)
  //     dispatch(callApiAction(
  //         async () => await getBeneficiaryVideosCount({ ...filters }),
  //         (response) => {
  //           console.log("RESPOJNS",response)
  //             setCount(response)
  //             setCountsLoading(false)
  //         },
  //         (err) => {
  //             setCountsLoading(false)
  //         }
  //     ))
  // }
  console.log("LISTSsS",list);
    useEffect(() => {
      fetchList();
      // fetchCounts()
    }, [filters]);
  
    return (
      <>
        <VideosLogDetails
          // count={count}
          // setCount={setCount}
          // countsLoading={countsLoading}
          // setCountsLoading={setCountsLoading}
          // title={title}
          modalKey={modalKey}
          filters={filters}
          // setFilters={setFilters}
          loading={loading}
          list={list}
        //   columns={columns}
        />
      </>
    );
  };
  export default VideosLogsController;