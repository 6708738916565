import { useMemo } from "react"
import moment from "moment"
import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"

import { FOLLOW_UP_STATUS, HEARING_STATE, SPEECH_STATE } from "../../../utils/beneficiary.constant"



import { getDashboardFollowUpApi } from "../../../apis/followup.api"
import { Box, IconButton, Tooltip } from "@mui/material"
import { Info } from "@mui/icons-material"
import DetailedViewController from "../../beneficiary/detailedview/DetailedViewController"
import { openModal } from "../../../store/actions/modalAction"
import { findObjectKeyByValue } from "../../../utils/helper"
import fileDownload from "js-file-download"
import {getCountsOfDonationBeneficiaryApi} from "../../../apis/beneficiary.api";

const ActionComponent = memo(({ params, setParams, modalKey, type }) => {
    const dispatch = useDispatch()


    const onBenificaryClick = () => {
        dispatch(openModal(<DetailedViewController
            id={params.beneficiary_id?._id}

        />, "md", false, "beneficiary-detailed-view"))
    }







    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>


        <Tooltip title="Beneficiary Info">

            <IconButton size="inherit" onClick={onBenificaryClick}>
                <Info color="info" fontSize="inherit" />
            </IconButton>
        </Tooltip>

    </Box>
})




const ListController = ({ defaultFilters }) => {
    const dispatch = useDispatch()

    console.log("Default Filter:",defaultFilters)

    const title = "Followups "
    const modalKey = 'followup-list'
    const fetchApi = getDashboardFollowUpApi



    const columns = useMemo(() => [
        { id: 1, fieldName: 'beneficiary_id', label: 'Name', minWidth: "100px", align: "left", renderValue: (params, setParams) => params.beneficiary_id?.name + " " + params.beneficiary_id?.surname },

        { id: 6, fieldName: 'total_followups', label: 'Total Followups Done', minWidth: "100px", align: "left" },
        { id: 7, fieldName: 'hearing_state', label: 'Hearing State', minWidth: "100px", align: "left", renderValue: (params, setParams) => findObjectKeyByValue(params?.beneficiary_id?.hearing_state, HEARING_STATE) },
        { id: 8, fieldName: 'speech_state', label: 'Speech State', minWidth: "100px", align: "left", renderValue: (params, setParams) => findObjectKeyByValue(params?.beneficiary_id?.speech_state, SPEECH_STATE) },
        { id: 2, fieldName: 'videoes', label: ' Total Videos', align: "left" },

        { id: 3, fieldName: 'action', label: 'Action', minWidth: "100px", align: "left", renderValue: (params, setParams) => <ActionComponent params={params} setParams={setParams} /> },
    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['name'],
        hearing_state:null,
        speech_state:null,
        isDropDown:true,
        donation_event_id:null,
        sort: 'scheduled_date',
        ...defaultFilters,

        tab: 1,
        sortDirection: 1,
        status: FOLLOW_UP_STATUS.PENDING,

    })

    console.log("Filters:",filters)




    // do not change 

    const [loading, setLoading] = useState(false)
    const [exportLoading, setExportLoading] = useState(false)
    const [list, setList] = useState({})



    const [countsLoading, setCountsLoading] = useState(false)
    const [counts, setCounts] = useState([])
    const [childCount, setChildCounts] = useState([])



    const fetchList = (isExport) => {
        if (isExport)
            setExportLoading(true)
        else
            setLoading(true)

        if (isExport) {
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters,csv:true }),
                (response) => {

                    fileDownload(response, 'Followups.csv')
                    setExportLoading(false)
                },
                (err) => {
                    setExportLoading(false)
                },
                true
            ))
            return
        }
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {

                console.log("popopo",response)
                setList(response)
                setLoading(false)

            },
            (err) => {

                setLoading(false)
            }
        ))
        // dispatch(callApiAction(
        //     async () => await ({ ...filters }),
        //     (response) => {
        //         setCounts(response)
        //         setCountsLoading(false)
        //     },
        //     (err) => {
        //         setCountsLoading(false)
        //     }
        // ))
    }

    const onExport = () => {
        fetchList(true)
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                counts={counts}
                countsLoading={countsLoading}
                childCount={childCount}
                setChildCounts={setChildCounts}
                title={title}
                modalKey={modalKey}
                onExport={onExport}
                exportLoading={exportLoading}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController