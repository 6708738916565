import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
// import { deleteUserApi, getUserApi, updateUserField } from "../../apis/user.api"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { findNameByRole, findObjectKeyByValue, titleCase } from "../../utils/helper"
import { Box, CircularProgress, IconButton, Switch, Typography } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./CreateController"
import { Delete, Download, Edit } from "@mui/icons-material"
import MessageDilog from "../../components/MessageDilog"
import { getSecretsApi, updateSecretsField } from "../../apis/secrets.api"
import { deleteRegionField, getRegionApi } from "../../apis/region.api"
import { REGIONS_TYPE, USER_ROLES } from "../../utils/constants"
import { deleteDocumentsField, getDocumentsApi } from "../../apis/documents.api"

const ActionComponent = memo(({ params, setParams, deleteApi, modalKey }) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)


    const onDelete = () => {
        dispatch(openModal(<MessageDilog
            title="Alert!"
            message={"Are you sure to delete " + params.name + "?"}

            onSubmit={(e) => {
                e.preventDefault()
                setLoading(true)
                dispatch(callApiAction(
                    async () => await deleteApi({ id: params._id }),
                    (response) => {
                        setParams({})
                        setLoading(false)
                        dispatch(closeModal('delete'))
                    },
                    (err) => {
                        setLoading(false)
                    }
                ))
            }}

        />, "sm", false, "delete"))
    }

    if (loading) {
        return <CircularProgress />
    }
    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <IconButton href={params.url} target="_blank">
            <Download color="error" fontSize="inherit" />
        </IconButton>
        <IconButton size="inherit" onClick={onDelete}>
            <Delete color="error" fontSize="inherit" />
        </IconButton>

    </Box>
})



const ListController = () => {
    const dispatch = useDispatch()



    const title = "Documents "
    const modalKey = 'docs'
    const fetchApi = getDocumentsApi
    const deleteApi = deleteDocumentsField


    const columns = useMemo(() => [

        { id: 1, fieldName: 'name', label: 'Document name', align: "left", sort: true },
        { id: 3, fieldName: 'version', label: 'Version', align: "left", sort: true },
        { id: 2, fieldName: 'allowed_role', label: 'User Type', align: "left", renderValue: (params, setParams) => findObjectKeyByValue(params.allowed_role, USER_ROLES) },


        {
            id: 5,
            fieldName: '',
            label: 'Action',

            align: "right",
            renderValue: (params, setParams) => <ActionComponent modalKey={modalKey} deleteApi={deleteApi} params={params} setParams={setParams} />
        },

    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['name'],
        sort: '',
        
        sortDirection: 1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController callBack={async () => { fetchList() }} />, "sm", false, modalKey))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                title={title}
                onCreateBtnClick={onCreateBtnClick}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController