import { Card, CardContent, Typography, Box } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import { callApiAction } from "../../store/actions/commonAction";
import { HEARING_STATE, SPEECH_STATE } from "../../utils/beneficiary.constant";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardFollowUpApi } from "../../apis/followup.api";
import ListUi from "./ListUi";
import {
  findObjectKeyByValue,
  titleCase,
  toTitleCase,
} from "../../utils/helper";
import { getAvgListApi } from "../../apis/dashboard.api";

const ListOnAvgController = ({ defaultFilters }) => {
  console.log("List", defaultFilters);
  const dispatch = useDispatch();
  const title = "Average Weekly Usage";
  const modalKey = "avg-list";
  const fetchApi = getAvgListApi;
  const { users } = useSelector((state) => state);

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "beneficiary_id",
        label: "Name",
        minWidth: "100px",
        align: "left",
        renderValue: (params, setParams) =>
        // console.log("PARAMS",params)
          params?._id?.name + " " + params?._id?.surname,
      },

      {
        id: 6,
        fieldName: "total_followups",
        label: "Total Followups Done",
        minWidth: "100px",
        align: "left",
      },
      {
        id: 7,
        fieldName: "hearing_state",
        label: "Hearing State",
        minWidth: "100px",
        align: "left",
        renderValue: (params, setParams) =>
          findObjectKeyByValue(
            params?._id?.hearing_state,
            HEARING_STATE
          ),
      },
      {
        id: 8,
        fieldName: "speech_state",
        label: "Speech State",
        minWidth: "100px",
        align: "left",
        renderValue: (params, setParams) =>
          findObjectKeyByValue(
            params?._id?.speech_state,
            SPEECH_STATE
          ),
      },
      { id: 2, fieldName: "videoes", label: " Total Videos", align: "left" },

      {
        id: 3,
        fieldName: "action",
        label: "Action",
        minWidth: "100px",
        align: "left",
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["name"],
    sort: "scheduled_date",
    isDropDown:true,
    donation_event_id:null,
    donor:null,
    hearing_state: null,
    speech_state: null,
    // country: null,
    ...defaultFilters,
    sortDirection: 1,
  });

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          console.log("AVERAGE", response);
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        modalKey={modalKey}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};

export default ListOnAvgController;
