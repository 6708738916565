import CreatableRegionComponent from "../../../components/custom/CreateableRegionComponent"
import CustomInput from "../../../components/inputs/CustomInput"

import FileInput from "../../../components/inputs/FileInput"
import { REGIONS_TYPE } from "../../../utils/constants"

const TeachersFields = ({ fields, setFields, loading }) => {
  return <>
    <>
      <CustomInput
        
        disabled={loading}
        value={fields.phone}
        onChange={(e) => setFields({ ...fields, err: '', phone: e.target.value })}
        type="tel"
        label={"Phone No.*"}

      />

      <CreatableRegionComponent
        disabled={loading}
        value={typeof fields.country == "object" ? fields.country : null}
        label={"Select Country*"}
        type={REGIONS_TYPE.COUNTRY}
        parent={null}
        onChange={async (changedVal) => {

          setFields({ ...fields, country: (changedVal ? changedVal._id : null), state: null, district: null, taluka: null })
        }}
      />



      <CreatableRegionComponent
        disabled={loading || !fields.country || fields.country == null}
        value={typeof (fields.state) == "object" ? fields.state : null}

        type={REGIONS_TYPE.STATE}

        onChange={async (changedVal) => {

          setFields({ ...fields, state: changedVal ? changedVal._id : null, district: null, taluka: null })
        }}
        parent={fields.country && typeof (fields.country) == "object" ? fields.country['_id'] : fields.country}
        label={"Select State*"}

      />


      <CreatableRegionComponent
        disabled={loading || !fields.state || fields.state == null}
        value={typeof fields.district == "object" ? fields.district : null}
        type={REGIONS_TYPE.DISTRICT}

        onChange={async (changedVal) => {
          setFields({ ...fields, district: changedVal ? changedVal._id : null, taluka: null })
        }}
        label={"Select District*"}
        parent={fields.state && typeof (fields.state) == "object" ? fields.state['_id'] : fields.state}
      />

      <CreatableRegionComponent
        disabled={loading || !fields.district || fields.district == null}
        value={typeof fields.taluka == "object" ? fields.taluka : null}
        type={REGIONS_TYPE.TALUKA}


        onChange={async (changedVal) => {

          setFields({ ...fields, taluka: changedVal ? changedVal._id : null })
        }}
        label={"Select Taluka*"}
        parent={fields.district && typeof (fields.district) == "object" ? fields.district['_id'] : fields.district}
      />

      <FileInput
        defaults={fields.photo ? [fields.photo] : undefined}
        title="Profile Picture"
        subTitle="Only png, jpg, jpeg are required."
        onlyImage
        onChange={(newUrl) => {
          setFields({ ...fields, photo: newUrl })
        }} />
    </>




  </>
}
export default TeachersFields