import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import {   getDonorByIdApi, getDonorsApi} from "../../../apis/user.api"

import {  openModal } from "../../../store/actions/modalAction"

import { Box, IconButton, Tooltip,Typography } from "@mui/material"
import { useMemo } from "react"
// import CreateController from "./CreateController"
import { Edit, Info } from "@mui/icons-material"
import { USER_ROLES } from "../../../utils/constants"

import UpdateController from "./CreateController"
import CreateController from "../CreateController"
import UserProfileViewCommonController from "../UserProfileViewCommonController"

const ActionComponent = memo(({ params ,setParams}) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const { user } = useSelector(state => state)

    const onEdit = () => {
        dispatch(openModal(<UpdateController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm", false, "users"))
    }

    const onViewMore = ()=>{
         dispatch(openModal(<UserProfileViewCommonController role={USER_ROLES.DONOR} id={params._id} getFun={getDonorByIdApi} />, "md", false, "users-modal"))
    }
   


    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
         <Tooltip title="view more">

         <IconButton disabled={loading} size="inherit" onClick={onViewMore}>
            <Info color="info" fontSize="inherit" />
        </IconButton>
         </Tooltip>
         <Tooltip title="Edit details">
        <IconButton disabled={loading} size="inherit" onClick={onEdit}>
            <Edit color="info" fontSize="inherit" />
        </IconButton>
       </Tooltip>
        
    </Box>
})

const ListController = () => {
    const dispatch = useDispatch()



    const title = "Donor "
    const role = USER_ROLES.DONOR
    const fetchApi = getDonorsApi
    

    const columns = useMemo(() => [

        { id: 1, minWidth: "50px", fieldName: 'name', label: 'Company Name', align: "left", sort: true },
        { id: 2, minWidth: "50px", fieldName: 'email', label: 'Email', align: "left", sort: true,renderValue:(params)=><Typography textTransform={"none"}  >{params.email}</Typography> },
        { id: 3, minWidth: "50px", fieldName: 'head_name', label: 'Head Name', align: "left", sort: true },
        { id: 4, minWidth: "50px", fieldName: 'contact_person_name', label: 'Contact Person', align: "left", sort: true },
        { id: 5, minWidth: "50px", fieldName: 'contact_person_phone', label: 'Contact Person Phone', align: "left", sort: true },
        
       
        

        {
            id: 5,
            fieldName: '',
            label: 'Action',

            align: "right",
            renderValue: (params, setParams) => <ActionComponent  params={params} setParams={setParams} />,
        },

    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        role: '',
        sort: '',
        sortDirection: 1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController role={role}  callBack={async () => { fetchList() }} />, "sm", false, "users"))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                title={title}
                onCreateBtnClick={onCreateBtnClick}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController