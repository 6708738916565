import { Paper, Box, Button, CircularProgress, Grid, LinearProgress, Typography, styled } from "@mui/material"

import FileInput from "../../components/inputs/FileInput"
import { memo, useState } from "react"
import ImageComponent from "../../components/ImageComponent"
import { Delete, HideImageOutlined, Upload } from "@mui/icons-material"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { useDispatch } from "react-redux"
import { toTitleCase } from "../../utils/helper"
import FileDownloadComponent from "../../components/FileDownloadComponent"
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const FileViewer = memo(({ src, ...props }) => {
    const fileNameSplitted = src?.split('.')
    const fileExtension = fileNameSplitted[fileNameSplitted.length - 1]
    if (fileExtension == "true") {
        return <>
            <CenteredBox>
            <Typography variant="caption" textAlign="center" >
            All Files are merged in one file.
            </Typography>
            </CenteredBox>
        </>
    }
    if (fileExtension?.slice(0, 3) == 'jpg' || fileExtension?.slice(0, 4) == 'jpeg' || fileExtension?.slice(0, 3) == 'png' || fileExtension?.slice(0, 3) == 'svg')
        return <ImageComponent imgStyle={{ height: "100%", width: "100%", objectFit: "contain" }} src={src} />
    return <CenteredBox sx={{flexDirection:"column"}}>        
        <FileDownloadComponent src={src} view />
        <Typography sx={{ wordBreak: "break-word" }}>Download</Typography>
    </CenteredBox>

})

const ImageUploadComponent = memo(({ loading, onChange, percentage, onDeleteFile, files, name }) => {
    const dispatch = useDispatch()
    const [deleteLoading, setDeleteLoading] = useState(false)




    const onDelete = () => {
        setDeleteLoading(true)
        onDeleteFile(files[0], () => {
            setDeleteLoading(false)

        })
    }



    return <Box component={Paper} sx={{ width: "100%", background: "#e5e5e5" }} pt={1} >
        <Typography variant="subtitle2" align="center" lineHeight="100%" >{toTitleCase(name?.replace(/_/g, " "))}</Typography>
        <Box component={Paper} elevation={0} mt={1} mb={1} sx={{ display: "flex", width: "100%", position: 'relative', paddingTop: '100%', border: "1px solid gray" }}>
            <Box sx={{ position: "absolute", top: "0px", left: "0px", right: "0px", width: "100%", height: "100%" }}>
                {(files[0] && !loading && !deleteLoading) ?
                    <FileViewer src={files[0]} />
                    :
                    <CenteredBox>
                        {loading ?
                            <LinearProgress sx={{ zIndex: 1111, width: "100%" }} variant="determinate" value={percentage} />
                            :
                            deleteLoading ?
                                <CircularProgress />
                                : <HideImageOutlined />
                        }
                    </CenteredBox>}


            </Box>
        </Box>
        <Box>

            <Box>
                {!files[0] && <Button component="label" mt={2} startIcon={<Upload />} disableElevation disabled={loading || deleteLoading} variant="contained" fullWidth >
                    {/* <Upload />Upload */}
                    Upload
                    <VisuallyHiddenInput accept="image/*" onChange={onChange} type="file" />
                </Button>}

                {files[0] && <Button onClick={onDelete} component="label" color="error" mt={2} startIcon={<Delete />} disableElevation disabled={loading || deleteLoading} variant="contained" fullWidth >
                    {/* <Upload />Upload */}
                    Delete

                </Button>}
            </Box>

        </Box>
    </Box>
})

const DocumentFields = ({ show, fields, setFields, loading }) => {
    return <>
        <Box sx={{ width: "100%", display: show ? "block" : "none" }}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4} md={3}>

                    <Box>
                        {console.log(fields)}
                        <FileInput
                            disabled={loading}
                            onlyImage

                            defaults={fields.photo ? [fields.photo] : undefined}
                            componentProps={{
                                name: "Photo",
                                url: fields.photo
                            }}
                            onChange={(newUrl) => {
                                setFields({ ...fields, photo: newUrl })
                            }}
                            onDelete={() => {
                                setFields({ ...fields, photo: "" })
                            }}
                            FileComponent={ImageUploadComponent}


                        />
                    </Box>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <Box>
                        <FileInput
                            disabled={loading}
                            onlyImage
                            noCompression
                            defaults={fields.aadhar_file ? [fields.aadhar_file] : undefined}
                            componentProps={{
                                name: "Aadhar Card",
                                url: fields.aadhar_file
                            }}
                            onChange={(newUrl) => {
                                setFields({ ...fields, aadhar_file: newUrl })
                            }}
                            FileComponent={ImageUploadComponent}
                            onDelete={() => {
                                setFields({ ...fields, aadhar_file: "" })
                            }}

                        />
                    </Box>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <Box>
                        <FileInput
                            disabled={loading}
                            onlyImage
                            noCompression
                            defaults={fields.udise_file ? [fields.udise_file] : undefined}
                            componentProps={{
                                name: "Disablity Certificate",
                                url: fields.udise_file
                            }}
                            onChange={(newUrl) => {
                                setFields({ ...fields, udise_file: newUrl })
                            }}
                            FileComponent={ImageUploadComponent}
                            onDelete={() => {
                                setFields({ ...fields, udise_file: "" })
                            }}

                        />
                    </Box>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <Box>
                        <FileInput
                            disabled={loading}
                            onlyImage
                            noCompression
                            defaults={fields.income_certi ? [fields.income_certi] : undefined}
                            componentProps={{
                                name: "Income Certificate",
                                url: fields.income_certi
                            }}
                            onChange={(newUrl) => {
                                setFields({ ...fields, income_certi: newUrl })
                            }}
                            FileComponent={ImageUploadComponent}

                            onDelete={() => {
                                setFields({ ...fields, income_certi: "" })
                            }}
                        />
                    </Box>
                </Grid>


            </Grid>
        </Box>
    </>
}

export default DocumentFields