import {  Paper, ListItem,  Typography, styled } from "@mui/material"
import { Box } from "@mui/system"

import { useDispatch, useSelector } from "react-redux"



import SubmitButton from "../../components/button/SubmitButton"
import { StyledSearchBar } from "../../components/inputs/SearchBar"


import { REGIONS_TYPE } from "../../utils/constants"
import DataTable from "../../components/tables/DataTable"
import { findObjectKeyByValue, titleCase, toTitleCase } from "../../utils/helper"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getRegionApi } from "../../apis/region.api"
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector"
import MODULES from "../../utils/module.constant"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list, roles, loading, onCreateBtnClick, columns }) => {

    
    const { user } = useSelector(state => state)
    const allowedModules = user.data.modules??[]
    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} >
                        <FilterTitleBox>
                            <Typography variant="h5" mb={2} >{title}</Typography>

                           {allowedModules.includes(MODULES.EVENT_CREATE_UPDATE) && <Box mb={2}>
                                <SubmitButton variant="contained" onClick={onCreateBtnClick} title={"Add Event"} />
                            </Box>}

                        </FilterTitleBox>
                        <FiltersBox mt={3} >
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <TimeRangeSelector onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />
                            </PaddingBoxInDesktop>


               
                           

                            <PaddingBoxInDesktop mb={2} sx={{
                                display: "flex", flex: 1, justifyContent: "flex-end", "*": {
                                    justifyContent: "center"
                                }
                            }} pl={3} >
                                <StyledSearchBar fullWidth title="Search By Event Name  " size="small" placeholder={"Search By Event Name "} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} />
                            </PaddingBoxInDesktop>



                        </FiltersBox>
                        <FilterTitleBox>


                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => { return await getRegionApi({ type: REGIONS_TYPE.COUNTRY, ...para }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, country: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Country"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                            <PaddingBoxInDesktop pl={2} mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => { return await getRegionApi({ type: REGIONS_TYPE.STATE, ...para }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, state: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select State"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                            <PaddingBoxInDesktop pl={2} mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => { return await getRegionApi({ type: REGIONS_TYPE.DISTRICT, ...para }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, district: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select District"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>


                            <PaddingBoxInDesktop pl={2} mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => { return await getRegionApi({ type: REGIONS_TYPE.TALUKA, ...para }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, taluka: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Taluka"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                        </FilterTitleBox>

                    </Box>

                    <Box sx={{ minHeight: "300px" }}  className="table">
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi