
import { OpenInNew } from "@mui/icons-material"
import { Autocomplete, Button, ButtonGroup, CircularProgress, Grid, IconButton, ListItem, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"



import SubmitButton from "../../components/button/SubmitButton"
import { StyledSearchBar, UserSearchBarNormal } from "../../components/inputs/SearchBar"


import { REGIONS_TYPE, USER_ROLES } from "../../utils/constants"
import DataTable from "../../components/tables/DataTable"
import { findObjectKeyByValue, titleCase, toTitleCase } from "../../utils/helper"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getProductApi } from "../../apis/product.api"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list, roles, loading, onCreateBtnClick, columns ,onExportBtnClick,exportLoading}) => {

    const user = useSelector(state => state.user)

    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} >
                        <FilterTitleBox>
                            <Typography variant="h5" mb={2} >{title}</Typography>

                            <Box mb={2}>
                                <SubmitButton variant="contained" onClick={onCreateBtnClick} title={"Add Product"} />
                            </Box>

                        </FilterTitleBox>


                        <FiltersBox mt={3} >
                            {/* <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <Autocomplete

                                    disableClearable

                                    value={filters.type ? findObjectKeyByValue(filters.type, REGIONS_TYPE) : null}

                                    onChange={(e, newVal) => {
                                        setFilters({ ...filters, type: newVal ? newVal._id : null, parent: null })
                                    }}
                                    options={[...Object.keys(REGIONS_TYPE).map((key) => ({ label: titleCase(key), _id: REGIONS_TYPE[key] }))]}
                                    sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                                    renderInput={(params) => <StyledSearchBar placeholder="Select Region Type*" {...params} />}
                                />
                            </PaddingBoxInDesktop>
                            {filters.type && filters.type!=REGIONS_TYPE.COUNTRY &&<PaddingBoxInDesktop mb={2} sx={{
                                display: "flex", flex: 1, justifyContent: "flex-end", "*": {
                                    justifyContent: "center"
                                }
                            }} pl={3} >

                                <AsyncDropDown
                                    key={filters.type}
                                   
                                    lazyFun={async (para) => { return await getRegionApi({ type: filters.type -1 ,...para}) }}
                                    
                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, parent: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select " + findObjectKeyByValue(filters.type-1 , REGIONS_TYPE).toLowerCase()} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>} */}
                            <PaddingBoxInDesktop mb={2} ml={"65%"} sx={{ height: "40px",
                                display: "flex", flex: 1, justifyContent: "flex-end", "*": {
                                    justifyContent: "center"
                                }
                            }} pl={3} >
                                <StyledSearchBar fullWidth title="Search By  Name " size="small" placeholder={"Search By  Name "} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} />
                            </PaddingBoxInDesktop>



                        </FiltersBox>
                        {/* <FiltersBox mt={3} >
                        <Box mb={2}>
                                <SubmitButton loading={exportLoading} variant="contained" onClick={onExportBtnClick} title={"Export"} />
                            </Box>
                            </FiltersBox> */}
                    </Box>

                    <Box sx={{ minHeight: "300px" }} className="table">
                        <DataTable  key={filters.type} columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi