import { useDispatch, useSelector } from "react-redux"
import CreateController from "../CreateController"
import { openModal } from "../../../store/actions/modalAction"
import { IconButton, MenuItem } from "@mui/material"
import { Edit } from "@mui/icons-material"
import { BENIFICIARY_STATUS } from "../../../utils/beneficiary.constant"
import MODULES from "../../../utils/module.constant"

const BeneficiaryUpdateButton = ({ id, status, type = "icon" }) => {
    const dispatch = useDispatch()

    const { user } = useSelector(state => state)
    const allowedModules = user.data.modules??[]


    const onUpdateClick = () => {
        dispatch(openModal(<CreateController
            id={id}

        />, "md", false, "beneficiary"))

    }
    if (status===false
        //  ||
        //  status>= BENIFICIARY_STATUS.VALIDATED 
         ||   !allowedModules.includes(MODULES.BENIFICIARY_UPDATE) )
        return <></>

    if (type == 'menuitem')
        return <MenuItem onClick={onUpdateClick}>Update Beneficiary Details</MenuItem>
    if (type == 'icon')
        return <IconButton onClick={onUpdateClick}>
            <Edit color="info" />
        </IconButton>
}
export default BeneficiaryUpdateButton