import axios from "axios";
import { getFileHeaders, getHeaders } from "../utils/helper";
import endpoints from "./endpoints";





export const assignFollowUpApi = async data => {
  const callResponse = await axios({
    url: endpoints.followupBaseUrl,
    method: "POST",
    headers: getFileHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const updateFollowUpApi = async data => {
  const callResponse = await axios({
    url: endpoints.followupBaseUrl,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateInclusiveFollowUpApi = async data => {
  const callResponse = await axios({
    url: endpoints.inclusiveFollowup,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const getFollowUpByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.followupByIdBaseUrl,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getDashboardFollowUpApi = async params => {
  const callResponse = await axios({
    url: endpoints.dashBoardFollowup,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getVidoesApi = async params => {
  const callResponse = await axios({
    url: endpoints.followupVideo,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const updateVidoesApi = async data => {
  const callResponse = await axios({
    url: endpoints.followupVideo,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const getFollowUpApi = async params => {
  const callResponse = await axios({
    url: endpoints.followupBaseUrl,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getFollowUpByBenificaryApi = async params => {
  const callResponse = await axios({
    url: endpoints.followupByBeneficiary,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const getFollowUpVideosByEvents = async params => {
  const callResponse = await axios({
    url: endpoints.eventsVideos,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

