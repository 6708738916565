import { useCallback, useEffect, useReducer, useState } from "react"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"
import { addBeneficiaryToEventApi, closeEventEventApi, getEventByIdApi } from "../../apis/event.api"
import AddBeneficiaryToEventUi from "./AddBeneficiaryToEventUi"
import { closeModal } from "../../store/actions/modalAction"
import { EVENT_STATUS } from "../../utils/beneficiary.constant"
import CloseEventUi from "./CloseEventUi"
const reducer = (state, action) => {

    if (action.type == 'UPDATE') {
        const oldData = [...state]

        const index = action.index
        const value = action.value
        const err = !(value && value.trim() != '')
        oldData[index].device_id = value
        oldData[index].err = err
        return oldData
    }

    if (action.type == 'ADD') {
        const value = action.value
        return value
    }

    if (action.type == 'RESET') {

        return []
    }
    return [...state]
}
const CloseEventController = ({ id, callBack }) => {
    const dispatch = useDispatch()
    const [data, setData] = useState({})
    const title = "Complete Event"
    const modalKey = 'event-close'
    const [loading, setLoading] = useState(false)

    const [fetchLoading, setFetchLoading] = useState(false)




    const [benificiarisList, dispatchBeneficiaryList] = useReducer(reducer, [])




    const fetchById = () => {
        setFetchLoading(true)
        dispatch(
            callApiAction(
                async () => await getEventByIdApi({ id, onlyBeneficiary: true }),
                async (response) => {
                    const { benificiaris, ...otherDetails } = response
                    if (benificiaris && benificiaris.length > 0) {
                        const newBeneficiary = benificiaris.map((item) => ({
                            name: item.beneficiary_id.name,
                            surname: item.beneficiary_id.surname,
                            device_id: null,
                            err: '',
                            _id: item.beneficiary_id._id,
                        }))
                        dispatchBeneficiaryList({ type: "ADD", value: newBeneficiary })
                    } else {
                        dispatchBeneficiaryList({
                            type: "RESET"
                        })
                    }


                    setData({ ...otherDetails })
                    setFetchLoading(false)
                },
                (err) => {
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    setFetchLoading(false)
                }
            )
        )
    }

    const onSubmit = (e) => {
        e.preventDefault()

        
        setLoading(true)
        

        for (let i = 0; i < benificiarisList.length; i++) {
            const item = benificiarisList[i]
            if (!item.device_id || item.device_id.trim() == '') {
                dispatchBeneficiaryList({ type: "UPDATE", value: null,index:i,err:true })
                setLoading(false)
                return
            }

        }

       
        const formData = new FormData()
        formData.append('beneficiaries', JSON.stringify(benificiarisList.map((item) => ({
            beneficiary_id: item._id,
            device_id: item.device_id,
        }))))
        formData.append('id', id)
        dispatch(
            callApiAction(
                async () => await closeEventEventApi(formData),
                async (response) => {

                    await callBack({ status: EVENT_STATUS.CLOSED })
                    setLoading(false)
                    dispatch(callSnackBar('Event closed successfully' + data.name, SNACK_BAR_VARIETNS.suceess))
                    dispatch(closeModal(modalKey))
                    
                },
                (err) => {
                    setLoading(false)
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))

                }
            )
        )
    }



    useEffect(() => {
        fetchById()
    }, [id])

    return <CloseEventUi

        fetchLoading={fetchLoading}
        id={id}
        onSubmit={onSubmit}
        loading={loading}
        title={title}
        data={data}
        setData={setData}
        modalKey={modalKey}
        benificiarisList={benificiarisList}
        dispatchBeneficiaryList={dispatchBeneficiaryList}


    />
}
export default CloseEventController