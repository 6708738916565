import { Box, Button, ButtonGroup, Chip, CircularProgress, Grid, Typography } from "@mui/material"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import NoDataComponent from "../../components/layouts/NoDataComponent"
import { NoAccounts } from "@mui/icons-material"
import { useDispatch } from "react-redux"
import BeneficiaryDetailedViewController from "../beneficiary/detailedview/DetailedViewController"
import { openModal } from "../../store/actions/modalAction"
import { useCallback } from "react"
import BeneficiaryFetchCampController from "./beneficiarymodal/BeneficiaryFetchController"

const AddBeneficiaryToEventUi = ({ data, loading, onSubmit, setData, modalKey, oldBenificarioes, newBenficiaries, onDeleteBeneficiary, onAddBeneficiary }) => {
    const dispatch = useDispatch()

    const onBenificaryClick = useCallback((beneficiaryId) => {
        dispatch(openModal(<BeneficiaryDetailedViewController
            id={beneficiaryId}

        />, "md", false, "beneficiary-detailed-view"))
    }, [])

    const onAddFromCampClick = useCallback((beneficiaryId) => {
        dispatch(openModal(<BeneficiaryFetchCampController


            callBack={onAddBeneficiary}

        />, "sm", false, "beneficiary-by-paramas"))
    }, [onAddBeneficiary])


    const onAddFromRegionClick = useCallback((beneficiaryId) => {
        dispatch(openModal(<BeneficiaryFetchCampController
            type="region"

            callBack={onAddBeneficiary}

        />, "sm", false, "beneficiary-by-paramas"))
    }, [onAddBeneficiary])

    const onAddFromUDISEClick = useCallback((beneficiaryId) => {
        dispatch(openModal(<BeneficiaryFetchCampController
            type="udise"

            callBack={onAddBeneficiary}

        />, "sm", false, "beneficiary-by-paramas"))
    }, [onAddBeneficiary])


    return (

        <CustomDialog
            onSubmit={onSubmit}
            id={modalKey}
            loading={loading}
            title={`Add Beneficiaries To Event`}
            closeText="Close"
            dialogProps={{ sx: { height: "1000px", overflow: "hidden" } }}

        >
            {
                loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                    <Box sx={(theme) => ({ height: "100%", width: "100%", display: "flex", border: "1px solid", overflow: "hidden", [theme.breakpoints.down('md')]: { flexDirection: "column" } })}>
                        <Box sx={{ display: "flex", borderRight: "1px solid black", flex: 1, overflow: "hidden", flexDirection: "column" }}>
                            <Box p={2} sx={{ display: "flex", background: "#f2f2f2" }}>
                                <Typography variant="button" textTransform="capitalize" >
                                    {
                                        data.name
                                    }
                                </Typography>
                            </Box>
                            <Box p={2} sx={{ display: "flex" }}>
                                <Typography variant="body2" fontWeight="bold" >
                                    Already Assigned Beneficiaries: ({oldBenificarioes && oldBenificarioes.length})
                                </Typography>
                            </Box>
                            <Box p={2} sx={{ display: "flex", flex: 1, overflowY: "auto" , flexWrap: "wrap"}}>
                                {
                                    oldBenificarioes && Array.isArray(oldBenificarioes) && oldBenificarioes.length > 0 ? <>
                                        {
                                            oldBenificarioes.map((item) => <Chip sx={{ m: 1 }} onClick={() => {
                                                onBenificaryClick(item._id)
                                            }} key={item._id} label={(item.name + " " + item.surname)} />)
                                        }
                                    </> : <NoDataComponent Icon={NoAccounts} message="No Beneficiary Assigned" />
                                }
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", flex: 2, overflow: "hidden", flexDirection: "column" }}>
                            <Box sx={{ width: "100%" }}>
                                <ButtonGroup disableElevation variant="contained" sx={{ width: "100%", display: "flex", "*": { display: "flex", flex: 1, borderRadius: 0 } }}>
                                    <Button onClick={onAddFromCampClick} >Add From Camp</Button>
                                    <Button onClick={onAddFromRegionClick}>Add From Region</Button>
                                    <Button onClick={onAddFromUDISEClick}>Add By UDISE</Button>
                                </ButtonGroup>
                            </Box>
                            <Box p={2} sx={{ display: "flex" }}>
                                <Typography variant="body2" fontWeight="bold" >
                                    Assigned Beneficiaries:({newBenficiaries && newBenficiaries.length})
                                </Typography>
                            </Box>
                            <Box p={2} sx={{ display: "flex", overflowY: "auto", flexWrap: "wrap",justifyContent:"flex-start",alignItems:"flex-start" }}>
                                {
                                    newBenficiaries && Array.isArray(newBenficiaries) && newBenficiaries.length > 0 ? <>

                                        {
                                            newBenficiaries.map((item) => <Chip onDelete={() => { onDeleteBeneficiary(item._id) }} sx={{ m: 1 }} onClick={() => {
                                                onBenificaryClick(item._id)
                                            }} key={item._id} label={item._id ? (item.name + " " + item.surname) : null} />)
                                        }

                                    </> : <NoDataComponent Icon={NoAccounts} message="No Beneficiary Assigned" />
                                }
                            </Box>
                        </Box>
                    </Box>
            }
        </CustomDialog>
    )

}
export default AddBeneficiaryToEventUi