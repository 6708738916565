import { OpenInNew } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  ListItem,
  IconButton,
  MenuItem,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SubmitButton from "../../components/button/SubmitButton";
import {
  StyledSearchBar,
  UserSearchBarNormal,
} from "../../components/inputs/SearchBar";

import { REGIONS_TYPE, USER_ROLES, VIDEOS_RATING, VIDEOS_TAGS } from "../../utils/constants";
import DataTable from "../../components/tables/DataTable";
import {
  findObjectKeyByValue,
  titleCase,
  toTitleCase,
} from "../../utils/helper";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { getRegionApi } from "../../apis/region.api";
import CreatableRegionComponent from "../../components/custom/CreateableRegionComponent";
import { getCampApi } from "../../apis/camp.api";
import { getEventApi } from "../../apis/event.api";
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector";
import CustomInput from "../../components/inputs/CustomInput";
import { getDonorsApi } from "../../apis/user.api";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ListUi = ({
  title,
  filters,
  setFilters,
  list,
  roles,
  loading,
  onCreateBtnClick,
  columns,
}) => {
  const user = useSelector((state) => state.user);

  return (
    <>
      <Box component={Paper} p={3} mb={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
          }}
        >
          <FiltersBox mt={3}>
            {/* <FormControl fullWidth={true} margin="dense">
              <InputLabel>Standard/Grade</InputLabel>
              <Select
                fullWidth={true}
                id="demo-simple-select-standard"
                label="Standard/Grade"
                value={filters.followup_no}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    err: "",
                    followup_no: e.target.value,
                  })
                }
              >
                {filters.followup_no.map((item) => {
                  return (
                    <MenuItem key={item.key} value={item.value}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl> */}
            {/* <FormControl>
              <Select
                // labelId="number-dropdown-label"
                id="number-dropdown"
                value={filters.followup_no}
                onChange={(e)=> setFilters({
                  ...filters,
                  err: "",
                  followup_no: e.target.value,
                })}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8].map((number) => (
                  <MenuItem key={number} value={number}>
                    {number}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

            <PaddingBoxInDesktop
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <AsyncDropDown
                lazyFun={getEventApi}
                OptionComponent={({ option, ...rest }) => {
                  return <ListItem {...rest}>{option.name}</ListItem>;
                }}
                onChange={async (changedVal) => {
                  setFilters({
                    ...filters,
                    donation_event_id: changedVal ? changedVal._id : null,
                  });
                }}
                titleKey={"name"}
                valueKey={"_id"}
                InputComponent={(params) => (
                  <StyledSearchBar
                    placeholder={"Select Event"}
                    {...params}
                    margin="none"
                  />
                )}
              />
            </PaddingBoxInDesktop>

            <PaddingBoxInDesktop
              mb={2}
              // mt={1}
              sx={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
                "*": {
                  justifyContent: "center",
                },
              }}
              pl={3}
            >
              <AsyncDropDown
                // key={filters.donor}
                lazyFun={async (para) => {
                  console.log("PARA",para)
                  return await getDonorsApi({ ...para });
                }}
                OptionComponent={({ option, ...rest }) => {
                  return <ListItem {...rest}>{option.name}</ListItem>;
                }}
                onChange={async (changedVal) => {
                  setFilters({
                    ...filters,
                    donors: changedVal ? changedVal._id : null,
                  });
                }}
                titleKey={"name"}
                valueKey={"_id"}
                InputComponent={(params) => (
                  <StyledSearchBar
                    placeholder={"Select Donor"}
                    {...params}
                    margin="none"
                  />
                )}
              />
            </PaddingBoxInDesktop>
          </FiltersBox>
          <FilterTitleBox>
            <PaddingBoxInDesktop
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <CreatableRegionComponent
                onlyDropDown
                disabled={loading}
                value={
                  typeof filters.country == "object" ? filters.country : null
                }
                label={"Select Country*"}
                type={REGIONS_TYPE.COUNTRY}
                parent={null}
                onChange={async (changedVal) => {
                  setFilters({
                    ...filters,
                    country: changedVal ? changedVal._id : null,
                    state: null,
                    district: null,
                    taluka: null,
                  });
                }}
              />
            </PaddingBoxInDesktop>

            <PaddingBoxInDesktop
              pl={2}
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <CreatableRegionComponent
                onlyDropDown
                disabled={
                  loading || !filters.country || filters.country == null
                }
                value={typeof filters.state == "object" ? filters.state : null}
                type={REGIONS_TYPE.STATE}
                onChange={async (changedVal) => {
                  setFilters({
                    ...filters,
                    state: changedVal ? changedVal._id : null,
                    district: null,
                    taluka: null,
                  });
                }}
                parent={
                  filters.country && typeof filters.country == "object"
                    ? filters.country["_id"]
                    : filters.country
                }
                label={"Select State*"}
              />
            </PaddingBoxInDesktop>

            <PaddingBoxInDesktop
              pl={2}
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <CreatableRegionComponent
                onlyDropDown
                disabled={loading || !filters.state || filters.state == null}
                value={
                  typeof filters.district == "object" ? filters.district : null
                }
                type={REGIONS_TYPE.DISTRICT}
                onChange={async (changedVal) => {
                  setFilters({
                    ...filters,
                    district: changedVal ? changedVal._id : null,
                    taluka: null,
                  });
                }}
                label={"Select District*"}
                parent={
                  filters.state && typeof filters.state == "object"
                    ? filters.state["_id"]
                    : filters.state
                }
              />
            </PaddingBoxInDesktop>

            <PaddingBoxInDesktop
              pl={2}
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <CreatableRegionComponent
                onlyDropDown
                disabled={
                  loading || !filters.district || filters.district == null
                }
                value={
                  typeof filters.taluka == "object" ? filters.taluka : null
                }
                type={REGIONS_TYPE.TALUKA}
                onChange={async (changedVal) => {
                  setFilters({
                    ...filters,
                    taluka: changedVal ? changedVal._id : null,
                  });
                }}
                label={"Select Taluka*"}
                parent={
                  filters.district && typeof filters.district == "object"
                    ? filters.district["_id"]
                    : filters.district
                }
              />
            </PaddingBoxInDesktop>
          </FilterTitleBox>
          <FilterTitleBox>
            <PaddingBoxInDesktop
              //   pl={2}
              mb={2}
              sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
            >
              <Autocomplete
                disableClearable
                // value={findObjectKeyByValue(filters.tags, VIDEOS_TAGS)}
                onChange={(e, changedVal) => {
                  setFilters({
                    ...filters,
                    tags: changedVal ? changedVal._id : null,
                  });
                }}
                options={[
                  { label: "All", _id: null },
                  ...Object.keys(VIDEOS_TAGS).map((key) => ({
                    label: titleCase(key),
                    _id: VIDEOS_TAGS[key],
                  })),
                ]}
                sx={{
                  width: "100%",
                  display: "flex",
                  "*": { display: "flex", justifyContent: "center" },
                }}
                renderInput={(params) => (
                  <StyledSearchBar
                    placeholder={"Select Video Tags"}
                    {...params}
                    // size={size}
                  />
                )}
              />
            </PaddingBoxInDesktop>
            <PaddingBoxInDesktop
              mb={2}
              sx={{ display: "flex", flex: 1 }}
              pl={2}
            >
              <Autocomplete
                disableClearable
                value={findObjectKeyByValue(filters.ratings, VIDEOS_RATING)}
                onChange={(e, changedVal) => {
                  setFilters({
                    ...filters,
                    ratings: changedVal ? changedVal._id : null,
                  });
                }}
                options={[
                  { label: "All", _id: null },
                  ...Object.keys(VIDEOS_RATING).map((key) => ({
                    label: titleCase(key),
                    _id: VIDEOS_RATING[key],
                  })),
                ]}
                sx={{
                  width: "100%",
                  display: "flex",
                  "*": { display: "flex", justifyContent: "center" },
                }}
                renderInput={(params) => (
                  <StyledSearchBar placeholder="Select Rating" {...params} />
                )}
              />
            </PaddingBoxInDesktop>
          </FilterTitleBox>

          {/* <PaddingBoxInDesktop mb={2} sx={{
                                display: "flex", flex: 1, justifyContent: "flex-end", "*": {
                                    justifyContent: "center"
                                }
                            }} >
                                <StyledSearchBar fullWidth title="Search By Name  " size="small" placeholder={"Search By Name "} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} />
                            </PaddingBoxInDesktop> */}

        </Box>
      </Box>

      <Box sx={{ minHeight: "300px" }} className="table">
        <DataTable
          columns={columns}
          rows={list.result ? list.result : []}
          count={list.total ?? 0}
          filters={filters}
          setFilters={setFilters}
          loading={loading}
        />
      </Box>
    </>
  );
};
export default ListUi;
