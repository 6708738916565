import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import { getBeneficiaryByIdApi } from "../../apis/beneficiary.api"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"

import { getEventByIdApi } from "../../apis/event.api"
import { getFollowUpByIdApi } from "../../apis/followup.api"
import FollowUpDetailedViewUi from "./FollowUpDetailedViewUi"

const FollowupDetailedViewController = ({ id }) => {
    const dispatch = useDispatch()
    const [data, setData] = useState({})
    const modalKey = 'followup-detailed-view'
    const [loading, setLoading] = useState(false)

    const fetchById = () => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getFollowUpByIdApi({ id }),
                async (response) => {
                    setData({ ...response })
                    setLoading(false)
                },
                (err) => {
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    setLoading(false)
                }
            )
        )
    }
    useEffect(() => {
        fetchById()
    }, [id])

    return <FollowUpDetailedViewUi
        modalKey={modalKey}
        data={data}
        setData={setData}
        loading={loading}
    />
}
export default FollowupDetailedViewController