import { ListItem, Box, Chip, CircularProgress } from "@mui/material"
import { useCallback, useState } from "react"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"

import NoDataComponent from "../../../components/layouts/NoDataComponent"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"
import { getCampApi } from "../../../apis/camp.api"
import { StyledSearchBar } from "../../../components/inputs/SearchBar"
import BeneficiaryDetailedViewController from "../../beneficiary/detailedview/DetailedViewController"
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import { REGIONS_TYPE } from "../../../utils/constants"
import { findObjectKeyByValue, titleCase } from "../../../utils/helper"
import { getRegionApi } from "../../../apis/region.api"
import { getEventApi } from "../../../apis/event.api"


const BeneficiaryFromEventUi = ({ filters, loading, setFilters, list, modalKey, onSubmit, title }) => {
    const dispatch = useDispatch()

    const onBenificaryClick = useCallback((beneficiaryId) => {
        dispatch(openModal(<BeneficiaryDetailedViewController
            id={beneficiaryId}

        />, "md", false, "beneficiary-detailed-view"))
    }, [])
    return <>
        <CustomDialog
            id={modalKey}
            loading={loading}
            title={title}
            closeText="Close"
            onSubmit={onSubmit}
            confirmText="Add"
            dialogProps={{ sx: { height: "1000px", overflow: "hidden" } }}

        >
            <Box sx={(theme) => ({ height: "100%", width: "100%", display: "flex", flexDirection: "column", overflow: "hidden" })}>
                <Box mt={1} mb={3}>


                    <AsyncDropDown
                        key={filters.donation_event_id}
                        lazyFun={async (para) => { return await getEventApi({ ...para }) }}
                        OptionComponent={({ option, ...rest }) => {
                            return <ListItem {...rest}>{option.name}</ListItem >
                        }}
                        onChange={async (changedVal) => {
                            setFilters({ ...filters, donation_event_id: changedVal ? changedVal._id : null, })
                        }}
                        titleKey={'name'}
                        valueKey={"_id"}
                        InputComponent={(params) => <StyledSearchBar placeholder={"Select event"} {...params} margin="none" />}
                    />




                </Box>

                {loading ? <CenteredBox><CircularProgress size={30} /></CenteredBox> :

                    <>
                        <Box sx={{ display: "flex", flexWrap: "wrap", overflowY: "auto", justifyContent: "flex-start", alignItems: "flex-start" }}>
                            {
                                list && list.length > 0 ? list.map((item) => <Chip onClick={() => {
                                    onBenificaryClick(item._id)
                                }} sx={{ m: 1 }} label={item.name + ' ' + item.surname} key={item._id} />) : <NoDataComponent message="No Data Found" />
                            }
                        </Box>
                    </>
                }
            </Box>
        </CustomDialog>
    </>
}
export default BeneficiaryFromEventUi