import CustomInput from "../../../components/inputs/CustomInput"
import { REGIONS_TYPE } from "../../../utils/constants"
import FileInput from "../../../components/inputs/FileInput"
import CreatableRegionComponent from "../../../components/custom/CreateableRegionComponent"

const GovFields = ({ fields, setFields, loading }) => {
  return <>

    <CustomInput
      disabled={loading}
      value={fields.contact_person_name}
      onChange={(e) => setFields({ ...fields, err: '', contact_person_name: e.target.value })}

      type="text"
      label={"Contact Person Name*"}

    />
    <CustomInput
      disabled={loading}
      value={fields.contact_person_phone}
      onChange={(e) => setFields({ ...fields, err: '', contact_person_phone: e.target.value })}

      type="tel"
      label={"Contact Person Phone*"}

    />
    <CreatableRegionComponent
      disabled={loading}
      value={typeof fields.country == "object" ? fields.country : null}
      label={"Select Country*"}
      type={REGIONS_TYPE.COUNTRY}
      parent={null}
      onChange={async (changedVal) => {

        setFields({ ...fields, country: (changedVal ? changedVal._id : null), state: null, district: null, taluka: null })
      }}
    />



    <CreatableRegionComponent
      disabled={loading || !fields.country || fields.country == null}
      value={typeof (fields.state) == "object" ? fields.state : null}

      type={REGIONS_TYPE.STATE}

      onChange={async (changedVal) => {

        setFields({ ...fields, state: changedVal ? changedVal._id : null, district: null, taluka: null })
      }}
      parent={fields.country && typeof (fields.country) == "object" ? fields.country['_id'] : fields.country}
      label={"Select State*"}

    />
    <FileInput
      defaults={fields.photo ? [fields.photo] : undefined}
      title="State Logo"
      subTitle="Only png, jpg, jpeg are required."
      onlyImage
      onChange={(newUrl) => {
        setFields({ ...fields, photo: newUrl })
      }} />


  </>
}
export default GovFields