import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import { useParams } from "react-router-dom";
import { callApiAction } from "../../store/actions/commonAction";
import { grayInputStyle } from "./SearchBar";


const AsyncDropDown = (props) => {

    const param = useParams()

    const [buffer, setBuffer] = useState(null)
    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 50,
        search: '',
        isDropDown: true
    })
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState(props.defaultOptions ? props.defaultOptions :[]);
    const dispatch = useDispatch()


    const fetchOptions = (data, onSucces = () => { }) => {
        setLoading(true)
        const bodyData = data ? data : { ...filters }
        dispatch(
            callApiAction(
                async () => await props.lazyFun(bodyData),
                (response) => {
                    let defaultOptions = []
                    const updatedOptions = response.result
                    if (props.defaultOptions) {
                        defaultOptions = props.defaultOptions
                    }
                    onSucces()
                    setOptions([...defaultOptions, ...updatedOptions])
                    setLoading(false)

                },
                (err) => {
                    setLoading(false)
                }
            )
        )
    }
    useEffect(() => {

        if (!loading && buffer && Object.keys(buffer).length > 0) {
            fetchOptions({ ...buffer }, () => {
                if (buffer.search === filters.search) {
                    setBuffer(undefined)
                }
            })
            setBuffer(undefined)
        }
    }, [loading])

    let [timeOut, setTimeOutState] = useState()
    useEffect(() => {

        if (timeOut) {

            clearTimeout(timeOut)
        }
        const fun = () => {

            if (!loading) {
                fetchOptions()
            }
            else
                setBuffer(filters)

            clearTimeout(timeOut)
        }
        if (filters.search && filters.search.length > 0) {
            timeOut = setTimeout(fun, 500)
            setTimeOutState(timeOut)
        }


    }, [filters, setBuffer])


    const Component = props.InputComponent ? props.InputComponent : TextField
    const OptionComponent = props.OptionComponent ? props.OptionComponent : null
    return <Autocomplete
        fullWidth={true}
        defaultValue={props.defaultVal}
        autoFocus={false}
        onChange={(e, newVal) => { props.onChange(newVal) }}
        noOptionsText={filters.search.length > 0 ? "No Options" : "Search for the result..."}
        disabled={props.disabled}
        filterOptions={(options, state) => options}
        renderOption={OptionComponent?(props, option) => OptionComponent ? <OptionComponent option={option} key={option[props.titleKey]} {...props} /> : undefined:undefined}
        getOptionLabel={(option) => option[!props.titleKey ? 'title' : props.titleKey]}
        options={options}
        loading={loading}
        onFocus={() => {
            if (options.length == 0 && !loading) {                
                fetchOptions({...filters,search:''})
                setFilters({...filters,search:''})
            }
        }}
        renderInput={(params) => (
            <Component

                autoFocus={false}
                onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }}

                margin="dense"
                {...params}

                label={props.label}
                InputProps={{
                    ...params.InputProps,
                    startAdornment: props.startAdornment ? props.startAdornment : params.InputProps.startAdornment ? params.InputProps.startAdornment : null,
                    sx: (theme) => props.inputStyle ? props.inputStyle({ theme }) : {},
                    endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {!props.hideEndAdornment && params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}

            />
        )}
        multiple={props.multiple}
    // freeSolo
    // {...props}
    />
}
export default memo(AsyncDropDown)