import { Autocomplete, Box, CircularProgress, FormControl, FormControlLabel, FormLabel, MenuItem, Paper, Radio, RadioGroup, Step, StepLabel, Stepper, Typography } from "@mui/material"
import { Fragment, memo } from "react"
import { useDispatch, useSelector } from 'react-redux'

import CustomInput from "../../components/inputs/CustomInput"
import { AUS_STATES, USER_ROLES } from "../../utils/constants"
import { findNameByRole, findObjectKeyByValue, getAllowedRoles, titleCase } from "../../utils/helper"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { SEVEARITY_OF_LOSS, TYPE_OF_HEARING_LOSS, TYPE_OF_HEARING_LOSS_LIST, WAY_OF_CONFIRMATION } from "../../utils/beneficiary.constant"
import FileInput from "../../components/inputs/FileInput"






const UpdateHearingTestDetailsUi = ({ title, modalKey, fields, setFields, isUpdate, loading, onSubmit }) => {
    const { user } = useSelector(state => state)


    return <>


        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            <Autocomplete
                value={TYPE_OF_HEARING_LOSS_LIST[fields.type_of_hearing_loss - 1]?.label}
                // / findObjectKeyByValue(fields.type_of_hearing_loss, TYPE_OF_HEARING_LOSS)
                disableClearable
                onChange={(e, changedVal) => { setFields({ ...fields, type_of_hearing_loss: changedVal ? changedVal._id : null, }) }}
                options={TYPE_OF_HEARING_LOSS_LIST}

                sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                renderInput={(params) => <CustomInput label="Type Of Hearing Loss*" {...params} />}
            />

            <Autocomplete
                value={findObjectKeyByValue(fields.severity_of_hearing_loss, SEVEARITY_OF_LOSS)}
                disableClearable
                onChange={(e, changedVal) => { setFields({ ...fields, severity_of_hearing_loss: changedVal ? changedVal._id : null, }) }}
                options={Object.keys(SEVEARITY_OF_LOSS).map((key) => ({ label: titleCase(key), _id: SEVEARITY_OF_LOSS[key] }))}

                sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                renderInput={(params) => <CustomInput label="Severity Of Hearing Loss*" {...params} />}
            />

            <FileInput
                defaults={fields.audio_gram_file ? [fields.audio_gram_file] : undefined}
                title="Audiogram (if Available)"
                subTitle="Only .pdf less than 2 mb is required."
                accept=".pdf"
                onChange={(newUrl) => {
                    setFields({ ...fields, audio_gram_file: newUrl })
                }} />

            {/* <FileInput
                defaults={fields.hearing_test_report ? [fields.hearing_test_report] : undefined}
                title="Hearing test report (if Available)"
                subTitle="Only .pdf less than 2 mb is required."
                accept=".pdf"
                onChange={(newUrl) => {
                    setFields({ ...fields, hearing_test_report: newUrl })
                }} /> */}


            <FormControl fullWidth>
                <FormLabel >HearNU device Response?</FormLabel>
                <RadioGroup
                    row
                    value={fields.nu_device_responce}
                    onChange={(e) => {
                        setFields({ ...fields, nu_device_responce: e.target.value })
                    }}
                >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />

                </RadioGroup>
            </FormControl>




            <FormControl fullWidth margin="dense">
                <FormLabel >Music Response?</FormLabel>
                <RadioGroup
                    row
                    value={fields.music_responce}
                    onChange={(e) => {
                        setFields({ ...fields, music_responce: e.target.value })
                    }}
                >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />

                </RadioGroup>
            </FormControl>




            <FormControl fullWidth margin="dense">
                <FormLabel >Voice Response?</FormLabel>
                <RadioGroup
                    row
                    value={fields.voice_responce}
                    onChange={(e) => {
                        setFields({ ...fields, voice_responce: e.target.value })
                    }}
                >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />

                </RadioGroup>
            </FormControl>



            <FormControl fullWidth margin="dense">
                <FormLabel >Has Smartphone?</FormLabel>
                <RadioGroup
                    row
                    value={fields.has_smart_phone}
                    onChange={(e) => {
                        setFields({ ...fields, has_smart_phone: e.target.value })
                    }}
                >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />

                </RadioGroup>
            </FormControl>

            <Autocomplete
                value={findObjectKeyByValue(fields.way_of_confirmation, WAY_OF_CONFIRMATION)}
                disableClearable
                onChange={(e, changedVal) => { setFields({ ...fields, way_of_confirmation: changedVal ? changedVal._id : null, }) }}
                options={Object.keys(WAY_OF_CONFIRMATION).map((key) => ({ label: titleCase(key), _id: WAY_OF_CONFIRMATION[key] }))}

                sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                renderInput={(params) => <CustomInput label="Way Of Confirmation*" {...params} />}
            />

           
            {!(fields.nu_device_responce =='false' &&  fields.voice_responce=='false' && fields.music_responce =='false') && <>
            
                <CustomInput

                    disabled={loading}
                    value={fields.left_response_in_db}
                    onChange={(e) => setFields({ ...fields, err: '', left_response_in_db: e.target.value })}
                    type="number"
                    label={"Left Ear Response(dB)*"}

                />
                <CustomInput

                    disabled={loading}
                    value={fields.right_response_in_db}
                    onChange={(e) => setFields({ ...fields, err: '', right_response_in_db: e.target.value })}
                    type="number"
                    label={"Right Ear Response(dB)*"}

                />
            </>}

        </CustomDialog>

    </>
}
export default memo(UpdateHearingTestDetailsUi)