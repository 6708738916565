import { Autocomplete, Box, Button, Chip, CircularProgress, Grid, MenuItem, Tab, Tabs } from "@mui/material"
import { useCallback, useEffect, useRef, useState } from "react"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"

import NoDataComponent from "../../../components/layouts/NoDataComponent"
import { StyledSearchBar } from "../../../components/inputs/SearchBar"
import BeneficiaryDetailedViewController from "../../beneficiary/detailedview/DetailedViewController"
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import { Search } from "@mui/icons-material"



const BeneficiaryFromUDISE = ({ filters, loading, setFilters, list, modalKey, onSubmit, title }) => {
    const dispatch = useDispatch()

    const [val, setVal] = useState('')

    const onBenificaryClick = useCallback((beneficiaryId) => {
        dispatch(openModal(<BeneficiaryDetailedViewController
            id={beneficiaryId}

        />, "md", false, "beneficiary-detailed-view"))
    }, [])

    const ref = useRef()

    const onSearch = (e) => {

        e.preventDefault()

        setFilters({ ...filters, udise: val })


    }


    return <>
        <CustomDialog
            id={modalKey}
            loading={loading}
            title={title}
            closeText="Close"
            onSubmit={onSubmit}
            confirmText="Add"
            dialogProps={{ sx: { height: "1000px", overflow: "hidden" } }}
        >
            <Box sx={(theme) => ({ height: "100%", width: "100%", display: "flex", flexDirection: "column", overflow: "hidden" })}>
                <Box >
                    <Box mt={1} mb={3} ref={ref} id="search-form" sx={{ display: "flex" }}>

                        <Box sx={{ display: "flex", flex: 1 }}>
                            <StyledSearchBar placeholder={"Type UDISE Number"} fullWidth value={val} onChange={(e) => setVal(e.target.value)} />
                        </Box>
                        <Button sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} onClick={onSearch} disableElevation variant="contained" type="submit"  >
                            <Search />
                        </Button>




                    </Box>
                </Box>
                {loading ? <CenteredBox><CircularProgress size={30} /></CenteredBox> :

                    <>
                        <Box sx={{ display: "flex", flexWrap: "wrap", overflowY: "auto" }}>
                            {
                                list && list.length > 0 ? list.map((item) => <Chip onClick={() => {
                                    onBenificaryClick(item._id)
                                }} sx={{ m: 1 }} label={item.name + ' ' + item.surname} key={item._id} />) : <NoDataComponent message="No Data Found" />
                            }
                        </Box>
                    </>
                }
            </Box>
        </CustomDialog>
    </>
}
export default BeneficiaryFromUDISE