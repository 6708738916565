import { Box, Button, IconButton, ListItem } from "@mui/material"
import AsyncDropDown from "../inputs/AsyncDropDown"
import { getRegionApi } from "../../apis/region.api"
import CustomInput from "../inputs/CustomInput"
import { Add, AddBox } from "@mui/icons-material"
import { CenteredBox } from "../layouts/OneViewBox"
import { useDispatch, useSelector } from "react-redux"
import { openModal } from "../../store/actions/modalAction"
import CreateController from "../../pages/region/CreateController"
import { StyledSearchBar } from "../inputs/SearchBar"
import MODULES from "../../utils/module.constant"

const CreatableRegionComponent = ({ type, onChange, value, label, parent, disabled ,onlyDropDown,...rest}) => {
    const {user} = useSelector(state=>state)
    const dispatch = useDispatch()
    const onCreateButtonClick = () => {
        dispatch(openModal(<CreateController type={type} />, "sm", false, 'region'))
    }
    const allowedModules = user.data.modules??[]
    if(onlyDropDown)
  return   <AsyncDropDown
            key={parent}
            disabled={disabled}
            lazyFun={async (para) => { return await getRegionApi({ type, parent,...para }) }}
            
            OptionComponent={({ option, ...rest }) => {
                return <ListItem {...rest}>{option.name}</ListItem >
            }}
            onChange={async (changedVal) => {
                
                onChange(changedVal)
            }}
            titleKey={'name'}
            valueKey={"_id"}
            defaultVal={value}            
            InputComponent={(params) => <StyledSearchBar size="small" placeholder={label} {...params} margin="none" />}
            {...rest}
        />
    return <Box sx={{ display: "flex" }} mt={3} mb={2}>
        <AsyncDropDown
            key={parent}
            disabled={disabled}
            lazyFun={async (para) => { return await getRegionApi({ type, parent,...para }) }}
            
            OptionComponent={({ option, ...rest }) => {
                return <ListItem {...rest}>{option.name}</ListItem >
            }}
            onChange={async (changedVal) => {
                
                onChange(changedVal)
            }}
            titleKey={'name'}
            valueKey={"_id"}
            defaultVal={value}
            label={label}
            InputComponent={(params) => <CustomInput size="small" placeholder={label} {...params} margin="none" />}
            {...rest}
        />
       {allowedModules.includes(MODULES.REGIONS) &&  <Button onClick={onCreateButtonClick} variant="contained" size="small" sx={{ ml: 1 }} >
            <Add />
        </Button>}
    </Box>
}
export default CreatableRegionComponent