import { Box, Chip, Grid, Typography, styled } from "@mui/material"
import ImageComponent from "../../../components/inputs/ImageComponent"
import moment from "moment"
import { findObjectKeyByValue } from "../../../utils/helper"
import { GENDER } from "../../../utils/constants"
import FileDownloadComponent from "../../../components/FileDownloadComponent"
import NoDataComponent from "../../../components/layouts/NoDataComponent"
import { BENIFICIARY_LOG_STATUS } from "../../../utils/beneficiary.constant"

const LogContainer = styled(Box)(({ theme }) => ({
    border: '1px solid ' + theme.palette.primary.main,
    padding: theme.spacing(2),
    background: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius * 2,

    width: "100%"
}))
const Body = styled(Box)(({ theme }) => ({
    border: '1px solid ' + theme.palette.primary.main,
    padding: theme.spacing(2),

    marginBottom: theme.spacing(2),

    borderBottomLeftRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
    width: "100%"
}))
const LogDetails = ({ data }) => {
    return <>
        {
            data.logs && data.logs.length > 0 ? <>
                {
                    data.logs?.sort((a,b)=>moment(b.date).valueOf()-moment(a.date).valueOf()).map((log) => <LogContainer key={log._id} >
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6} >
                                <Typography fontWeight="bold" variant="body2">Actioned By:</Typography>

                            </Grid>
                            <Grid item md={6} xs={6} >
                                <Typography variant="body2">{log.by ? log.by.name : "NA"}</Typography>
                            </Grid>

                            <Grid item md={6} xs={6} >
                                <Typography fontWeight="bold" variant="body2">Date:</Typography>

                            </Grid>
                            <Grid item md={6} xs={6} >
                                <Typography variant="body2">{moment(log.date).format("DD MMM YYYY")}</Typography>
                            </Grid>



                            <Grid item md={6} xs={6} >
                                <Typography fontWeight="bold" variant="body2">Activity Title:</Typography>

                            </Grid>
                            <Grid item md={6} xs={6} >
                                <Typography variant="body2"><Chip color="info" size="small" label={log.status!=null && log.status!=undefined  ? findObjectKeyByValue(log.status, BENIFICIARY_LOG_STATUS) : "NA"} /></Typography>
                            </Grid>

                            <Grid item md={6} xs={6} >
                                <Typography fontWeight="bold" variant="body2">Remarks:</Typography>

                            </Grid>
                            <Grid item md={6} xs={6} >
                                <Typography variant="body2">{log.remarks ?? "NA"}</Typography>
                            </Grid>
                        </Grid>
                    </LogContainer>)
                }
            </> : <NoDataComponent message="No log found" />
        }
    </>
}
export default LogDetails