const MODULES = {
    DASHBOARD: 1,
    BENIFIICIARY_MODULE: 2,//FOR UI PURPOSE
    BENIFICIARY_FETCH_MODULE:24,
    BENIFIICIARY_DONATIONS: 3,//LIST OF BENIFICIARY
    
    BENIFICIARY_ADD: 4,
    BENIFICIARY_TEST: 5,
    BENIFICIARY_UPDATE: 6,
    BENIFICIARY_VALIDATE: 7,
    BENIFICIARY_LOGS: 8,//WILL SHOW LOGS OF BENIFCIARY

    USER_MODULE: 9,//UI PURPOSE
    ALL_USER:25,
    DONORS: 10,//FOR DONORS CRUD
    NGOS: 11,//''
    BRC:12,
    TEACHER: 13,//''
    GOVEREMENT: 14,//''

    REGIONS: 15,//''
    CAMPS: 16,//''

    ADMIN_AREA: 17,//FOR ADMIN AREA SHOWN IN UI
    EVENT_MODULE: 18,//FOR UI PURPOSE
    EVENT_CREATE_UPDATE: 19,//EVENT CREATE & UPDATE
    EVENT_BENIFICIARY_ASSIGN: 20,//EVENT BENIFICIARY ASSIGN

    ASSIGN_FOLLOWUP: 21,//WILL SHOW PANNEL FOR ASSIGNING FOLLOWUP
    FOLLOWUP_LIST: 22,///WILL SHOW FOLLOWUP LIST
    UPDATE_FOLLOWUP: 23,//WILL UPDATE FOLLOWUP
    WHOLE_DASHBOARD: 26,
    VIDEO_MODULE: 27,
    PRODUCTS : 28,

    BENIFICIARY_ROW_VIDEO:29
}
//last count 28
export default MODULES
/*
//user wise access

ADMIN: *
IMPACT_ANALYSIS_TEAM:
    DASHBOARD
    BENIFIICIARY_MODULE
    BENIFIICIARY_DONATIONS
    BENIFICIARY_LOGS
    EVENT_MODULE

REGISTRATION_TEAM
    BENIFIICIARY_MODULE
    BENIFICIARY_ADD
    USER_MODULE
    BRC:12,
    TEACHER
    GOVEREMENT
    REGIONS
    CAMP

TESTING_TEAM
    BENIFIICIARY_MODULE
    BENIFICIARY_TEST

VALIDATION_TEAM
    BENIFIICIARY_MODULE
    BENIFICIARY_UPDATE
    BENIFICIARY_VALIDATE
    BRC:12,
    TEACHER
    GOVEREMENT
    REGIONS
    CAMP

EVENT_TEAM
    EVENT_MODULE
    EVENT_BENIFICIARY_ASSIGN
    EVENT_CREATE_UPDATE
    USER_MODULE
    NGOS
    DONORS

SIT_MANAGER
    FOLLOWUP_LIST
    EVENT_MODULE
    ASSIGN_FOLLOWUP

SIT_TEAM
    FOLLOWUP_LIST
    UPDATE_FOLLOWUP

GOVERMENT
    DASHBOARD
    BENIFIICIARY_MODULE
    BENIFIICIARY_DONATIONS

DONOR
    BENIFIICIARY_MODULE
    BENIFIICIARY_DONATIONS

NGO
    BENIFIICIARY_MODULE
    BENIFIICIARY_DONATIONS

TEACHER
    BENIFIICIARY_MODULE
    BENIFIICIARY_DONATIONS

BRC:12,

    BENIFIICIARY_MODULE
    BENIFIICIARY_DONATIONS
*/