import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "./../../store/actions/commonAction"
import { getGovByIdApi, getGovsApi } from "./../../apis/user.api"

import { openModal } from "./../../store/actions/modalAction"

import { Box, Chip, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material"
import { useMemo } from "react"
// import CreateController from "./CreateController"
import { Add, AddPhotoAlternate, AddPhotoAlternateOutlined, AddTask, Download, Edit, GroupAdd, Info } from "@mui/icons-material"
import { USER_ROLES } from "./../../utils/constants"

import UpdateController from "./CreateController"
import CreateController from "./CreateController"
import StaffCreateController from "./staff/CreateController"
import ImagesCreateController from "./images/CreateController"
import { downloadCampReport, getCampApi } from "../../apis/camp.api"
import moment from "moment"
import { CAMP_STATUS } from "../../utils/beneficiary.constant"
import CloseCampController from "./closecamp/CloseCampController"
import CampDetailedViewController from "./detailedView/CampDetailedViewController"
import fileDownload from "js-file-download"

const DownloadReportComponent = ({ params }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const onClick = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await downloadCampReport({ id: params._id }),
            (response) => {
                fileDownload(response, params.name + ".pdf")
                setLoading(false)
            },
            (err) => {
                console.log(err)
                setLoading(false)
            },
            true
        ))
    }
    if (loading)
        return <CircularProgress size={25} />
    return <IconButton onClick={onClick} >
        <Download color="primary" />
    </IconButton>
}
const ActionComponent = memo(({ params, setParams }) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)


    const onEdit = () => {
        dispatch(openModal(<UpdateController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm", false, "create-camp"))
    }
    const onClosure = () => {
        dispatch(openModal(<CloseCampController start_date={params.start_date} id={params._id} name={params.name} callBack={(response, updatedData) => {
            setParams({ ...params, status: CAMP_STATUS.CLOSED })
        }} />, "sm", false, "camp-close"))
    }

    const onViewMore = () => {
        dispatch(openModal(<CampDetailedViewController id={params._id} />, "md", false, "camp-details"))
    }



    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <Tooltip title="view more">

            <IconButton disabled={loading} size="inherit" onClick={onViewMore}>
                <Info color="info" fontSize="inherit" />
            </IconButton>
        </Tooltip>
        <Tooltip title="Edit details">
            <IconButton disabled={loading} size="inherit" onClick={onEdit}>
                <Edit color="info" fontSize="inherit" />
            </IconButton>
        </Tooltip>

        {params.status == CAMP_STATUS.OPEN ? <Tooltip title="Close">
            <IconButton disabled={loading} size="inherit" onClick={onClosure}>
                <AddTask color="success" fontSize="inherit" />
            </IconButton>
        </Tooltip> :
            <DownloadReportComponent params={params}  />
        }


    </Box>
})

const AddStaffComponent = memo(({ params, setParams }) => {
    const dispatch = useDispatch()


    const onClick = () => {
        dispatch(openModal(<StaffCreateController id={params._id} name={params.name} callBack={(response, updatedData) => {

        }} />, "sm", false, "camp-staff"))
    }





    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <Tooltip title="Add Staff">

            <IconButton size="inherit" onClick={onClick}>
                <GroupAdd color="info" fontSize="inherit" />
            </IconButton>
        </Tooltip>

    </Box>
})
const AddImagesComponent = memo(({ params, setParams }) => {
    const dispatch = useDispatch()


    const onClick = () => {
        dispatch(openModal(<ImagesCreateController id={params._id} name={params.name} callBack={(response, updatedData) => {

        }} />, "md", false, "create-camp-images"))
    }





    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <Tooltip title="Add Images">

            <IconButton size="inherit" onClick={onClick}>
                <AddPhotoAlternate color="info" fontSize="inherit" />
            </IconButton>
        </Tooltip>

    </Box>
})

const ListController = () => {
    const dispatch = useDispatch()



    const title = "Camp "

    const fetchApi = getCampApi


    const columns = useMemo(() => [

        { id: 0, minWidth: "50px", fieldName: 'start_date', label: 'Camp Date', align: "left", sort: true, renderValue: (params) => `${params.start_date ? moment(params.start_date).format("DD/MM/YYYY HH:mm") : "NA"}${params.end_date ? "-" + moment(params.end_date).format("DD/MM/YYYY HH:mm") : ""}` },
        { id: 1, minWidth: "50px", fieldName: 'name', label: 'Camp Name', align: "left", sort: true },
        { id: 2, minWidth: "50px", fieldName: 'total_expected_children', label: 'Expected Children', align: "left", sort: true },

        { id: 4, minWidth: "50px", fieldName: 'total_arrived_children', label: 'Arrived Childrens', align: "left", sort: true },
        { id: 5, minWidth: "50px", fieldName: 'images_length', label: 'Images', align: "left" },
        { id: 6, minWidth: "50px", fieldName: 'status', label: 'Status', align: "left", sort: true, renderValue: (params) => params.status == CAMP_STATUS.OPEN ? <Chip label="Open" color="warning" size="small" /> : <Chip label="Closed" color="success" size="small" /> },


        {
            id: 8,
            fieldName: '',
            label: 'Add Staff',

            align: "right",
            renderValue: (params, setParams) => <AddStaffComponent params={params} setParams={setParams} />,
        },
        {
            id: 9,
            fieldName: '',
            label: 'Add Images',

            align: "right",
            renderValue: (params, setParams) => <AddImagesComponent params={params} setParams={setParams} />,
        },
        {
            id: 7,
            fieldName: '',
            label: 'Action',

            align: "right",
            renderValue: (params, setParams) => <ActionComponent params={params} setParams={setParams} />,
        }

    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        role: '',
        sort: '',
        sortDirection: 1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController callBack={async () => { fetchList() }} />, "sm", false, "create-camp"))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                title={title}
                onCreateBtnClick={onCreateBtnClick}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController