import { Close, Download, Visibility } from "@mui/icons-material";
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Fade, IconButton, Modal, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { getFile, getFileDirect } from "../apis/file.api";
import fileDownload from 'js-file-download'
const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});
const FileDownloadComponent = ({ src, direct, heades = false, view = false,iconStyle={} }) => {
    const [loading, setLoading] = useState(false)
    const filePath = src ? src.split('/') : []
    const fileName = filePath[filePath.length - 1]


    const fetchImage = async () => {
        setLoading(true)
        const file = direct ? await getFileDirect(src, heades) : await getFile(src)
        const fileNameSplitted = src?.split(".")
        const fileExtensionWithQuery = fileNameSplitted?.[fileNameSplitted.length - 1]
        const fileExtension = fileExtensionWithQuery?.split("?")?.[0]


        if (view) {
            let type = "text/plain"

            if (fileExtension == "jpg" || fileExtension == "jpeg" || fileExtension == "png" || fileExtension == "svg" || fileExtension == "gif") {
                type = "image/" + fileExtension
            }
            if (fileExtension == 'pdf') {
                type = 'application/pdf'
            }
            const blobFile = new Blob([file], { type })
            const objectUrl = URL.createObjectURL(blobFile);
            window.open(objectUrl);
        }
        else

            fileDownload(file, fileName + "." + fileExtension)



        setLoading(false)
    }



    if (loading)
        return <CircularProgress />
    if (view)
        return (

            <>
                <IconButton sx={{...iconStyle}} onClick={fetchImage} ><Visibility fontSize="inherit" /></IconButton>

            </>)
    return (

        <>
            <IconButton sx={{...iconStyle}} onClick={fetchImage} ><Download  fontSize="inherit" /></IconButton>

        </>)
}
export default FileDownloadComponent