import { Autocomplete, CircularProgress, MenuItem } from "@mui/material"
import { Fragment, memo } from "react"

import CustomInput from "../../../components/inputs/CustomInput"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"

import DonorFields from "./DonorFields"




const CreateUi = ({ title, modalKey, fetchLoading, isUpdate, fields, setFields, loading, onSubmit }) => {

    return <>

        <CustomDialog
            id={modalKey}
            loading={loading || fetchLoading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {(loading || fetchLoading) && <CenteredBox><CircularProgress /></CenteredBox>}
            {!fetchLoading &&
                <>
                    <CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.name}
                        onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                        type="text"
                        label={"Company Name*"}

                    />




                    {
                        <DonorFields fields={fields} setFields={setFields} loading={loading} />
                    }
                 
                </>}

        </CustomDialog>


    </>
}
export default memo(CreateUi)