import { Box, } from "@mui/system";
import { styled,ListItem,Paper,Grid,Skeleton,Typography, ButtonBase, Autocomplete} from "@mui/material";

import { REGIONS_TYPE, USER_ROLES } from "../../../utils/constants";
import DataTable from "../../../components/tables/DataTable";

// import {
//   findObjectKeyByValue,
//   titleCase,
//   toTitleCase,
// } from "../../utils/helper";
import {
    StyledSearchBar,
  } from "../../../components/inputs/SearchBar";
import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop";
import AsyncDropDown from "../../../components/inputs/AsyncDropDown";
import { getRegionApi } from "../../../apis/region.api";
import CreatableRegionComponent from "../../../components/custom/CreateableRegionComponent";

import { getEventApi } from "../../../apis/event.api";
import { getDonorsApi } from "../../../apis/user.api";
import { getDashboardApi } from "../../../apis/dashboard.api";
import { useDispatch, useSelector } from "react-redux";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import SubmitButton from "../../../components/button/SubmitButton";
import { HEARING_STATE, SPEECH_STATE } from "../../../utils/beneficiary.constant";
import { titleCase } from "../../../utils/helper";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space- tween",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const CountBoxContainer = styled(Box)(({ theme, active }) => ({
    background: active ? theme.palette.primary.main : theme.palette.light.main,
    border: "1px solid " + theme.palette.grey.main,
    borderRadius: theme.shape.borderRadius,
    height: "100%",
    "*": {
        color: active ? theme.palette.light.main + "!important" : ''
    },
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))

const CountBoxComponent = ({ part, count, title, subtitle, active, onClick, loading }) => {
    if (loading) {
        return <Grid item sm={part} xs={6}>
            <Skeleton variant="rounded" width="100%" height="100px" >

            </Skeleton>
        </Grid>
    }
    return <Grid item sm={part} xs={6}>
    <CountBoxContainer active={active} component={ButtonBase} onClick={onClick} sx={{ width: "100%" }}  >
        <Box p={2}>

            <Typography color="primary" variant="h3" align="center">
                {count}
            </Typography>
            <Typography variant="h6" fontWeight="bold" align="center">
                {title}
            </Typography>
            {subtitle && <Typography variant="caption" fontWeight="bold" align="center">
                {subtitle}
            </Typography>}
        </Box>
    </CountBoxContainer>
</Grid>

}

const ListUi = ({
  title,
  filters,
  setFilters,
  list,
  modalKey,
  loading,
  onCreateBtnClick,
  columns,
  exportLoading,
  onExport,
  counts,
  countsLoading,
  ChildCounts,
  setChildCounts
}) => {
  const user = useSelector((state) => state.user);
console.log("LIST",list)
  return (
    <>
      <CustomDialog
        id={modalKey}
        loading={loading}
        title={`${title}`}
        closeText="Close"
      >
        <Box sx={{ display: "flex" }} mb={3}>
          <Box>
            <SubmitButton
              title="Export"
              onClick={onExport}
              loading={exportLoading}
            />
          </Box>
        </Box>

        <Box component={Paper} p={3} mb={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
            }}
          >
            <FiltersBox mt={3}>
              <PaddingBoxInDesktop
                mb={2}
                sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                <AsyncDropDown
                lazyFun={async (para) => {
                  // console.log("PARA",para)
                  return await getEventApi({ ...para });
                }}
                  // lazyFun={getEventApi}
                  OptionComponent={({ option, ...rest }) => {
                    return <ListItem {...rest}>{option.name}</ListItem>;
                  }}
                  onChange={async (changedVal) => {
                    setFilters({
                      ...filters,
                      donation_event_id: changedVal ? changedVal._id : null,
                    });
                  }}
                  titleKey={"name"}
                  valueKey={"_id"}
                  InputComponent={(params) => (
                    <StyledSearchBar
                      placeholder={"Select Event"}
                      {...params}
                      margin="none"
                    />
                  )}
                />
              </PaddingBoxInDesktop>

{/* <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                            <AsyncDropDown


                                lazyFun={getEventApi}

                                OptionComponent={({ option, ...rest }) => {
                                    return <ListItem {...rest}>{option.name}</ListItem >
                                }}
                                onChange={async (changedVal) => {
                                    setFilters({ ...filters, donation_event_id: changedVal ? changedVal._id : null, })
                                }}
                                titleKey={'name'}
                                valueKey={"_id"}
                                InputComponent={(params) => <StyledSearchBar placeholder={"Select Event"} {...params} margin="none" />}
                            />
                        </PaddingBoxInDesktop> */}

              <FiltersBox >

<PaddingBoxInDesktop
  mb={2}
  // mt={1}
  sx={{
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    "*": {
      justifyContent: "center",
    },
  }}
  // pl={3}
>
  <Autocomplete
    disableClearable
    onChange={(e, newVal) => {
      setFilters({
        ...filters,
        hearing_state: newVal ? newVal._id : null,
      
      });
    }}
    options={[
      { label: "All", _id: null },
      ...Object.keys(HEARING_STATE).map((key) => ({
        label: titleCase(key),
        _id: HEARING_STATE[key],
      })),
    ]}
    sx={{
      width: "100%",
      display: "flex",
      "*": { display: "flex", justifyContent: "center", flex: 1 },
    }}
    renderInput={(params) => (
      <StyledSearchBar
        placeholder="Select Hearing State"
        {...params}
      />
    )}
    // renderInput={(params) => <CustomInput label="Select Hearing State*" {...params} />}
  />
</PaddingBoxInDesktop>
<PaddingBoxInDesktop
  mb={2}
  // mt={1}
  sx={{
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    "*": {
      justifyContent: "center",
    },
  }}
  pl={3}
>
  <Autocomplete
    disableClearable

    onChange={(e, newVal) => {
      setFilters({
        ...filters,
        speech_state: newVal ? newVal._id : null,
      });
    }}
    options={[
      { label: "All", _id: null },
      ...Object.keys(SPEECH_STATE).map((key) => ({
        label: titleCase(key),
        _id: SPEECH_STATE[key],
      })),
    ]}
    sx={{
      width: "100%",
      display: "flex",
      "*": { display: "flex", justifyContent: "center", flex: 1 },
    }}
    renderInput={(params) => (
      <StyledSearchBar
        placeholder="Select Speech State"
        {...params}
      />
    )}
  />
</PaddingBoxInDesktop>
</FiltersBox>
              <PaddingBoxInDesktop
                mb={2}
                // mt={1}
                sx={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  "*": {
                    justifyContent: "center",
                  },
                }}
                // pl={3}
              >
                <AsyncDropDown
                  // key={filters.donor}
                  lazyFun={async (para) => {
                    return await getDonorsApi({ ...para });
                  }}
                  OptionComponent={({ option, ...rest }) => {
                    return <ListItem {...rest}>{option.name}</ListItem>;
                  }}
                  onChange={async (changedVal) => {
                    setFilters({
                      ...filters,
                      donor: changedVal ? changedVal._id : null,
                    });
                  }}
                  titleKey={"name"}
                  valueKey={"_id"}
                  InputComponent={(params) => (
                    <StyledSearchBar
                      placeholder={"Select Donor"}
                      {...params}
                      margin="none"
                    />
                  )}
                />
              </PaddingBoxInDesktop>

            </FiltersBox>
            <FilterTitleBox>
              <PaddingBoxInDesktop
                mb={2}
                sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                <CreatableRegionComponent
                  onlyDropDown
                  disabled={loading}
                  value={
                    typeof filters.country == "object" ? filters.country : null
                  }
                  label={"Select Country*"}
                  type={REGIONS_TYPE.COUNTRY}
                  parent={null}
                  onChange={async (changedVal) => {
                    setFilters({
                      ...filters,
                      country: changedVal ? changedVal._id : null,
                      state: null,
                      district: null,
                      taluka: null,
                    });
                  }}
                />
              </PaddingBoxInDesktop>

              <PaddingBoxInDesktop
                pl={2}
                mb={2}
                sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                <CreatableRegionComponent
                  onlyDropDown
                  disabled={
                    loading || !filters.country || filters.country == null
                  }
                  value={
                    typeof filters.state == "object" ? filters.state : null
                  }
                  type={REGIONS_TYPE.STATE}
                  onChange={async (changedVal) => {
                    setFilters({
                      ...filters,
                      state: changedVal ? changedVal._id : null,
                      district: null,
                      taluka: null,
                    });
                  }}
                  parent={
                    filters.country && typeof filters.country == "object"
                      ? filters.country["_id"]
                      : filters.country
                  }
                  label={"Select State*"}
                />
              </PaddingBoxInDesktop>

              <PaddingBoxInDesktop
                pl={2}
                mb={2}
                sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                <CreatableRegionComponent
                  onlyDropDown
                  disabled={loading || !filters.state || filters.state == null}
                  value={
                    typeof filters.district == "object"
                      ? filters.district
                      : null
                  }
                  type={REGIONS_TYPE.DISTRICT}
                  onChange={async (changedVal) => {
                    setFilters({
                      ...filters,
                      district: changedVal ? changedVal._id : null,
                      taluka: null,
                    });
                  }}
                  label={"Select District*"}
                  parent={
                    filters.state && typeof filters.state == "object"
                      ? filters.state["_id"]
                      : filters.state
                  }
                />
              </PaddingBoxInDesktop>

              <PaddingBoxInDesktop
                pl={2}
                mb={2}
                sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                <CreatableRegionComponent
                  onlyDropDown
                  disabled={
                    loading || !filters.district || filters.district == null
                  }
                  value={
                    typeof filters.taluka == "object" ? filters.taluka : null
                  }
                  type={REGIONS_TYPE.TALUKA}
                  onChange={async (changedVal) => {
                    setFilters({
                      ...filters,
                      taluka: changedVal ? changedVal._id : null,
                    });
                  }}
                  label={"Select Taluka*"}
                  parent={
                    filters.district && typeof filters.district == "object"
                      ? filters.district["_id"]
                      : filters.district
                  }
                />
              </PaddingBoxInDesktop>
            </FilterTitleBox>
          </Box>
          {/* <Grid container spacing={2} mt={2}>
                    {
                        !countsLoading && counts.length > 0 ? <>
                            {
                                counts.map((count) => <CountBoxComponent
                                    key={count.title}
                                    onClick={() => {
                                        setFilters({ ...filters, nuResponse: count.value, donated: null });
                                        setChildCounts(count.children)
                                    }}
                                    setFilters={setFilters}
                                    filters={filters}
                                    active={count.value === filters.nuResponse}
                                    part={12 / counts.length}
                                    title={count.title}
                                    subtitle={count.subtitle}
                                    count={count.count} />)
                            }
                        </> : <>
                            {[0, 1, 2].map(item => <CountBoxComponent part={12 / 3} loading={true} />)}
                        </>
                    }
                </Grid> */}
        </Box>
        <Box sx={{ minHeight: "300px" }} className="table">
          <DataTable
            columns={columns}
            rows={list.result ? list.result : []}
            count={list.total ?? 0}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
          />
        </Box>
      </CustomDialog>
    </>
  );
};
export default ListUi;
