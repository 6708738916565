import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"

import { closeModal } from "../../../store/actions/modalAction"

import { addCampApi, createCampStaffApi, getCampByIdApi } from "../../../apis/camp.api"
import { toTitleCase } from "../../../utils/helper"

const CreateController = ({ callBack=()=>{}, id, name }) => {
    const validate = useValidate()
    const dispatch = useDispatch()

    const modalKey = 'camp-staff'
    const title = "Staff for "+toTitleCase(name)+ " Camp"

    const createApi = createCampStaffApi


    const [loading, setLoading] = useState(false)

    const [fetchLoading, setFetchLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        camp_id: id,
        name: '',
        email: '',
        phone: '',
        designation: ''




    })



    const validationSchemaCreate = useMemo(() => ([

        {
            required: true,
            value: fields.name,
            field: ' Name',
        },
        {
            required: true,
            value: fields.email,
            isEmail: true,
            field: 'Email',
        },
        {
            required: true,
            value: fields.phone,
            field: 'Phone',
        },
        {
            required: true,
            value: fields.designation,
            field: 'Designation',
        }

    ]), [fields])





    const createFunction = async () => {
        const validationResponse = validate(validationSchemaCreate)
        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi({ ...fields }),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }





    const onSubmit = async (e) => {
        e.preventDefault()
        createFunction()


    }





    return <CreateUi modalKey={modalKey} title={title} fetchLoading={fetchLoading} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)