import { Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import CustomInput from "../../components/inputs/CustomInput"
import { GENDER, REGIONS_TYPE, USER_ROLES } from "../../utils/constants"
import { findObjectKeyByValue } from "../../utils/helper"
import { DatePicker, DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import CreatableRegionComponent from "../../components/custom/CreateableRegionComponent"
import FileInput from "../../components/inputs/FileInput"
import { CAMP_STATUS, STANDAERED } from "../../utils/beneficiary.constant"
import CreatableDropDownComponent from "../../components/custom/CreateableDropodown"
import CampController from "../camp/CreateController"
import { useSelector } from "react-redux"
import MODULES from "../../utils/module.constant"
import { getCampApi } from "../../apis/camp.api"
import CreateController from "../user/CreateController"
import { getTeachersApi } from "../../apis/user.api"

const PersonalDetailsFields = ({ show, fields, setFields, loading }) => {
    const { user } = useSelector(state => state)
    return <>
        <Box sx={{ width: "100%", display: show ? "block" : "none" }}>

            <Grid container columnSpacing={2} >


                <Grid item sm={6} xs={12}>
                    <CreatableDropDownComponent
                        onlyDropDown={!user.data?.modules?.includes?.(MODULES.CAMPS)}
                        createComponent={<CampController />}
                        modalKey='create-camp'
                        disabled={loading}
                        value={typeof fields.camp == "object" ? fields.camp : null}
                        label={"Select Camp*"}
                        getApi={async (params) => await getCampApi({ ...params, status: CAMP_STATUS.OPEN, allStatus: true, allDetails: true })}
                        onChange={async (changedVal) => {

                            setFields({
                                ...fields,
                                camp: (changedVal ? changedVal : null),
                                country: (changedVal ? changedVal.country : null),
                                state: (changedVal ? changedVal.state : null),
                                district: (changedVal ? changedVal.district : null),
                                taluka: (changedVal ? changedVal.taluka : null),
                            })
                        }}
                    />

                </Grid>







                <Grid item sm={6} xs={12}>
                    <CreatableDropDownComponent
                        onlyDropDown={!user.data?.modules?.includes?.(MODULES.TEACHER)}
                        createComponent={<CreateController role={USER_ROLES.TEACHER} />}
                        modalKey='users'
                        disabled={loading}
                        value={typeof fields.teacher == "object" ? fields.teacher : null}
                        label={"Select Teacher"}
                        getApi={async (params) => await getTeachersApi(params)}
                        onChange={async (changedVal) => {

                            setFields({ ...fields, teacher: (changedVal ? changedVal : null) })
                        }}
                    />
                </Grid>


                <Grid item sm={6} xs={12}>
                    <CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.name}
                        onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                        type="text"
                        label={"First Name*"}

                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <CustomInput

                        disabled={loading}
                        value={fields.surname}
                        onChange={(e) => setFields({ ...fields, err: '', surname: e.target.value })}
                        type="text"
                        label={"Last Name"}

                    />
                </Grid>

                <Grid item sm={6} xs={12}>
                    <CustomInput

                        disabled={loading}
                        value={fields.phone}
                        onChange={(e) => setFields({ ...fields, err: '', phone: e.target.value, family_contact: e.target.value })}
                        type="number"
                        label={"Phone No*"}

                    />
                </Grid>
                <Grid item sm={6} xs={8}>
                    <CustomInput

                        disabled={loading}
                        value={fields.email}
                        onChange={(e) => setFields({ ...fields, err: '', email: e.target.value })}
                        type="email"
                        label={"Email"}

                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Autocomplete
                        value={findObjectKeyByValue(fields.gender, GENDER)}
                        disableClearable
                        onChange={(val, newVal) => {
                            setFields({ ...fields, err: '', gender: newVal._id })
                        }}
                        options={Object.keys(GENDER).map((key) => ({ label: key, _id: GENDER[key] }))}


                        renderInput={(params) => <CustomInput placeholder="Select Gender*" {...params} />}
                    />

                </Grid>
                <Grid item sm={6} xs={12}>

                    <DatePicker
                    
                        views={['month','year','day']}
                        value={moment(fields.dob)}
                        inputFormat="DD/MM/YYYY"
                        disableFuture
                        onChange={(val) => {
                            setFields({ ...fields, err: '', dob: val?.toISOString() })
                        }}
                        label="Select DOB*"
                        renderInput={(params) => <CustomInput placeholder="Select DOB*"  {...params} />}
                    />


                </Grid>
                <Grid item sm={6} xs={12}>
                    <CustomInput

                        disabled={loading}
                        value={fields.school}
                        onChange={(e) => setFields({ ...fields, err: '', school: e.target.value })}
                        type="text"
                        label={"School"}

                    />
                </Grid>
                <Grid item sm={6} xs={12}>

                    <FormControl fullWidth={true} margin="dense" >
                        {/* <FormControlLabel>S</FormControlLabel> */}
                        <InputLabel >Standard/Grade</InputLabel>
                        <Select
                            fullWidth={true}
                            id="demo-simple-select-standard"
                            label="Standard/Grade"

                            value={fields.std}
                            onChange={(e) => setFields({ ...fields, err: '', std: e.target.value })}
                        >

                            {
                                STANDAERED.map((item) => {
                                    return <MenuItem key={item.value} value={item.value}>
                                        {item.name}
                                    </MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>



                <Grid item xs={12}>
                    <CustomInput
                        multiline
                        rows={2}

                        disabled={loading}
                        value={fields.addres_line}
                        onChange={(e) => setFields({ ...fields, err: '', addres_line: e.target.value })}
                        type="text"
                        label={"Address (Street/ Village Name)*"}

                    />
                </Grid>

                <Grid item sm={4} xs={12}>
                    <FormControl fullWidth={true} margin="dense" >
                        <CustomInput


                            disabled={loading}
                            value={fields.PIN}
                            onChange={(e) => setFields({ ...fields, err: '', PIN: e.target.value })}
                            type="text"
                            label={"PIN"}

                        />
                    </FormControl>
                </Grid>
                <Grid item sm={4} xs={12}>

                    <CreatableRegionComponent
                        disabled={loading || !fields.state || fields.state == null}
                        value={typeof fields.district == "object" ? fields.district : null}
                        type={REGIONS_TYPE.DISTRICT}

                        onChange={async (changedVal) => {
                            setFields({ ...fields, district: changedVal ? changedVal : null, taluka: null })
                        }}
                        label={"Select District*"}
                        parent={fields.state && typeof (fields.state) == "object" ? fields.state['_id'] : fields.state}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <CreatableRegionComponent
                        disabled={loading || !fields.district || fields.district == null}
                        value={typeof fields.taluka == "object" ? fields.taluka : null}
                        type={REGIONS_TYPE.TALUKA}


                        onChange={async (changedVal) => {

                            setFields({ ...fields, taluka: changedVal ? changedVal : null })
                        }}
                        label={"Select Taluka*"}
                        parent={fields.district && typeof (fields.district) == "object" ? fields.district['_id'] : fields.district}
                    />

                </Grid>

                <Grid item xs={12} sm={6}>
                    <CustomInput
                        disabled={loading}
                        value={fields.aadhar}
                        onChange={(e) => setFields({ ...fields, err: '', aadhar: e.target.value })}
                        type="number"
                        label={"Aadhar No."}

                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomInput
                        disabled={loading}
                        value={fields.udise_no}
                        onChange={(e) => setFields({ ...fields, err: '', udise_no: e.target.value })}
                        type="text"
                        label={"UDISE No"}
                    />
                </Grid>


                {/* <Grid item xs={12}>
                    <FileInput
                        defaults={fields.udise_file && fields.udise_file != '' ? [fields.udise_file] : undefined}
                        title="Attached Documents(if available)"
                        subTitle="Only .pdf less than 1mb are required."
                        accept=".pdf"
                        onChange={(newUrl) => {
                            setFields({ ...fields, err: '', udise_file: newUrl })
                        }}
                        onDelete={(newList) => { setFields({ ...fields, udise_file: '' }) }}
                    />
                </Grid> */}
                {/* <Grid item xs={12}>
                    <FileInput
                        defaults={fields.aadhar_file && fields.aadhar_file!='' ? [fields.aadhar_file] : undefined}
                        title="Adhdar Card(if available)"
                        subTitle="Only .pdf less than 1mb are required."
                        accept=".pdf"
                        onChange={(newUrl) => {
                            setFields({ ...fields, err: '', aadhar_file: newUrl })
                        }}
                        onDelete={(newList) => { setFields({ ...fields, aadhar_file: '' }) }}
                    />
                </Grid> */}
                {/* <Grid item xs={12}>
                    <FileInput
                        defaults={fields.udise_file ? [fields.udise_file] : undefined}
                        title="Disability Certifiacte(if available)"
                        subTitle="Only .pdf less than 1mb are required."
                        accept=".pdf"
                        onChange={(newUrl) => {
                            setFields({ ...fields,err:'', udise_file: newUrl })
                        }} 
                        onDelete={(newList)=>{setFields({ ...fields, udise_file: '' })}}
                        />
                </Grid> */}

                {/* <Grid item xs={12}>
                    <FileInput
                        onDelete={() => { setFields({ ...fields, photo: '' }) }}
                        defaults={fields.photo ? [fields.photo] : undefined}
                        title="Photo Indentity"
                        subTitle="Only png, jpg, jpeg are required."
                        onlyImage
                        onChange={(newUrl) => {
                            setFields({ ...fields, err: '', photo: newUrl })
                        }} />
                </Grid> */}


                <Grid item sm={6} xs={12}>
                    <CustomInput

                        disabled={loading}
                        value={fields.father_name}
                        onChange={(e) => setFields({ ...fields, err: '', father_name: e.target.value })}
                        type="text"
                        label={"Father Name"}

                    />
                </Grid>

                <Grid item sm={6} xs={12}>
                    <CustomInput

                        disabled={loading}
                        value={fields.father_occupation}
                        onChange={(e) => setFields({ ...fields, err: '', father_occupation: e.target.value })}
                        type="text"
                        label={"Father Occupation"}

                    />
                </Grid>



                <Grid item sm={6} xs={12}>
                    <CustomInput

                        disabled={loading}
                        value={fields.mother_name}
                        onChange={(e) => setFields({ ...fields, err: '', mother_name: e.target.value })}
                        type="text"
                        label={"Mother Name"}

                    />
                </Grid>

                <Grid item sm={6} xs={12}>
                    <CustomInput

                        disabled={loading}
                        value={fields.mother_occupation}
                        onChange={(e) => setFields({ ...fields, err: '', mother_occupation: e.target.value })}
                        type="text"
                        label={"Mother Occupation"}

                    />
                </Grid>


                <Grid item sm={6} xs={12}>
                    <CustomInput

                        disabled={loading}
                        value={fields.family_income}
                        onChange={(e) => setFields({ ...fields, err: '', family_income: e.target.value })}
                        type="number"
                        label={"Family Income (yearly)"}

                    />
                </Grid>

                <Grid item sm={6} xs={12}>
                    <CustomInput

                        disabled={loading}
                        value={fields.family_contact}
                        onChange={(e) => setFields({ ...fields, err: '', family_contact: e.target.value })}
                        type="tel"
                        label={"Guardian contact*"}

                    />
                </Grid>


            </Grid>
        </Box>
    </>
}
export default PersonalDetailsFields