export const BENIFICIARY_STATUS = {
    CREATED: 0,
    TESTED: 1,
    VALIDATED: 2,
    ASSIGNED_FOR_DONATION: 3,
    DONATED: 4,

    FOLLOWUP_ASSIGNED: 5,

}

export const BENIFICIARY_LOG_STATUS = {
    MODIFIED: -1,
    CREATED: 0,
    TESTED: 1,
    VALIDATED: 2,
    DONATION_ASSIGNED: 3,
    DONATED: 4,
    FOLLOWUP_ASSIGNED: 5,
    FOLLOWUP_TAKEN: 6,
    FOLLOWUP_RESCHEDULED: 7

}
export const FOLLOW_UP_STATUS = {
    PENDING: 1,
    FOLLOWED_UP: 2,
}
export const FOLLOW_UP_LOG_TYPE = {
    ASSIGNED: 1,
    CALL_NO_ANSWERED: 2,
    CALL_RESCHEDULED: 3,
    CALL_ANSWERED: 4
}
export const SPEECH_STATE = {
    S1: 1,
    S2: 2,
    S3: 3,
    S4: 4,

}
export const FOLLOWUP_TYPE = {
    GENERAL: null,
    INCLUSIVE: 1
}

export const INCLUSION_STATE = {
    I1: 1,
    I2: 2,
    I3: 3

}
export const INCLUSION_STATE_NAME = {
    I1: "(I1) Domestic Activity",
    I2: "(I2) Taking Initiative",
    I3: "(I3) Indipendent",

}
export const HEARING_STATE = {
    H1: 1,
    H2: 2,
    H3: 3,
    H4: 4,

}
export const TYPE_OF_HEARING_LOSS = {
    CONDUCTIVE: 1,
    SN: 2,
    MIXED: 3
}
export const TYPE_OF_HEARING_LOSS_LIST = [{
    _id: 1,
    label: "Conductive"

},
{
    _id: 2,
    label: "SN"

}, {
    _id: 3,
    label: "Mixed"
}]
export const SEVEARITY_OF_LOSS = {
    MILD: 1,
    MODERATE: 2,
    SEVERE: 3,
    PROFOUND: 4
}
export const WAY_OF_CONFIRMATION = {
    HAND: 1,
    EYE_CONTACT: 2,
    VERBAL_CONFIRMATION: 3,
    OTHER: 4
}

export const EVENT_STATUS = {
    OPEN: 1,
    CLOSED: 2
}
export const CAMP_STATUS = {
    OPEN: 1,
    CLOSED: 2
}
export const STANDAERED = [
    {
        name: "None",
        value: null
    },
    {
        name: "Sr. kg",
        value: -2
    },
    {
        name: "Jr. kg",
        value: -1
    },
    {
        name: "UG",
        value: 13
    },
    {
        name: "PG",
        value: 14
    },
    ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => ({ name: item, value: item }))
]