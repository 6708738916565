import { useDispatch, useSelector } from "react-redux"
import MODULES from "../../../utils/module.constant"
import { openModal } from "../../../store/actions/modalAction"
import ListController from "./ListController"

const { Button } = require("@mui/material")

const SheetDonwnloadButton = () => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal(<ListController />,"md",false,'beneficiary-demo-sheet'))
    }
    if (user.data.modules.includes(MODULES.BENIFICIARY_ADD))
        return <Button  mr={1} variant="contained" onClick={onClick} >
            Download Blank Sheet
        </Button>

    return <></>

}
export default SheetDonwnloadButton