import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"
import { addBeneficiaryToEventApi, getEventByIdApi } from "../../apis/event.api"
import AddBeneficiaryToEventUi from "./AddBeneficiaryToEventUi"
import { closeModal } from "../../store/actions/modalAction"
import { getNewUniqueElements, getUniqueElements } from "../../utils/helper"

const AddBeneficiaryToEventController = ({ id, callBack }) => {
    const dispatch = useDispatch()
    const [data, setData] = useState({})
    const modalKey = 'event-beneficiary-attach'
    const [loading, setLoading] = useState(false)


    const [processingLoading, setProcessingLoading] = useState(false)

    const [oldBenificarioes, setOldBeneficiaries] = useState([])


    const [newBenficiaries, setNewBeneficiaries] = useState([])

    const fetchById = () => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getEventByIdApi({ id, onlyBeneficiary: true }),
                async (response) => {
                    const { benificiaris, ...otherDetails } = response
                    if (benificiaris && benificiaris.length > 0) {
                        const newBeneficiary = benificiaris.map((item) => ({
                            name: item.beneficiary_id.name,
                            surname: item.beneficiary_id.surname,
                            _id: item.beneficiary_id._id,
                        }))
                        setOldBeneficiaries(newBeneficiary)
                    } else {
                        setOldBeneficiaries([])
                    }


                    setData({ ...otherDetails })
                    setLoading(false)
                },
                (err) => {
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    setLoading(false)
                }
            )
        )
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const formData = new FormData()
        formData.append('beneficiaries', JSON.stringify(newBenficiaries.map((item) => item._id)))
        formData.append('id', id)
        dispatch(
            callApiAction(
                async () => await addBeneficiaryToEventApi(formData),
                async (response) => {

                    await callBack(newBenficiaries.length)
                    setLoading(false)
                    dispatch(closeModal(modalKey))
                    dispatch(callSnackBar('Beneficiaries added to Event ' + data.name, SNACK_BAR_VARIETNS.suceess))
                },
                (err) => {
                    setLoading(false)
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))

                }
            )
        )
    }


    const onAddBeneficiary = (beneficiaries) => {
        setProcessingLoading(true)
        const old = [...oldBenificarioes]
        const alreadyAssigned = [...newBenficiaries]
        const alreadyExistArr = old.concat(alreadyAssigned)
        const newBeneficiaryArr = getUniqueElements(beneficiaries)
        const latestArr = getNewUniqueElements(alreadyExistArr, newBeneficiaryArr)
        setNewBeneficiaries([...alreadyAssigned, ...latestArr])

        setProcessingLoading(false)
    }
    const onDeleteBeneficiary = useCallback((id) => {
        const arr = [...newBenficiaries]
        const index = arr.findIndex(obj => obj._id === id);
        if (index !== -1) {
            arr.splice(index, 1);
        }
        setNewBeneficiaries(arr)
    }, [newBenficiaries])
    useEffect(() => {
        fetchById()
    }, [id])

    return <AddBeneficiaryToEventUi
        processingLoading={processingLoading}
        onDeleteBeneficiary={onDeleteBeneficiary}
        id={id}
        onSubmit={onSubmit}
        loading={loading}
        data={data}
        setData={setData}
        modalKey={modalKey}
        oldBenificarioes={oldBenificarioes}
        newBenficiaries={newBenficiaries}
        onAddBeneficiary={onAddBeneficiary}
    />
}
export default AddBeneficiaryToEventController