import { Box, Chip, Grid, Typography, styled } from "@mui/material"
import ImageComponent from "../../../components/inputs/ImageComponent"
import moment from "moment"
import { findObjectKeyByValue } from "../../../utils/helper"
import { GENDER } from "../../../utils/constants"
import FileDownloadComponent from "../../../components/FileDownloadComponent"
import { BENIFICIARY_STATUS, HEARING_STATE, INCLUSION_STATE, SEVEARITY_OF_LOSS, SPEECH_STATE, TYPE_OF_HEARING_LOSS, WAY_OF_CONFIRMATION } from "../../../utils/beneficiary.constant"
import { Cancel, Check } from "@mui/icons-material"

const Header = styled(Box)(({ theme }) => ({
    border: '1px solid ' + theme.palette.primary.main,
    padding: theme.spacing(2),
    background: theme.palette.secondary.main,
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderBottom: "0px",
    borderTopRightRadius: theme.shape.borderRadius * 2,
    width: "100%"
}))
const Body = styled(Box)(({ theme }) => ({
    border: '1px solid ' + theme.palette.primary.main,
    padding: theme.spacing(2),

    marginBottom: theme.spacing(2),

    borderBottomLeftRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
    width: "100%"
}))
const HearingDetails = ({ data }) => {
    return <>




        <Header>
            <Typography fontWeight="bold" variant="body1">
                Current Progress
            </Typography>
        </Header>
        <Body>
            <Grid container spacing={2}>
                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Hearing:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2">{data.hearing_state ? findObjectKeyByValue(data.hearing_state, HEARING_STATE) : "NA"}</Typography>
                </Grid>



                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Speech:</Typography>
                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2">{data.speech_state ? findObjectKeyByValue(data.speech_state, SPEECH_STATE) : "NA"}</Typography>
                </Grid>


                {data.inclusion_state && <>

                    <Grid item md={3} xs={6} >
                        <Typography fontWeight="bold" variant="body2">Inclusion Level:</Typography>
                    </Grid>
                    <Grid item md={3} xs={6} >
                        <Typography variant="body2">{data.inclusion_state ? findObjectKeyByValue(data.inclusion_state, INCLUSION_STATE) : "NA"}</Typography>
                    </Grid>

                </>}

            </Grid>
        </Body>



        <Header>
            <Typography fontWeight="bold" variant="body1">
                Test Details
            </Typography>
        </Header>
        <Body>
            <Grid container spacing={2}>
            <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Product Tested</Typography>

                </Grid>
                <Grid item md={9} xs={6} >
                    <Typography variant="body2">{data?.product?.name ?? "NA"}</Typography>
                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Type Of Hearing Loss:</Typography>

                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2">{data.type_of_hearing_loss ? findObjectKeyByValue(data.type_of_hearing_loss, TYPE_OF_HEARING_LOSS) : "NA"}</Typography>
                </Grid>



                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Severity Of Hearing Loss:</Typography>
                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2">{data.severity_of_hearing_loss ? findObjectKeyByValue(data.severity_of_hearing_loss, SEVEARITY_OF_LOSS) : "NA"}</Typography>
                </Grid>


                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Audiogram:</Typography>
                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2">{data.audio_gram_file && data.audio_gram_file != '' ? <FileDownloadComponent src={data.audio_gram_file} /> : "NA"}</Typography>
                </Grid>






                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">HearNU Device Response:</Typography>
                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="h6">{data.nu_device_responce ? <Check color="success" /> : <Cancel color="error" />}</Typography>
                </Grid>


                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Music Response:</Typography>
                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="h6">{data.music_responce ? <Check color="success" /> : <Cancel color="error" />}</Typography>
                </Grid>




                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Voice Response:</Typography>
                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="h6">{data.voice_responce ? <Check color="success" /> : <Cancel color="error" />}</Typography>
                </Grid>


                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Has Smartphone?:</Typography>
                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="h6">{data.has_smart_phone ? <Check color="success" /> : <Cancel color="error" />}</Typography>
                </Grid>



                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Left Ear Response (DB):</Typography>
                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2">{data.left_response_in_db ?? "NA"}</Typography>
                </Grid>

                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Right Ear Response (DB):</Typography>
                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2">{data.right_response_in_db ?? "NA"}</Typography>
                </Grid>


                <Grid item md={3} xs={6} >
                    <Typography fontWeight="bold" variant="body2">Way of confirmation:</Typography>
                </Grid>
                <Grid item md={3} xs={6} >
                    <Typography variant="body2">{data.way_of_confirmation ? findObjectKeyByValue(data.way_of_confirmation, WAY_OF_CONFIRMATION) : "NA"}</Typography>
                </Grid>







            </Grid>
        </Body>

    </>
}
export default HearingDetails