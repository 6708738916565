import { Description, Menu } from "@mui/icons-material";
import { Avatar, Badge, Box, ButtonBase, Collapse, Fab, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, Tooltip, Typography } from "@mui/material"



import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom";
import Profile from "./Profile";
import NotificationModel from "../../../pages/notification/NotificationModel";
import { findObjectKeyByValue } from "../../../utils/helper";
import { USER_ROLES } from "../../../utils/constants";
import moment from "moment";
import { CenteredBox } from "../OneViewBox";

const headerStyle = (theme) => ({
    width: "100%",
    background: "white", position: "sticky", top: "0px", display: "flex", alignItems: "center",
    pt: 3, pb: 3, pr: 3,
    borderBottom: "1px solid " + theme.palette.grey.main,
    zIndex: 111,
    borderRadius: "0px"
})

const Header = ({ open, setOpen }) => {
    const user = useSelector(state => state.user)
    return (
        <Paper elevation={0} sx={headerStyle}>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>


                <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>

                    <IconButton onClick={() => { setOpen(!open) }}>
                        <Menu />
                    </IconButton>
                    <CenteredBox ml={2} sx={{ flexDirection: "column" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }} >
                            <Typography variant="h6" lineHeight="100%" fontWeight="bold">{user.data.name}</Typography>
                            <Typography variant="h6" lineHeight="100%" ml={2} >({findObjectKeyByValue(user.data.role, USER_ROLES)})</Typography>
                        </Box>
                        <Typography variant="caption" lineHeight="100%" mt={2} >{moment().format("DD/MM/YYYY HH:mm")}</Typography>
                    </CenteredBox>
                </Box>
                {user.data.document && <CenteredBox sx={{ flex: "0" }}>
                    <Tooltip title="Documentation" >
                        <IconButton href={user.data.document} target={"_blank"}>
                            <Description />
                        </IconButton>
                    </Tooltip>
                </CenteredBox>}

                {/* <NotificationModel /> */}
                <Profile />
            </Box>
        </Paper>
    )
}
export default Header