import {  CircularProgress } from "@mui/material"
import {  memo } from "react"

import CustomInput from "../../../components/inputs/CustomInput"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import moment from "moment"







const CloseCampUi = ({ title, modalKey, fetchLoading, isUpdate, fields, setFields, loading, onSubmit }) => {

    return <>

        <CustomDialog
            id={modalKey}
            loading={loading || fetchLoading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${title}`}
            closeText="Back"
            confirmText={`Close Camp`}
        >

            {(loading || fetchLoading) && <CenteredBox><CircularProgress /></CenteredBox>}
            {!fetchLoading &&
                <>
                 {
                    console.log(fields.start_date)
                 }
                  <DesktopDatePicker
                        value={moment(fields.end_date)}
                        
                        minDate={moment(fields.start_date)}
                        inputFormat="DD/MM/YYYY"
                        
                        onChange={(val) => {
                            setFields({ ...fields, err: '', end_date: val.toISOString() })
                        }}
                        label="Select Camp Closure Date*"
                        renderInput={(params) => <CustomInput placeholder="Select Camp Closure Date*"  {...params} />}
                    />


                </>}

        </CustomDialog>


    </>
}
export default memo(CloseCampUi)