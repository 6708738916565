import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { deleteUserApi, getUserApi, updateUserField } from "../../apis/user.api"
import { useParams } from "react-router-dom"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { findNameByRole, findObjectKeyByValue, titleCase } from "../../utils/helper"
import { Box, Chip, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./CreateController"
import { Add, ChangeCircle, Delete, Draw, Edit, LockClock, LockOpen } from "@mui/icons-material"
import MessageDilog from "../../components/MessageDilog"
import ResetPasswordController from "./ResetPassword.controller"
import { USER_ROLES } from "../../utils/constants"
import ModulePermissionController from "./ModulePermissionController"
import MODULES from "../../utils/module.constant"
import UpdateSignatureController from "./signature/UpdateSignatureController"

const ActionComponent = memo(({ params, setParams, deleteApi }) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const { user } = useSelector(state => state)

    const onEdit = () => {
        dispatch(openModal(<CreateController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm", false, "users"))
    }

    const onResetPassword = () => {
        dispatch(openModal(<ResetPasswordController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm", false, "reset-password"))
    }

    const deleteFun = async () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteApi({ id: params._id }),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())


    }
    const onDelete = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" message={`Are you sure to delete "${params.name || params.title}" ?`} />, "sm"))
    }

    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <IconButton disabled={loading} size="inherit" onClick={onEdit}>
            <Edit color="info" fontSize="inherit" />
        </IconButton>
        <IconButton disabled={loading} size="inherit" onClick={onResetPassword}>
            <LockOpen color="info" fontSize="inherit" />
        </IconButton>
        {user.data.role == USER_ROLES.admin && <>

            {loading && <CircularProgress color="primary" fontSize="inherit" />}
            {!loading && <IconButton disabled={loading} size="inherit" onClick={onDelete}>
                <Delete color="error" fontSize="inherit" />
            </IconButton>}
        </>}
    </Box>
})
const ModulesComponent = memo(({ params, setParams }) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const { user } = useSelector(state => state)

    const onEdit = () => {
        dispatch(openModal(<ModulePermissionController id={params._id} {...params} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm", false, "modules"))
    }

    return <>
        <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
            {(params.modules && params.modules.map((item) => (<Chip key={item} label={findObjectKeyByValue(item, MODULES)} />)))}
            <IconButton disabled={loading} size="inherit" onClick={onEdit}>
                <ChangeCircle color="info" fontSize="inherit" />
            </IconButton>
        </Box>



    </>
})

const SignatureUpdateComponent = ({params, setParams}) => {

    const dispatch = useDispatch()
    
    const onClick = () => {
        dispatch(openModal(<UpdateSignatureController callBack={(signature)=>setParams({...params,signature})} id={params._id} oldSignature={params.signature} />, "sm", undefined, 'users-signature'))
    }
    return params.signature ? <Tooltip title="Update Signature" >
        <IconButton onClick={onClick} >
            <Draw />
        </IconButton>
    </Tooltip> : <Tooltip title="Add Signature" >
        <IconButton onClick={onClick} >
            <Add />
        </IconButton>
    </Tooltip>
}
const ListController = () => {
    const dispatch = useDispatch()



    const title = "Users"
    const fetchApi = getUserApi
    const deleteApi = deleteUserApi

    const columns = useMemo(() => [

        { id: 1, minWidth: "50px", fieldName: 'name', label: 'Name', align: "left", sort: true },
        { id: 2, minWidth: "50px", fieldName: 'email', label: 'Email', align: "left", sort: true, renderValue: (params) => <Typography textTransform="none" >{params.email?.toLowerCase()}</Typography> },
        {
            id: 4,
            fieldName: 'role',
            label: 'Role',

            sort: true,
            align: "left",
            renderValue: (params) => findNameByRole(params.role) || '',
        },

        {
            id: 5,
            fieldName: 'modules',
            label: 'Modules',
            minWidth: "500px",
            sort: true,
            align: "left",
            renderValue: (params, setParams) => {
                return <ModulesComponent params={params} setParams={setParams} />
            },
        },

        {
            id: 5,
            fieldName: '',
            label: 'Signature',
            align: "right",
            renderValue: (params, setParams) => <SignatureUpdateComponent params={params} setParams={setParams} />,
        },
        {
            id: 5,
            fieldName: '',
            label: 'Action',

            align: "right",
            renderValue: (params, setParams) => <ActionComponent deleteApi={deleteApi} params={params} setParams={setParams} />,
        },

    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        role: '',
        sort: '',
        sortDirection: 1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController callBack={async () => { fetchList() }} />, "sm", false, "users"))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                title={title}
                onCreateBtnClick={onCreateBtnClick}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController