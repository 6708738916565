import { useState } from "react";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/actions/modalAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { callApiAction } from "../../../store/actions/commonAction";
import {
  updateBeneficiaryVideoRatingAndTagsApi,
} from "../../../apis/beneficiary.api";
import CustomInput from "../../../components/inputs/CustomInput";
import {
  SNACK_BAR_VARIETNS,
  VIDEOS_RATING,
  VIDEOS_STATUS,
  VIDEOS_TAGS,
} from "../../../utils/constants";
import { Autocomplete, Box, Typography } from "@mui/material";
import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop";
import { StyledSearchBar } from "../../../components/inputs/SearchBar";
import { titleCase } from "../../../utils/helper";

const UpdateVideoRatingModal = ({
  rating = 1,
  video_id,
  tags,
  followup_id,
  date,
  other_tags,
  callBack = () => {},
}) => {
  const modalId = "update-video-rating";
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    rating,
    tags,
    video_id,
    followup_id,
    date,
    other_tags,
    err: "",
  });

  const onClose = () => {
    dispatch(closeModal(modalId));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await updateBeneficiaryVideoRatingAndTagsApi({
            ...fields,
            video_status: VIDEOS_STATUS.MANAGER_APPROVE,
            date:new Date().toISOString(),
          }),
        (response) => {
          dispatch(
            callSnackBar(
              "Video Rating Updated successfully.",
              SNACK_BAR_VARIETNS.suceess
            )
          );
          setLoading(false);
          onClose();
          callBack(fields);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  return (
    <CustomDialog
      id={modalId}
      loading={loading}
      err={fields.err}
      onSubmit={onSubmit}
      title={`${"Update Video Rating"}`}
      onClose={onClose}
      closeText={"Close"}
      confirmText={"Update"}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography mx={2}>Video Rating</Typography>
          <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} px={2}>
            <Autocomplete
              disableClearable
              onChange={(e, changedVal) => {
                setFields({
                  ...fields,
                  rating: changedVal ? changedVal._id : null,
                });
              }}
              options={[
                { label: "All", _id: null },
                ...Object.keys(VIDEOS_RATING).map((key) => ({
                  label: titleCase(key),
                  _id: VIDEOS_RATING[key],
                })),
              ]}
              sx={{
                width: "100%",
                display: "flex",
                "*": { display: "flex", justifyContent: "center" },
              }}
              renderInput={(params) => (
                <StyledSearchBar placeholder="Select Rating" {...params} />
              )}
            />
          </PaddingBoxInDesktop>
          {/* <Rating
            
            name="ratings-controlled"
            value={fields.rating}
            onChange={(event, newValue) => {
              setFields({ ...fields, rating: newValue });
            }}
          /> */}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography mx={2}>Video Tag</Typography>
          <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} px={2}>
            <Autocomplete
              disableClearable
              onChange={(e, changedVal) => {
                setFields({
                  ...fields,
                  tags: changedVal ? changedVal._id : null,
                });
              }}
              options={[
                { label: "All", _id: null },
                ...Object.keys(VIDEOS_TAGS).map((key) => ({
                  label: titleCase(key),
                  _id: VIDEOS_TAGS[key],
                })),
              ]}
              sx={{
                width: "100%",
                display: "flex",
                "*": { display: "flex", justifyContent: "center" },
              }}
              renderInput={(params) => (
                <StyledSearchBar placeholder="Select Tags" {...params} />
              )}
            />
          </PaddingBoxInDesktop>
          <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} px={2}>
            {fields.tags === VIDEOS_TAGS.OTHER && (
              <CustomInput
                disabled={loading}
                value={fields.other_tags}
                onChange={(e) =>
                  setFields({ ...fields, err: "", other_tags: e.target.value })
                }
                type="text"
                label={"Other Tags"}
              />
            )}
          </PaddingBoxInDesktop>
        </Box>
      
      </Box>
    </CustomDialog>
  );
};
export default UpdateVideoRatingModal;
