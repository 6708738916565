import { useDispatch, useSelector } from "react-redux"
import CreateController from "../CreateController"
import { closeModal, openModal } from "../../../store/actions/modalAction"
import { Button, CircularProgress, IconButton, MenuItem, Tab, Typography } from "@mui/material"
import { Edit } from "@mui/icons-material"
import { BENIFICIARY_STATUS } from "../../../utils/beneficiary.constant"
import { callApiAction } from "../../../store/actions/commonAction"
import { validateBeneficiaryApi } from "../../../apis/beneficiary.api"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants"
import MessageDilog from "../../../components/MessageDilog"
import { forwardRef, useState } from "react"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import MODULES from "../../../utils/module.constant"

const BeneficiaryValidateButton = ({ id, status, params, setParams, type,setTab, ...props }) => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state)
    const allowedModules = user.data.modules??[]

    const [loading, setLoading] = useState(false)

    const validateFun = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await validateBeneficiaryApi({ id }),
            (response) => {
                setParams({ ...params, status: BENIFICIARY_STATUS.VALIDATED })
                setTab(0)
                setLoading(false)

            },
            (err) => {
                setLoading(false)
                dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
            }
        ))
        dispatch(closeModal())
    }
    const onValidate = () => {
        dispatch(openModal(<MessageDilog
            title="Are you sure to validate?"
            message={"By clicking on this, you agree that you have verified all benficiary details."}
            confirmText="Validate"
            onSubmit={(e) => {
                e.preventDefault()
                validateFun()
            }}
        />, "xs", false))
    }




    if (status != BENIFICIARY_STATUS.TESTED || !allowedModules.includes(MODULES.BENIFICIARY_VALIDATE) )
        return <>
        </>
    if (type == 'tab')
        return <Tab label="Validate" value={4} {...props} />
    if (loading)
        return <CenteredBox>
            <CircularProgress />
        </CenteredBox>
    return <>
        <Typography variant="body2" mb={3}>
            By clicking on this, you agree that you have verified all benficiary details.
        </Typography>

        <Button onClick={onValidate} variant="contained" >Validate</Button>
    </>
}
export default BeneficiaryValidateButton