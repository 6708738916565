import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { Avatar, Badge, Box, ButtonBase, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, MenuItem, MenuList } from '@mui/material';
import { AccountCircle, Email, Label, People, PictureAsPdf } from '@mui/icons-material';
// import { center } from '../../assets/css/theme/common';
import { useDispatch, useSelector } from 'react-redux';
import { center } from '../../../assets/css/theme/common';
import { findNameByRole } from '../../../utils/helper';
import SubmitButton from '../../button/SubmitButton';
import { signOutAction } from '../../../store/actions/userReducerAction';
import { openModal } from '../../../store/actions/modalAction';
import ResetTokenController from '../../../pages/reset-token/ResetTokenController';
import UserProfileViewCommonController from '../../../pages/user/UserProfileViewCommonController';
import { USER_ROLES } from '../../../utils/constants';
import { getDonorByIdApi, getNgoByIdApi } from '../../../apis/user.api';

export default function Profile() {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onResetButtonClick = () => {
        dispatch(openModal(<ResetTokenController  />,"xs",false,'reset-token'))
    }

    const onViewProfileClick = () => {
        dispatch(openModal(<UserProfileViewCommonController id={user.data?.detailedInfo?._id} role={user.data.role} getFun={user.data.role==USER_ROLES.NGO ?getNgoByIdApi:getDonorByIdApi} />,"md",false,'users-modal'))
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <>


            <Badge color="success" overlap="circular" anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
                badgeContent=" " variant='dot' >
                <ButtonBase p={0} aria-describedby={id} onClick={handleClick}>
                    <Avatar sx={(theme) => ({ bgcolor: theme.palette.primary.main })}   >
                        {user.data.name[0]}
                    </Avatar>
                </ButtonBase>
            </Badge>
            <Popover
                // sx={(theme) => ({ marginTop: theme.spacing(3) })}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}

            >
                <Box sx={{ width: "300px", maxWidth: "100%" }}>
                    <MenuList sx={{width:"100%"}}>
                        <ListItem disableRipple disableTouchRipple sx={{py:0,alignItems:"flex-start"}} p={0} >
                            <ListItemIcon sx={{mr:2,minWidth:"unset",mt:1,mb:1}} >
                                <AccountCircle color='dark.main' fontSize="small" />
                            </ListItemIcon>
                            <ListItemText sx={{ textTransform: "capitalize" }} >{user.data.name}</ListItemText>

                        </ListItem>
                        <ListItem disableRipple disableTouchRipple sx={{py:0,alignItems:"flex-start"}} p={0} >
                            <ListItemIcon sx={{mr:2,minWidth:"unset",mt:1,mb:1}} >
                                <Email fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{user.data.email}</ListItemText>

                        </ListItem>

                        <ListItem disableRipple disableTouchRipple sx={{py:0,alignItems:"flex-start"}} p={0} >
                            <ListItemIcon sx={{mr:2,minWidth:"unset",mt:1,mb:1}} >
                                <Label fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{findNameByRole(user.data.role)}</ListItemText>

                        </ListItem>

                        <Divider />
                        {user.data?.detailedInfo?._id && <MenuItem>
                            <SubmitButton variant="outlined" title={"View Profile"} onClick={onViewProfileClick}>

                            </SubmitButton>

                        </MenuItem>}
                        <MenuItem>
                            <SubmitButton variant="outlined" title={"Reset Password"} onClick={onResetButtonClick}>

                            </SubmitButton>

                        </MenuItem>
                        <MenuItem>
                            <SubmitButton title={"log Out"} onClick={() => { dispatch(signOutAction()) }}>

                            </SubmitButton>

                        </MenuItem>

                    </MenuList>
                </Box>
            </Popover>
        </>
    );
}