import { useDispatch, useSelector } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import {  MenuItem } from "@mui/material"

import { BENIFICIARY_STATUS } from "../../../utils/beneficiary.constant"
import UpdateHearingTestDetailsController from "../UpdateHearingTestDetailsController"
import MODULES from "../../../utils/module.constant"

const BeneficiaryTestButton = ({ id, status, params, setParams }) => {
    const dispatch = useDispatch()

    const { user } = useSelector(state => state)
    const allowedModules = user.data.modules??[]


    const onUpdateClick = () => {
        dispatch(openModal(<UpdateHearingTestDetailsController
            id={id}
            callBack={() => {
                setParams({ ...params, status: BENIFICIARY_STATUS.TESTED })
            }}
        />, "sm", false, "update-beneficiary"))

    }


    if (status >= BENIFICIARY_STATUS.TESTED || !allowedModules.includes(MODULES.BENIFICIARY_TEST))
        return <></>


    return <MenuItem onClick={onUpdateClick}>Update Test Details</MenuItem>

}
export default BeneficiaryTestButton