import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"
import {  updateBeneficiaryApi } from "../../apis/beneficiary.api"
import UpdateHearingTestDetailsUi from "./UpdateHearingTestDetailsUi"
import { BENIFICIARY_LOG_STATUS, BENIFICIARY_STATUS, SEVEARITY_OF_LOSS, TYPE_OF_HEARING_LOSS, WAY_OF_CONFIRMATION } from "../../utils/beneficiary.constant"


const UpdateHearingTestDetailsController = ({ callBack = () => { }, id ,name}) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()
    const modalKey = 'update-beneficiary'
    const title = "Beneficiary " +(name??"")

    const updateApi = updateBeneficiaryApi



    const [loading, setLoading] = useState(false)
    const defaultFields = {
        err: '',
        id,
        status: BENIFICIARY_STATUS.TESTED,
        status_updated: BENIFICIARY_LOG_STATUS.TESTED,
        type_of_hearing_loss: TYPE_OF_HEARING_LOSS.CONDUCTIVE,
        severity_of_hearing_loss: SEVEARITY_OF_LOSS.MILD,
        audio_gram_file: null,
        nu_device_responce: true,
        music_responce: true,
        voice_responce: true,
        has_smart_phone: true,
        left_response_in_db: 0,
        right_response_in_db: 0,
        way_of_confirmation: WAY_OF_CONFIRMATION.VERBAL_CONFIRMATION
    }
    const [fields, setFields] = useState(defaultFields)


    const validationSchemaForUpdate = useMemo(() => ([
        {
            
            value: fields.left_response_in_db,
            field: 'Left ear response ',
            custom: () => {
                if (isNaN(fields.left_response_in_db) || fields.left_response_in_db < 0 || fields.left_response_in_db > 120) {
                    return "Left ear response should be between 0 to 120 dB"
                }
                return true
            }
        },
        {
            
            value: fields.right_response_in_db,
            field: 'Right ear response ',
            custom: () => {
                if (isNaN(fields.right_response_in_db) || fields.right_response_in_db < 0 || fields.right_response_in_db > 120) {
                    return "Right ear response should be between 0 to 120 dB"
                }
                return true
            }
        }

    ]), [fields])






    const updateFunction = async () => {
        const validationResponse = validate(validationSchemaForUpdate)
        let updatedData = { ...fields, id }




        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(updatedData),
                    async (response) => {

                        await callBack(response, updatedData)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })

        }
    }



    const onSubmit = async (e) => {

        e.preventDefault()
        if (id)
            updateFunction()


    }






    return <UpdateHearingTestDetailsUi modalKey={modalKey} title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(UpdateHearingTestDetailsController)