import { useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import fileDownload from "js-file-download"

import { getBeneficiaryDemoSheetApi } from "../../../apis/beneficiary.api"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants"


const ListController = () => {

    const dispatch = useDispatch()

    const title = "Beneficiary Demo Sheet "
    const modalKey = 'beneficiary-demo-sheet'
    const fetchApi = getBeneficiaryDemoSheetApi

    const [filters, setFilters] = useState({
        product: null,
        camp: null,
        country: null,
        state: null,
        district: [],
        taluka: []
    })


    const [exportLoading, setexportLoading] = useState(false)


    const onExportBtnClick = (e) => {
e.preventDefault()

        if (filters.camp && filters.country && filters.state && filters.district && filters.taluka && filters.district.length > 0 && filters.taluka.length > 0) {

            const passData = {
                camp: filters.camp?._id,
                country: filters.country?._id,
                state: filters.state?._id,
                district: filters.district?.map(item => item?._id),
                taluka: filters.taluka?.map(item => item?._id)
            }

            setexportLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...passData }),
                (response) => {

                    fileDownload(response,(filters.camp?.name??"beneficiary-demo") +".csv")
                    setexportLoading(false)

                },
                (err) => {
                    console.log(err)
                    setexportLoading(false)
                },
                true
            ))
        } else {
            dispatch(callSnackBar("All Fields Are Required.",SNACK_BAR_VARIETNS.error))
        }
    }






    return (
        <>
            <ListUi

                modalKey={modalKey}
                title={title}

                exportLoading={exportLoading}
                onExportBtnClick={onExportBtnClick}
                filters={filters}
                setFilters={setFilters}


            />

        </>
    )
}
export default ListController