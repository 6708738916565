// import axios from "axios";
// import { getFileHeaders, getHeaders } from "../utils/helper";
// import endpoints from "./endpoints";


import axios from "axios";
import { getHeaders, getFileHeaders, unEscapeStr } from "../utils/helper";
import endpoints from "./endpoints";
import { setup } from "axios-cache-adapter";
import localforage from "localforage";
import localforageMemoryStorageDriver from "localforage-memoryStorageDriver";


const configureAxios = async () => {

    await localforage.defineDriver(localforageMemoryStorageDriver);

    const forageStore = localforage.createInstance({
        driver: [
            localforage.INDEXEDDB,
            localforage.LOCALSTORAGE,
            localforageMemoryStorageDriver._driver
        ],
        name: 'sims-cache'
    });

    return setup({

        // Options passed to `axios.create()` method
        headers: getHeaders(),

        // `axios-cache-adapter` options
        cache: {
            clearOnStale: true,
            clearOnError: true,
            readHeaders: true,
            maxAge: 1800,
           
            store: forageStore,
        }
    });
};

export const getFile = async (url) => {
    const api = await configureAxios()
    const callResponse = await api({
        url: endpoints.fileBase,
        method: "get",
        headers: getHeaders(),
        params: { fileUrl: url },
        responseType: "blob"
    })
        .then(response => response.data)
        .catch(err => { return err.response.data });

        console.log("res", callResponse)
    return callResponse;
};

export const getFileDirect = async (src, withHeaders) => {
    const obj = {
        url: src,
        method: "get",
        responseType: "blob"
    }
    if (withHeaders) {
        obj['headers'] = getHeaders()
    }
    const callResponse = await axios(obj)
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const deleteFile = async (url) => {
    const callResponse = await axios({
        url: endpoints.fileBase,
        method: "delete",
        headers: getHeaders(),
        data: { fileUrl: url },
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const uploadFile = async (data, onUploadProgress) => {
    const callResponse = await axios({
        url: endpoints.fileFile,
        method: "POST",
        headers: getFileHeaders(),
        onUploadProgress,
        data
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const uploadImage = async (data, onUploadProgress) => {
    console.log("DATA",data +" "+onUploadProgress);
    const callResponse = await axios({
        url: endpoints.fileImage,
        method: "POST",
        headers: getFileHeaders(),
        onUploadProgress,
        data
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

