import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";




export const getCampApi = async params => {
  const callResponse = await axios({
    url: endpoints.campBaseUrl,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getCampByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.campByIdUrl,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const downloadCampReport = async params => {
  const callResponse = await axios({
    url: endpoints.campReportBaseUrl,
    method: "get",
    headers: getHeaders(),
    responseType:"blob",
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addCampApi = async data => {
  const callResponse = await axios({
    url: endpoints.campBaseUrl,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateCampApi = async data => {
  const callResponse = await axios({
    url: endpoints.campBaseUrl,
    method: "PUT",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const deleteCampField = async data => {
  const callResponse = await axios({
    url: endpoints.campBaseUrl,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};



export const createCampStaffApi = async data => {
  const callResponse = await axios({
    url: endpoints.campStaffBaseUrl,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const deleteCampStaffField = async data => {
  const callResponse = await axios({
    url: endpoints.campStaffBaseUrl,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const createCampImagesApi = async data => {
  const callResponse = await axios({
    url: endpoints.campImagesBaseUrl,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const deleteCampImagesField = async data => {
  const callResponse = await axios({
    url: endpoints.campImagesBaseUrl,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const closeCampApi = async data => {
  const callResponse = await axios({
    url: endpoints.campCloseUrl,
    method: "PATCH",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
