import { Person } from "@mui/icons-material"
import NoDataComponent from "../../components/layouts/NoDataComponent"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { Box, CircularProgress, Grid, Typography, styled } from "@mui/material"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import ImageComponent from "../../components/inputs/ImageComponent"
import { findObjectKeyByValue } from "../../utils/helper"
import { USER_ROLES } from "../../utils/constants"

const OuterContainer = styled(Box)(({ theme }) => ({
    background: theme.palette.light.main,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: "1px solid " + theme.palette.primary.main
}))
const UserProfileViewCommon = ({ loading, data, modalKey, title,role }) => {
    return <>
        <CustomDialog
            id={modalKey}
            loading={loading}

            title={(role?findObjectKeyByValue(role,USER_ROLES):"")+ "'s "+title}
            closeText="Close"
            confirmText={`Reset`}
        >
            {loading && <CenteredBox><CircularProgress /></CenteredBox>}
            {!loading && (!data || !data['_id']) && <NoDataComponent message={title + " not found"} Icon={Person} />}
            {
                !loading && data && data['_id'] && <Box sx={(theme) => ({ width: "100%", background: theme.palette.secondary.main, borderRadius: 1 })} p={2}>
                    <CenteredBox>
                        <ImageComponent src={data.photo} isAvatar avtarTitle={data.name[0]} />
                    </CenteredBox>
                    <CenteredBox>
                        <Typography textTransform="capitalize" variant="body1" fontWeight="bold" mt={2}>
                            {data.name}
                        </Typography>

                    </CenteredBox>
                    <OuterContainer mt={3}>
                        <Grid container spacing={2} rowSpacing={3}>
                            <Grid item md={2} xs={4}>
                                <Typography variant="body1" fontWeight="bold">{data.head_name ? `${ findObjectKeyByValue(role,USER_ROLES)?? 'Company'} Name:` : "Name:"}</Typography>
                            </Grid>
                            <Grid item md={4} xs={8}>
                                <Typography variant="body1">{data.name}</Typography>
                            </Grid>

                            {/* <Grid item md={2} xs={4}>
                                <Typography variant="body1" fontWeight="bold">Email:</Typography>
                            </Grid>
                            <Grid item md={4} xs={8}>
                                <Typography variant="body1">{data.email}</Typography>
                            </Grid> */}


                            {data.phone && <> <Grid item md={2} xs={4}>
                                <Typography variant="body1" fontWeight="bold">Phone:</Typography>
                            </Grid>
                                <Grid item md={4} xs={8}>
                                    <Typography variant="body1">{data.phone}</Typography>
                                </Grid>
                            </>
                            }

                            {data.head_name && <><Grid item md={2} xs={4}>
                                <Typography variant="body1" fontWeight="bold">Head Name:</Typography>
                            </Grid>
                                <Grid item md={4} xs={8}>
                                    <Typography variant="body1">{data.head_name}</Typography>
                                </Grid></>}
                            {data.contact_person_name && <><Grid item md={2} xs={4}>
                                <Typography variant="body1" fontWeight="bold">Contact Person:</Typography>
                            </Grid>
                                <Grid item md={4} xs={8}>
                                    <Typography variant="body1">{data.contact_person_name}</Typography>
                                </Grid></>}
                            {data.contact_person_phone && <><Grid item md={2} xs={6}>
                                <Typography variant="body1" fontWeight="bold"> Contact No:</Typography>
                            </Grid>
                                <Grid item md={4} xs={6}>
                                    <Typography variant="body1">{data.contact_person_phone}</Typography>
                                </Grid></>}


                            {data.company_description && <><Grid item md={12} xs={4}>
                                <Typography variant="body1" fontWeight="bold">{`${ findObjectKeyByValue(role,USER_ROLES)?? 'Company'} `} Description:</Typography>
                            </Grid>
                                <Grid item md={12} xs={8} >
                                    <Typography variant="body1">{data.company_description}</Typography>
                                </Grid></>}








                            {data.country && <>
                                <Grid item md={2} xs={4}>
                                    <Typography variant="body1" fontWeight="bold">Country:</Typography>
                                </Grid>
                                <Grid item md={4} xs={8}>
                                    <Typography variant="body1">{data.country && data.country.name}</Typography>
                                </Grid>
                            </>}

                            {data.state && <>
                                <Grid item md={2} xs={4}>
                                    <Typography variant="body1" fontWeight="bold">State:</Typography>
                                </Grid>
                                <Grid item md={4} xs={8}>
                                    <Typography variant="body1">{data.state && data.state.name}</Typography>
                                </Grid>
                            </>}
                            {data.district && <>
                                <Grid item md={2} xs={4}>
                                    <Typography variant="body1" fontWeight="bold">District:</Typography>
                                </Grid>
                                <Grid item md={4} xs={8}>
                                    <Typography variant="body1">{data.district && data.district.name}</Typography>
                                </Grid>
                            </>}
                            {data.taluka && <>
                                <Grid item md={2} xs={4}>
                                    <Typography variant="body1" fontWeight="bold">Taluka:</Typography>
                                </Grid>
                                <Grid item md={4} xs={8}>
                                    <Typography variant="body1">{data.taluka && data.taluka.name}</Typography>
                                </Grid>
                            </>}
                        </Grid>
                    </OuterContainer>
                </Box>
            }

        </CustomDialog>
    </>
}
export default UserProfileViewCommon