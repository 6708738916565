import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { updateModulesPermission } from "../../apis/user.api"
import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"

import ModulePermissionUi from "./ModulePermissionUi"

const CreateController = ({ callBack=()=>{}, id ,modules}) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()
    const modalKey = 'modules'
    const title = "Modules "
    
    const updateApi =  updateModulesPermission
    

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        modules

    })
   

    const validationSchemaForUpdate = useMemo(() => ([

       
    ]), [fields])



   


    const updateFunction = async () => {
        const validationResponse = validate(validationSchemaForUpdate)
        

       
        

        if (validationResponse === true) {


            setLoading(true)
            
            dispatch(
                callApiAction(
                    async () => await updateApi({...fields}),
                    async (response) => {

                        await callBack(response, {...fields})
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }



    const onSubmit = async (e) => {
        e.preventDefault()
            updateFunction()
        

    }

   console.log(fields)


    return <ModulePermissionUi 
    title={title}
    modalKey={modalKey}
    loading={loading}
    fields={fields}
    onSubmit={onSubmit}
    setFields={setFields} />
}
export default memo(CreateController)