import { Autocomplete, Box, CircularProgress, MenuItem } from "@mui/material"
import { Fragment, memo } from "react"
import { useDispatch, useSelector } from 'react-redux'

import CustomInput from "../../components/inputs/CustomInput"
import { AUS_STATES, USER_ROLES } from "../../utils/constants"
import { findNameByRole, findObjectKeyByValue, titleCase } from "../../utils/helper"

import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import MODULES from "../../utils/module.constant"




const ModulePermissionui = ({ title, fields, setFields, loading, onSubmit, modalKey }) => {
    const { user } = useSelector(state => state)

    let allowedRoles = [...Object.keys(USER_ROLES).map((key) => ({ label: titleCase(key), _id: USER_ROLES[key] }))]

    if (user.data.role === USER_ROLES.partner_admin) {
        allowedRoles = []
        Object.keys(USER_ROLES).forEach((key) => {
            if (USER_ROLES[key] != USER_ROLES.admin)
                allowedRoles.push({ label: titleCase(key), _id: USER_ROLES[key] })
        })
    }

    const dispatch = useDispatch()
    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`Change  ${title} permissions`}
            closeText="Close"
            confirmText={`Change permissions`}
        >

            {loading && <CenteredBox><CircularProgress /></CenteredBox>}
            {
                <>
<Box sx={{display:"flex",alignItems:"center","*":{justifyContent:"flex-start"}}}>

                    {<Autocomplete

                        key={loading}
                        disableClearable
                        options={Object.keys(MODULES).map((item) => ({ label: titleCase(item), id: MODULES[item] }))}
                        multiple
                        value={fields.modules ? fields.modules.map((item) => ({ label: findObjectKeyByValue(item,MODULES), id: item })) : []}

                        onChange={(e, newVal) => {
                            console.log(newVal)
                            setFields({ ...fields, modules: newVal?newVal.map((item)=>item.id):[] })
                        }}

                        sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                        renderInput={(params) => <CustomInput placeholder="Select Module*" {...params} label="Select Module*" />}
                    />}
</Box>
                </>}

        </CustomDialog>


    </>
}
export default memo(ModulePermissionui)