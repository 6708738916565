import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
// import { deleteUserApi, getUserApi, updateUserField } from "../../apis/user.api"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { findNameByRole, findObjectKeyByValue, titleCase } from "../../utils/helper"
import { Box, CircularProgress, IconButton, Switch, Typography } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./CreateController"
import { Delete, Edit } from "@mui/icons-material"
import MessageDilog from "../../components/MessageDilog"
import { getSecretsApi, updateSecretsField } from "../../apis/secrets.api"
import { deleteRegionField, getRegionApi } from "../../apis/region.api"
import { REGIONS_TYPE } from "../../utils/constants"
import fileDownload from "js-file-download"
// import UpdateController from "./UpdateController"

const ActionComponent = memo(({ params, setParams, deleteApi  }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false)


    const onDelete = () => {
        dispatch(openModal(<MessageDilog
            title="Alert!"
            message={"Are you sure to delete " + params.name + " & all it's regions?"}

            onSubmit={(e) => {
                e.preventDefault()
                setLoading(true)
                dispatch(callApiAction(
                    async () => await deleteApi({ id: params._id }),
                    (response) => {
                        setParams({})
                        setLoading(false)
                        dispatch(closeModal('delete'))
                    },
                    (err) => {
                        setLoading(false)
                    }
                ))
            }}

        />, "sm", false, "delete"))
    }

    const onEdit = () => {
        dispatch(openModal(<CreateController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm", false, "region"))
    }

    if (loading) {
        return <CircularProgress />
    }
    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <IconButton size="inherit" onClick={onDelete}>
            <Delete color="error" fontSize="inherit" />
        </IconButton>

        <IconButton size="inherit" onClick={onEdit}>
            <Edit color="primary" fontSize="inherit" />
        </IconButton>

    </Box>
})

//country id 64b7c0196fe78d21c5824484

// new id : 6583fee000b86495ea8c8066

// { _id: new ObjectId("64b7c17a6fe78d21c582448e"), district: 'Amreli' },
//   {
//     _id: new ObjectId("64cb6d3a5148ae096a32e098"),
//     district: 'Banglore'
//   },
//   { _id: new ObjectId("65423296838aa3aa11d8ffa5"), district: 'mumbai' },
//   {
//     _id: new ObjectId("65802adf809ef01372f83d35"),
//     district: 'Nalgonda'
//   },
//   {
//     _id: new ObjectId("6583ff2600b86495ea8c8070"),
//     district: 'Adilabad'
//   }


const ListController = () => {
    const dispatch = useDispatch()



    const title = "Regions "
    const modalKey = 'region'
    const fetchApi = getRegionApi
    const deleteApi = deleteRegionField




    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['name'],
        sort: '',
        parent: '',
        type: REGIONS_TYPE.COUNTRY,
        sortDirection: 1

    })

    const columns = useMemo(() => {
       
        const arr = [

            { id: 1, fieldName: 'name', label: 'Region name', align: "left", sort: true },

            { id: 3, fieldName: 'short_name', label: 'Short name', align: "left", sort: true },
            { id: 2, fieldName: 'type', label: 'Region type', align: "left", renderValue: (params, setParams) => findObjectKeyByValue(params.type, REGIONS_TYPE) },




        ]
        if (filters.type != REGIONS_TYPE.COUNTRY)
            arr.push(
                { id: 4, fieldName: 'parent', label: `${findObjectKeyByValue(filters.type - 1, REGIONS_TYPE)} Name`, align: "left", renderValue: (params) => params?.parent?.name }
            )

        arr.push(
            {
                id: 5,
                fieldName: '',
                label: 'Action',

                align: "right",
                renderValue: (params, setParams) => <ActionComponent params={params} deleteApi={deleteApi} setParams={setParams} />
            }
        )
        return [...arr]
    }, [dispatch, filters]);




    // do not change 

    const [loading, setLoading] = useState(false)
    const [exportLoading, setexportLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = (isExport) => {
        if(!isExport){
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))}else{
            setexportLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters, csv: true }),
                (response) => {
                    fileDownload(response, "regions.csv")
                    setexportLoading(false)
                },
                (err) => {
                    console.log(err)
                    setexportLoading(false)
                },
                true
            ))
        }
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController callBack={async () => { fetchList() }} />, "sm", false, modalKey))
    }


    const onExportBtnClick = () => {
        fetchList(true)
    }

    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                title={title}
                onCreateBtnClick={onCreateBtnClick}
                onExportBtnClick={onExportBtnClick}
                exportLoading={exportLoading}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController