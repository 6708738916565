import { Box, Chip, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography, styled } from "@mui/material"
import ImageComponent from "../../../components/inputs/ImageComponent"
import moment from "moment"
import UserProfileViewCommonController from "../../user/UserProfileViewCommonController"
import { getDonorByIdApi, getNgoByIdApi } from "../../../apis/user.api"
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import BeneficiaryDetailedViewController from "../../beneficiary/detailedview/DetailedViewController"
import NoDataComponent from "../../../components/layouts/NoDataComponent"
import { Info } from "@mui/icons-material"
import { memo, useCallback } from "react"



const ViewPanel = memo(
    ({ beneficiary_id, name, device_id, onBenificaryClick, index }) => {        
        return <TableRow   >
            <TableCell>
                {index + 1}
            </TableCell>
            <TableCell sx={{textTransform:"capitalize"}} >
                {name}
            </TableCell>
            <TableCell>
                {device_id}
            </TableCell>
            <TableCell>
                <IconButton onClick={() => {
                    onBenificaryClick(beneficiary_id)
                }}>
                    <Info />
                </IconButton>
            </TableCell>

        </TableRow>
    }
)

const BeneficiaryDetails = ({ data, show }) => {
    const dispatch = useDispatch()
    const onBenificaryClick = useCallback((beneficiaryId) => {
        dispatch(openModal(<BeneficiaryDetailedViewController
            id={beneficiaryId}

        />, "md", false, "beneficiary-detailed-view"))
    }, [])
    return <>
        <Box sx={{ display: show ? "block" : "none" ,width:"100%"}}>
            <Typography fontWeight="bold" variant="body1">
                Total: {data.benificiaris && Array.isArray(data.benificiaris) ? data.benificiaris.length : 0}
            </Typography>





            <Table sx={{ width: "100%", overflowX: "scroll" }}>

                <TableHead>
                    <TableRow>
                        <TableCell sx={{minWidth:"100px"}}>Index No</TableCell>
                        <TableCell sx={{minWidth:"150px"}}>Name</TableCell>
                        <TableCell sx={{minWidth:"100px"}} >Device Id</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data.benificiaris && Array.isArray(data.benificiaris) ? data.benificiaris.map((item, index) => <ViewPanel
                            index={index}
                            key={item.beneficiary_id ? item.beneficiary_id._id : Math.random()}
                            beneficiary_id={item.beneficiary_id ? item.beneficiary_id._id : null}
                            name={item.beneficiary_id ? item.beneficiary_id.surname + " " + item.beneficiary_id.name : "NA"}
                            device_id={item && item.device_id ? item.device_id : "NA"}
                            onBenificaryClick={onBenificaryClick}
                        />) : <NoDataComponent message="No Data Found" />
                    }
                </TableBody>
            </Table>
        </Box>
    </>
}
export default memo(BeneficiaryDetails)