import { Box, Button, ButtonGroup, Chip, CircularProgress, Grid, Typography } from "@mui/material"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"

import { useDispatch } from "react-redux"
import BeneficiaryDetailedViewController from "../beneficiary/detailedview/DetailedViewController"
import { openModal } from "../../store/actions/modalAction"
import { useCallback } from "react"
import BeneficiaryFetchCampController from "./beneficiarymodal/BeneficiaryFetchController"
import FileInput from "../../components/inputs/FileInput"

const AddImagesToEventUi = ({ data, loading, onSubmit, setData, modalKey, onImagesChange }) => {
    const dispatch = useDispatch()



    return (

        <CustomDialog
            onSubmit={onSubmit}
            id={modalKey}
            loading={loading}
            title={`Add Images To Event`}
            closeText="Close"


        >
            {
                loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                    <Box sx={(theme) => ({ height: "100%", width: "100%", display: "flex", border: "1px solid", overflow: "hidden", [theme.breakpoints.down('md')]: { flexDirection: "column" } })}>
                        <Box sx={{ display: "flex", borderRight: "1px solid black", flex: 1, overflow: "hidden", flexDirection: "column" }}>
                            <Box p={2} sx={{ display: "flex", background: "#f2f2f2" }}>
                                <Typography variant="button" textTransform="capitalize" >
                                    {
                                        data.name
                                    }
                                </Typography>
                            </Box>

                            <Box p={2} >
                                <FileInput
                                    noCompression
                                    fileProps={{ multiple: true }}
                                    multi={true}
                                    defaults={data && data.images ? data.images : []}
                                    title="Images"
                                    onlyImage
                                    subTitle="Only jpeg,png,jpg are required.(maximum 5 images)"

                                    onChange={(newUrl, list) => {
                                        onImagesChange(list)
                                    }} />
                            </Box>
                        </Box>
                    </Box>
            }
        </CustomDialog>
    )

}
export default AddImagesToEventUi