import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"
import { addBeneficiaryToEventApi, getEventByIdApi } from "../../apis/event.api"

import { closeModal } from "../../store/actions/modalAction"
import { getNewUniqueElements, getUniqueElements } from "../../utils/helper"
import AssignBeneficiaryFollowupUi from "./AssignBeneficiaryFollowupUi"
import { assignFollowUpApi } from "../../apis/followup.api"

const AssignBeneficiaryFollowUpController = ({ id, callBack }) => {
    const dispatch = useDispatch()
    const [data, setData] = useState({})
    const modalKey = 'assign-beneficiary-followup'
    const [loading, setLoading] = useState(false)


    const [processingLoading, setProcessingLoading] = useState(false)



    const [fields, setFields] = useState({
        assigned_to: null
    })
    const [benficiaryList, setBeneficiaryList] = useState([])

    const fetchById = () => {

    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (!fields.assigned_to || fields.assigned_to == '') {
            dispatch(callSnackBar('Please Assign Followup To Someone', SNACK_BAR_VARIETNS.error))
            return
        }
        setLoading(true)
        const formData = new FormData()
        formData.append('beneficiaries', JSON.stringify(benficiaryList.map((item) => ({
            beneficiary_id: item._id,
            name: item.name + " " + item.surname,
            phone: item.phone
        }))))
        formData.append('id', id)
        formData.append('assigned_to', fields.assigned_to)
        dispatch(
            callApiAction(
                async () => await assignFollowUpApi(formData),
                async (response) => {


                    setLoading(false)
                    setBeneficiaryList([])

                    dispatch(callSnackBar(('Beneficiaries Followup Assigned. '+ data.name), SNACK_BAR_VARIETNS.suceess))
                },
                (err) => {
                    setLoading(false)
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))

                }
            )
        )
    }


    const onAddBeneficiary = (beneficiaries) => {
        setProcessingLoading(true)

        const alreadyAssigned = [...benficiaryList]

        const newBeneficiaryArr = getUniqueElements(beneficiaries)
        const latestArr = getNewUniqueElements(alreadyAssigned, newBeneficiaryArr)
        setBeneficiaryList([...alreadyAssigned, ...latestArr])

        setProcessingLoading(false)
    }
    const onDeleteBeneficiary = useCallback((id) => {
        const arr = [...benficiaryList]
        const index = arr.findIndex(obj => obj._id === id);
        if (index !== -1) {
            arr.splice(index, 1);
        }
        setBeneficiaryList(arr)
    }, [benficiaryList])
    useEffect(() => {
        fetchById()
    }, [id])

    return <AssignBeneficiaryFollowupUi
        processingLoading={processingLoading}
        onDeleteBeneficiary={onDeleteBeneficiary}
        id={id}
        onSubmit={onSubmit}
        loading={loading}
        data={data}
        setData={setData}
        modalKey={modalKey}
        setFields={setFields}
        fields={fields}
        benficiaryList={benficiaryList}
        onAddBeneficiary={onAddBeneficiary}
    />
}
export default AssignBeneficiaryFollowUpController