import { Autocomplete, Box, Grid, MenuItem, Paper, Skeleton, Typography, styled, useTheme } from "@mui/material"

import { Bar, Pie } from "react-chartjs-2"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import Counts from "./Counts"
import { formatNumberCustomPattern, formatNumberWithCommas } from "../../utils/helper"

const TotalHearingImpairedCounts = ({ list, loading,totalHearingImpaired }) => {
    const theme = useTheme()
    const labels = ["Able To hear with HearNU", "Not able To Hear with HearNu"];
    const finalData = [0, 0]

    if (list && Array.isArray(list) && list.length > 0) {
        for (let item of list) {
            if (item._id) {
                finalData[0] = item.count
            } else {
                finalData[1] = item.count
            }

        }
    }
    const options = {



    }

    const data = {
        labels,
        datasets: [
            {
                // label: 'Total Beneficiary',
                data: finalData,
                // backgroundColor: theme.palette.primary.main,
                // barPercentage:1,
                // base:0
            }
        ],
    };

    if (loading) {
        return (
            <>
                {[0, 1, 2, 3].map((item) => <Grid item xs={6} md={3} key={item}>

                    <Paper component={Box} mt={2} mb={2} p={2}>
                        <Skeleton variant="rounded" height="120px" width="100%" >

                        </Skeleton>
                    </Paper>
                </Grid>)}


            </>)
    }
    
    const pending = totalHearingImpaired-(finalData[0]+finalData[1]);
    return <>
        <Grid item xs={6} md={3} mb={2}>
            <Counts title="Total Hearing" subtitle="Impaired Children" count={formatNumberCustomPattern((totalHearingImpaired) ?? 0)} />
        </Grid>
        <Grid item xs={6} md={3} mb={2}>
            <Counts  title="Total Benificieries Tested" subtitle="With Device" count={formatNumberCustomPattern((finalData[0]+finalData[1]) ?? 0)} />
        </Grid>
        <Grid item xs={6} md={3} mb={2}>
            <Counts  title="Total Benificieries Pending" subtitle="For Assessment" count={formatNumberCustomPattern((totalHearingImpaired ?? 0) - ((finalData[0] ?? 0)+(finalData[1]?? 0)) ?? 0)} />
        </Grid>
        <Grid item xs={6} md={3} mb={2}>
             <Counts title="Pending" subtitle="For Assessment" count={(pending * 100 / totalHearingImpaired) ?? 0} unit={'%'} />
        </Grid>
    </>
}
export default TotalHearingImpairedCounts