import { Autocomplete, Button, ButtonGroup, Paper, ListItem, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { StyledSearchBar, UserSearchBarNormal } from "../../../components/inputs/SearchBar"


import { REGIONS_TYPE, USER_ROLES } from "../../../utils/constants"
import { findObjectKeyByValue, titleCase, toTitleCase } from "../../../utils/helper"
import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"
import { getRegionApi } from "../../../apis/region.api"
import { getCampApi } from "../../../apis/camp.api"
import { BENIFICIARY_STATUS } from "../../../utils/beneficiary.constant"
import TimeRangeSelector from "../../../components/layouts/common/TimeRangeSelector"
import { getProductApi } from "../../../apis/product.api"
import AsynSearchBar from "../../../components/inputs/AsynSearchBar"

import CustomDialog from "../../../components/layouts/common/CustomDialog"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, modalKey, exportLoading, onExportBtnClick }) => {

    const user = useSelector(state => state.user)

    return (
        <>
            <CustomDialog
                id={modalKey}
                loading={exportLoading}
                title={title}
                onSubmit={onExportBtnClick}
                closeText="Close"
                confirmText="Download"

            >
                <Box mb={3}>

                    <Box mb={3} >


                        <FiltersBox mt={3} >



                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}   >
                                <AsyncDropDown


                                    lazyFun={async (para) => await getCampApi({ ...para, allStatus: true })}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, camp: changedVal ? changedVal : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Camp"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }} pl={2} >
                                <AsyncDropDown


                                    lazyFun={async (para) => { return await getRegionApi({ type: REGIONS_TYPE.COUNTRY, ...para }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, country: changedVal ? changedVal : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Country"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                            <PaddingBoxInDesktop pl={2} mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => { return await getRegionApi({ type: REGIONS_TYPE.STATE, ...para }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, state: changedVal ? changedVal : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select State"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>


                        </FiltersBox>
                        <FilterTitleBox>




                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown

                                    multiple
                                    lazyFun={async (para) => { return await getRegionApi({ type: REGIONS_TYPE.DISTRICT, ...para }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, district: changedVal ? changedVal : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Districts"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>


                            <PaddingBoxInDesktop pl={2} mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown

                                    multiple
                                    lazyFun={async (para) => { return await getRegionApi({ type: REGIONS_TYPE.TALUKA, ...para }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, taluka: changedVal ? changedVal : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Taluka"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                        </FilterTitleBox>







                    </Box>


                </Box>
            </CustomDialog>
        </>
    )
}
export default ListUi