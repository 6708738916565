import { OpenInNew } from "@mui/icons-material"
import { Autocomplete, Button, ButtonGroup, Paper, ListItem, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"



import SubmitButton from "../../components/button/SubmitButton"
import { StyledSearchBar, UserSearchBarNormal } from "../../components/inputs/SearchBar"


import { REGIONS_TYPE, USER_ROLES } from "../../utils/constants"
import DataTable from "../../components/tables/DataTable"
import { findObjectKeyByValue, titleCase, toTitleCase } from "../../utils/helper"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getRegionApi } from "../../apis/region.api"
import { getCampApi } from "../../apis/camp.api"
import { BENIFICIARY_STATUS } from "../../utils/beneficiary.constant"
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector"
import { getProductApi } from "../../apis/product.api"
import AsynSearchBar from "../../components/inputs/AsynSearchBar"
import { openModal } from "../../store/actions/modalAction"
import CreateController from "./CreateController"
import BeneficiaryCreateButton from "./components/BeneFiciaryCreateButton"
import SheetDonwnloadButton from "./sheet/SheetDonwnloadButton"
import { getEventApi } from "../../apis/event.api"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list, exportLoading, loading, onExportBtnClick, columns ,callBackFun}) => {
   
    const user = useSelector(state => state.user)
   
    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={3} >
                        <FilterTitleBox>
                            <Typography variant="h5" mb={2} >{title}</Typography>

                           <BeneficiaryCreateButton callBack={callBackFun} />
                        </FilterTitleBox>


                        <FiltersBox mt={3} >
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <TimeRangeSelector onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />
                            </PaddingBoxInDesktop>


                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }} pl={2}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => await getCampApi({ ...para, allStatus: true })}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, camp: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Camp"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>
                            
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }} pl={2}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => await getProductApi({ ...para, allStatus: true })}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, product: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Product"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} pl={2} >
                                <Autocomplete
                                    disableClearable
                                    onChange={(e, changedVal) => { setFilters({ ...filters, status: changedVal ? changedVal._id : null, }) }}
                                    options={[{ label: 'All', _id: null }, ...Object.keys(BENIFICIARY_STATUS).map((key) => ({ label: titleCase(key), _id: BENIFICIARY_STATUS[key] }))]}

                                    sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                                    renderInput={(params) => <StyledSearchBar placeholder="Select Status" {...params} />}
                                />
                            </PaddingBoxInDesktop>
                            {/* <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }} pl={2}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => await getProductApi({ ...para, allStatus: true })}


                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, product: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Product"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop> */}
                               <PaddingBoxInDesktop
                  mb={1}
                  mt={1}
                  sx={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    "*": {
                      justifyContent: "center",
                    },
                  }}
                  pl={3}
                >
                  <AsyncDropDown
                    // key={filters.donation_event_id}
                    lazyFun={async (para) => {
                      return await getEventApi({ ...para });
                    }}
                    OptionComponent={({ option, ...rest }) => {
                      return <ListItem {...rest}>{option.name}</ListItem>;
                    }}
                    onChange={async (changedVal) => {
                      setFilters({
                        ...filters,
                        donation_event_id: changedVal ? changedVal._id : null,
                      });
                    }}
                    titleKey={"name"}
                    valueKey={"_id"}
                    InputComponent={(params) => (
                      <StyledSearchBar
                        placeholder={"Select Event"}
                        {...params}
                        margin="none"
                      />
                    )}
                  />
                </PaddingBoxInDesktop>
                            {/*  */}


                        </FiltersBox>
                        <FilterTitleBox>


                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => { return await getRegionApi({ type: REGIONS_TYPE.COUNTRY, ...para }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, country: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Country"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                            <PaddingBoxInDesktop pl={2} mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => { return await getRegionApi({ type: REGIONS_TYPE.STATE, ...para }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, state: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select State"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                            <PaddingBoxInDesktop pl={2} mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => { return await getRegionApi({ type: REGIONS_TYPE.DISTRICT, ...para }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, district: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select District"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>


                            <PaddingBoxInDesktop pl={2} mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => { return await getRegionApi({ type: REGIONS_TYPE.TALUKA, ...para }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, taluka: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Taluka"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                        </FilterTitleBox>




                        <FilterTitleBox>


                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", flexDirection: "column" }}  >
                                <Typography>Has Disability Certificate?:</Typography>
                                <ButtonGroup size="small">
                                    <Button variant={filters.has_desability_certi == null ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, has_desability_certi: null }) }} >All</Button>
                                    <Button variant={filters.has_desability_certi == 'true' ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, has_desability_certi: 'true' }) }}>Yes</Button>
                                    <Button variant={filters.has_desability_certi == 'false' ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, has_desability_certi: 'false' }) }}>No</Button>
                                </ButtonGroup>
                            </PaddingBoxInDesktop>






                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", flexDirection: "column" }}  >
                                <Typography>Has Aadhar Card?:</Typography>
                                <ButtonGroup size="small">
                                    <Button variant={filters.aadhar_file == null ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, aadhar_file: null }) }} >All</Button>
                                    <Button variant={filters.aadhar_file == 'true' ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, aadhar_file: 'true' }) }}>Yes</Button>
                                    <Button variant={filters.aadhar_file == 'false' ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, aadhar_file: 'false' }) }}>No</Button>
                                </ButtonGroup>
                            </PaddingBoxInDesktop>

                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", flexDirection: "column" }}  >
                                <Typography>Has Income Certificate?:</Typography>
                                <ButtonGroup size="small">
                                    <Button variant={filters.income_certi == null ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, income_certi: null }) }} >All</Button>
                                    <Button variant={filters.income_certi == 'true' ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, income_certi: 'true' }) }}>Yes</Button>
                                    <Button variant={filters.income_certi == 'false' ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, income_certi: 'false' }) }}>No</Button>
                                </ButtonGroup>
                            </PaddingBoxInDesktop>



                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", flexDirection: "column" }}  >
                                <Typography>Has Photo?:</Typography>
                                <ButtonGroup size="small">
                                    <Button variant={filters.photo == null ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, photo: null }) }} >All</Button>
                                    <Button variant={filters.photo == 'true' ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, photo: 'true' }) }}>Yes</Button>
                                    <Button variant={filters.photo == 'false' ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, photo: 'false' }) }}>No</Button>
                                </ButtonGroup>
                            </PaddingBoxInDesktop>

                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", flexDirection: "column" }}  >
                                <Typography>Has UDISE NO?:</Typography>
                                <ButtonGroup size="small">
                                    <Button variant={filters.udise_no == null ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, udise_no: null }) }} >All</Button>
                                    <Button variant={filters.udise_no == 'true' ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, udise_no: 'true' }) }}>Yes</Button>
                                    <Button variant={filters.udise_no == 'false' ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, udise_no: 'false' }) }}>No</Button>
                                </ButtonGroup>
                            </PaddingBoxInDesktop>


                        </FilterTitleBox>


                    </Box>
                    <FiltersBox mb={3}>
                        <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }}  >
                            <Box sx={{ display: "flex",flexWrap:"wrap" }}  >
                                <Box mr={1}>
                                <SheetDonwnloadButton />
                                </Box>
                                <Box>
                                {<SubmitButton loading={exportLoading} variant="contained" onClick={onExportBtnClick} title={"Export CSV"} />}
                                </Box>
                            </Box>
                        </PaddingBoxInDesktop>

                        <PaddingBoxInDesktop mb={2} sx={{
                            display: "flex", flex: 1, justifyContent: "flex-end", "*": {
                                justifyContent: "center"
                            }
                        }} pl={2} >

                            <AsynSearchBar

                                fullWidth
                                title="Search By  Name | Phone No "
                                size="small"
                                placeholder={"Search By  Name | Phone No "}
                                defaultValue={filters.search}
                                onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                            />
                            {/* <AsynS fullWidth title="Search By  Name | Phone No " placeholder={"Search By  Name | Phone No  "} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}
                        </PaddingBoxInDesktop>
                    </FiltersBox>
                    <Box sx={{ minHeight: "300px" }} className="table">
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi