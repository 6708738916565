
import CustomInput from "../../../components/inputs/CustomInput"
import FileInput from "../../../components/inputs/FileInput"

const NGOFields = ({ fields, setFields, loading }) => {
  return <>

    <CustomInput
      disabled={loading}
      value={fields.contact_person_name}
      onChange={(e) => setFields({ ...fields, err: '', contact_person_name: e.target.value })}

      type="text"
      label={"Contact Person Name*"}

    />
    <CustomInput
      disabled={loading}
      value={fields.contact_person_phone}
      onChange={(e) => setFields({ ...fields, err: '', contact_person_phone: e.target.value })}

      type="tel"
      label={"Contact Person Phone*"}

    />
    <FileInput
      defaults={fields.photo ? [fields.photo] : undefined}
      title="NGO Logo"
      subTitle="Only png, jpg, jpeg are required."
      onlyImage
      onChange={(newUrl) => {
        setFields({ ...fields, photo: newUrl })
      }} />


  </>
}
export default NGOFields