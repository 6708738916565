import { Box, Chip, Grid, ImageList, ImageListItem, Typography, styled } from "@mui/material"
import ImageComponent from "../../../components/inputs/ImageComponent"
import moment from "moment"
import UserProfileViewCommonController from "../../user/UserProfileViewCommonController"
import { getDonorByIdApi, getNgoByIdApi } from "../../../apis/user.api"
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import NoDataComponent from "../../../components/layouts/NoDataComponent"
import { Collections } from "@mui/icons-material"

const ImageDetails = ({ data,show }) => {
    const dispatch = useDispatch()
   

    return <>
    <Box sx={{display:show?"block":'none'}}>
          {
            data && data.images && data.images.length?<>
            
            {
                 <ImageList sx={{ width: '100%',height:'100%'}} cols={3} rowHeight={164}>
                 {data.images.map((item) => (
                   <ImageListItem key={item}>
                     
                     <ImageComponent loading="lazy"  src={item} />
                   </ImageListItem>
                 ))}
               </ImageList>
            }
            </>:<><NoDataComponent Icon={Collections} message="No Images Found" /></>
          }
        </Box>         
    </>
}
export default ImageDetails