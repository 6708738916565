import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { addUserApi, getUserByIdApi, updateInstallerUserField, updateUserField } from "../../apis/user.api"
import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"
import { USER_ROLES } from "../../utils/constants"
import { findObjectKeyByValue, validatePhone } from "../../utils/helper"

const CreateController = ({ callBack=()=>{}, id, role = null }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()
    const modalKey = 'users'
    const title = role && findObjectKeyByValue(role,USER_ROLES)?findObjectKeyByValue(role,USER_ROLES): "User "
    const createApi = addUserApi
    const updateApi = updateUserField
    const getByIdApi = getUserByIdApi

    const [loading, setLoading] = useState(false)
const isRolePredefined = role!==null
    const [fields, setFields] = useState({
        err: '',
        id,
        name: '',
        email: '',
        password: '',
        role

    })
    const [originalDocument, setOriginalDocument] = useState({


    })


    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.name,
            field: 'Name',

        },
        {
            required: true,
            value: fields.email,
            field: 'Email',
            isEmail: true,
        },
        
        {
            required: true,
            value: fields.password,
            field: 'Password',
        },

        {
            required: true,
            value: fields.role,
            field: 'Role',
        },

        {
           
            value: fields.phone,
            field: 'Phone',
            custom:()=>{
                const roles = [ USER_ROLES.TEACHER, USER_ROLES.ADMIN, USER_ROLES.IMPACT_ANALYSIS_TEAM, USER_ROLES.REGISTRATION_TEAM, USER_ROLES.IMPACT_ANALYSIS_TEAM, USER_ROLES.values, USER_ROLES.EVENT_TEAM, USER_ROLES.SIT_TEAM, USER_ROLES.SIT_MANAGER]
                if (roles.includes(fields.role) && (!fields.phone || fields.phone == '')) {
                    return "Phone no. is required."
                }
                if(roles.includes(fields.role) && !validatePhone(fields.phone)){
                    return "Phone no. is invalid"
                }
                return true
            }
        },
        // {
           
        //     value: fields.signature,
        //     field: 'signature',
        //     custom:()=>{
        //         const roles = [ USER_ROLES.TEACHER, USER_ROLES.ADMIN, USER_ROLES.IMPACT_ANALYSIS_TEAM, USER_ROLES.REGISTRATION_TEAM, USER_ROLES.IMPACT_ANALYSIS_TEAM, USER_ROLES.values, USER_ROLES.EVENT_TEAM, USER_ROLES.SIT_TEAM, USER_ROLES.SIT_MANAGER]
        //         if (roles.includes(fields.role) && (!fields.signature || fields.signature == '')) {
        //             return "Signature required."
        //         }                
        //         return true
        //     }
        // },
        {

            value: fields.country,
            field: 'Country',
            custom: () => {
                const roles = [
                    USER_ROLES.TEACHER,
                    
                    USER_ROLES.GOVERMENT,
                ]
                if (roles.includes(fields.role) && (!fields.country || fields.country == '')) {
                    return "Country is required."
                }
                return true
            }
        },


        {

            value: fields.state,
            field: 'State',
            custom: () => {
                const roles = [
                    USER_ROLES.TEACHER,
                    
                    USER_ROLES.GOVERMENT,
                ]
                if (roles.includes(fields.role) && (!fields.state || fields.state == '')) {
                    return "State is required."
                }
                return true
            }
        },
        {

            value: fields.district,
            field: 'District',
            custom: () => {
                const roles = [
                    USER_ROLES.TEACHER,
                    
                ]
                if (roles.includes(fields.role) && (!fields.district || fields.district == '')) {
                    return "District is required."
                }
                return true
            }
        },

        {

            value: fields.taluka,
            field: 'Taluka',
            custom: () => {
                const roles = [
                    USER_ROLES.TEACHER                    
                ]
                
                if (roles.includes(fields.role) && (!fields.taluka || fields.taluka == '')) {
                    
                    return "Taluka is required."
                }
                console.log("in")
                return true
            }
        },




       

        {

            value: fields.head_name,
            field: 'Head Name',
            custom: () => {
                const roles = [
                    USER_ROLES.DONOR,

                ]
                console.log(roles.includes(fields.role))
                if (roles.includes(fields.role) && (!fields.head_name || fields.head_name == '')) {
                    return "Head name is required."
                }
                return true
            }
        }
        ,
        {

            value: fields.company_description,
            field: 'Company description',
            custom: () => {
                const roles = [
                    USER_ROLES.DONOR,

                ]
                if (roles.includes(fields.role) && (!fields.company_description || fields.company_description == '')) {
                    return "Company description is required."
                }
                return true
            }
        },
        {

            value: fields.contact_person_name,
            field: 'Contact person name',
            custom: () => {
                const roles = [
                    USER_ROLES.DONOR,
                    USER_ROLES.NGO,

                ]
                if (roles.includes(fields.role) && (!fields.contact_person_name || fields.contact_person_name == '')) {
                    return "Contact person name is required."
                }
                return true
            }
        },
        {

            value: fields.contact_person_phone,
            field: 'Contact person phone',
            custom: () => {
                const roles = [
                    USER_ROLES.DONOR,

                    USER_ROLES.NGO,
                    USER_ROLES.GOVERMENT,
                ]
                if (roles.includes(fields.role) && (!fields.contact_person_phone || fields.contact_person_phone == '')) {
                    return "Contact person phone no is required."
                }

                if (roles.includes(fields.role) && !validatePhone(fields.contact_person_phone)) {
                    return "Contact person phone no is invalid."
                }
                return true
            }
        },
        // {

        //     value: fields.photo,
        //     field: 'Profile picture',
        //     custom: () => {
        //         const roles = [
        //             USER_ROLES.TEACHER,
        //             
        //             // USER_ROLES.NGO,
        //             // USER_ROLES.DONOR,
        //             // USER_ROLES.GOVERMENT,

        //         ]
        //         if (roles.includes(fields.role) && (!fields.photo || fields.photo == '')) {
        //             return "Profile picture is required."
        //         }
        //         const roles2 = [
                    
        //             USER_ROLES.NGO,
        //             USER_ROLES.DONOR,
        //             USER_ROLES.GOVERMENT,

        //         ]
        //         if (roles2.includes(fields.role) && (!fields.photo || fields.photo == '')) {
        //             return "Logo is required."
        //         }
        //         return true
        //     }
        // }



    ]), [fields])

    const validationSchemaForUpdate = useMemo(() => ([

        {
            required: true,
            value: fields.name,
            field: 'Name',
        },
        {
           
            value: fields.phone,
            field: 'Phone',
            custom:()=>{
                const roles = [ USER_ROLES.ADMIN, USER_ROLES.IMPACT_ANALYSIS_TEAM, USER_ROLES.REGISTRATION_TEAM, USER_ROLES.IMPACT_ANALYSIS_TEAM, USER_ROLES.values, USER_ROLES.EVENT_TEAM, USER_ROLES.SIT_TEAM, USER_ROLES.SIT_MANAGER]
                if (roles.includes(fields.role) && (!fields.phone || fields.phone == '')) {
                    return "Phone no. is required."
                }
                if(roles.includes(fields.role) && !validatePhone(fields.phone)){
                    return "Phone no. is invalid"
                }
                return true
            }
        },
    ]), [fields])



    const createFunction = async () => {
        
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi(fields),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


    const updateFunction = async () => {
        const validationResponse = validate(validationSchemaForUpdate)
        let updatedData = { id }

        for (let field in fields) {

            if (originalDocument[field] && fields[field] && fields[field] != originalDocument[field]) {
                updatedData[field] = fields[field]
            }

            if(!originalDocument[field] && fields[field]){
                updatedData[field] = fields[field]
            }
        }


        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(updatedData),
                    async (response) => {

                        await callBack(response, updatedData)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }



    const onSubmit = async (e) => {

        e.preventDefault()
        if (id)
            updateFunction()
        else
            createFunction()

    }

    const fetchById = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {


                    setFields({ ...fields, ...response })
                    setOriginalDocument(response)
                    setLoading(false)

                },
                (err) => {
                    setFields({ ...fields, err })
                    setLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])


    return <CreateUi isRolePredefined={isRolePredefined} modalKey={modalKey} title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)