import { Box, Button, Divider, Paper, Typography } from "@mui/material"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import moment from "moment"
import ListController from "./ListController"
import { toTitleCase } from "../../utils/helper"
import { HEARING_STATE, SPEECH_STATE } from "../../utils/beneficiary.constant"

const VideoViewer = ({ params }) => {
console.log("PARAMS",params)
    return <CustomDialog
        title="Video"
        id={'video-viewer'}
    >
        <Box sx={(theme) => ({ width: "100%" })} p={3}>
            <Button disableElevation sx={{ mb: 2 }} variant="contained" target="_blank" href={params?.videos[0]?.url ?? params?.videos?.url} >
                Open Video
            </Button>
            <Paper sx={{ height: "100%",width:"100%",display:"flex",gap:"100px"}}>  

            <Typography p={2} mx={3} >Name :<br/> {toTitleCase(params?.name ?? params?.beneficiary_id?.name+" "+(params?.surname ?? params?.beneficiary_id?.surname))}</Typography>
            
            <Typography p={2} >Followup Date :<br/> {moment(params?.followedup_date ?? params?.videos?.[0]?.date).format("DD/MM/YYYY")}</Typography>
           
            <Typography p={2} >Hearing State :<br/>  {Object.keys(HEARING_STATE)[Object.values(HEARING_STATE).indexOf(params?.hearing_state ??params?.beneficiary_id?.hearing_state)] }</Typography>
            <Typography p={2}>Speech State :<br/>  {Object.keys(SPEECH_STATE)[Object.values(SPEECH_STATE).indexOf(params?.speech_state ?? params?.beneficiary_id?.speech_state)] }</Typography>
         
            </Paper>

            <iframe style={{ width: "100%", minHeight: "300px", maxHeight: "500px" }} src={params?.videos[0]?.url ?? params?.videos?.url} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

        </Box>
    </CustomDialog>
}
export default VideoViewer