import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal, openModal } from "../../store/actions/modalAction"
import { Box, Chip, CircularProgress, IconButton, Rating, Switch, Typography } from "@mui/material"
import { useMemo } from "react"

import { Delete, Edit, Movie, MovieCreationOutlined } from "@mui/icons-material"
import MessageDilog from "../../components/MessageDilog"
import { REGIONS_TYPE, VIDEOS_TAGS } from "../../utils/constants"
import { deleteCampField, getCampApi } from "../../apis/camp.api"
import { getVidoesApi } from "../../apis/followup.api"
import moment from "moment"
import VideoViewer from "./VideoViewer"
import { getMainBeneficiaryVideoListApi } from "../../apis/beneficiary.api"
import { findObjectKeyByValue } from "../../utils/helper"

const ActionComponent = memo(({ params, setParams,  modalKey }) => {
    const dispatch = useDispatch()


    const onView = () => {        
        dispatch(openModal(<VideoViewer params={params} />,'md',undefined,'video-viewer'))
    }
    
    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <IconButton size="inherit" onClick={onView}>
            <MovieCreationOutlined color="error" fontSize="inherit" />
        </IconButton>

    </Box>
})



const ListController = ({ defaultFilters = {} }) => {
    const dispatch = useDispatch()



    const title = "Videos "
    const modalKey = 'video'
    const fetchApi = getMainBeneficiaryVideoListApi


    const columns = useMemo(() => [
        { id: 1, fieldName: 'name', label: 'Name', align: "left", renderValue: (params) => params.beneficiary_id.name + " " + params.beneficiary_id.surname },
        { id: 2,sort:true, fieldName: 'event_id.date', label: 'Donation Date', align: "left", renderValue: (params) => moment(params.event_id?.date).format("DD/MM/yyyy") },
        // { id: 2,sort:true, fieldName: 'followedup_date', label: 'Follow Up Date', align: "left", renderValue: (params) => moment(params.followedup_date).format("DD/MM/yyyy") },
        { id: 3, fieldName: 'event_id.name', label: 'Event', align: "left", renderValue: (params) =>params?.event_id?.name },
        { id: 5,sort:true, fieldName: 'followup_no', label: 'Followup No.', align: "center",renderValue:(params)=>params?.followup_no },
        { id: 5,sort:true, fieldName: 'tags', label: 'Tags', align: "center",renderValue:(params)=>findObjectKeyByValue(params?.videos?.tags, VIDEOS_TAGS) },
        { id: 6, fieldName: 'ratings', label: 'Ratings', align: "center",  renderValue: (params, setParams) => (
            <Chip
              size="small"
              color="primary"
              label={params?.videos?.ratings + " Star"}
            />
          ), },
        { id: 4, fieldName: 'videos', label: 'Video', align: "left", renderValue: (params) => <ActionComponent params={params}  /> },
    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 25,
        search: '',
        searchable: ['name'],
        sort: '',
        sortDirection: -1,
        isDropDown:true,
        followup_no:null,
        country: null,
        state: null,
        district: null,
        status: null,
        taluka: null,
        donation_event_id:null,
        donors:null,
        tags:'',
        ratings:'',
...defaultFilters ,
    })
    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters}),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        // dispatch(openModal(<CreateController callBack={async () => { fetchList() }} />, "sm", false, modalKey))
    }

    useEffect(() => {
        fetchList()
    }, [filters])

    return (
        <>
            <ListUi
                title={title}
                onCreateBtnClick={onCreateBtnClick}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}
            />

        </>
    )
}
export default ListController