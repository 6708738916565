import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal } from "../../store/actions/modalAction"
import { addCampApi, getCampByIdApi, updateCampApi } from "../../apis/camp.api"
import moment from "moment"

const CreateController = ({ callBack=()=>{}, id }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    
    const modalKey = 'create-camp'
    const title = "Camp "
    
    const createApi =  addCampApi
    const updateApi =  updateCampApi
    const getByIdApi = getCampByIdApi

    const [loading, setLoading] = useState(false)

    const [fetchLoading, setFetchLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        name: '',
        start_date:moment().toISOString(),
        camp_head:null,
        camp_coordinator:null,
        total_expected_children:null,
        venue:"",
        country:null,
        state:null,
        district:null,
        taluka:null,

      
        

    })
    const [originalDocument, setOriginalDocument] = useState({


    })


    const validationSchemaCreate = useMemo(() => ([

        {
            required: true,
            value: fields.name,
            field: 'Camp Name',
        },
        {
            required: true,
            value: fields.camp_head,
            field: 'Camp Head',
        },

        {
            required: true,
            value: fields.camp_coordinator,
            field: 'Camp Coordinator',
        },        {
            required: true,
            value: fields.total_expected_children,
            field: 'Expected Children',
        },
        {
            required: true,
            value: fields.venue,
            field: 'Venue',
        },
        {
            required: true,
            value: fields.country,
            field: 'Country',
        },
        {
            required: true,
            value: fields.state,
            field: 'State',
        },
        {
            required: true,
            value: fields.district,
            field: 'District',
        },
        {
            required: true,
            value: fields.taluka,
            field: 'Taluka',
        }
       
    ]), [fields])

    const validationSchemaForUpdate = useMemo(() => ([

        {
            required: true,
            value: fields.name,
            field: 'Camp Name',
        },
        {
            required: true,
            value: fields.camp_head,
            field: 'Camp Head',
        },
        {
            required: true,
            value: fields.total_expected_children,
            field: 'Expected Children',
        },
        {
            required: true,
            value: fields.venue,
            field: 'Venue',
        },
        {
            required: true,
            value: fields.country,
            field: 'Country',
        },
        {
            required: true,
            value: fields.state,
            field: 'State',
        },
        {
            required: true,
            value: fields.district,
            field: 'District',
        },
        {
            required: true,
            value: fields.taluka,
            field: 'Taluka',
        }
       
    ]), [fields])



    const createFunction = async () => {
        const validationResponse = validate(validationSchemaCreate)
        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi({...fields}),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


    const updateFunction = async () => {
        
        const validationResponse = validate(validationSchemaForUpdate)
        let updatedData = { id }

        for (let field in fields) {

            if (originalDocument[field] && fields[field] && fields[field] != originalDocument[field]) {
                const fieldVal = fields[field]
                updatedData[field] = typeof fieldVal == "object" ? fieldVal['_id'] : fieldVal
            }
            if(!originalDocument[field] && fields[field]){
                updatedData[field] = fields[field]
            }
        }
        

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(updatedData),
                    async (response) => {

                        await callBack(response, updatedData)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }



    const onSubmit = async (e) => {
        e.preventDefault()
        if (id)
            updateFunction()
        else 
        createFunction()
        

    }

    const fetchById = (id) => {
        setFetchLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {


                    setFields({ ...fields, ...response })
                    setOriginalDocument(response)
                    setFetchLoading(false)

                },
                (err) => {
                    setFields({ ...fields, err })
                    setFetchLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])


    return <CreateUi modalKey={modalKey} title={title} fetchLoading={fetchLoading} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)