import { Box, CircularProgress, IconButton, Tab, Tabs } from "@mui/material"
import { useState } from "react"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
// import BeneficiaryDetails from "./BeneficiaryDetails"
import LogDetails from "./LogDetails"
import HearingDetails from "./HearingDetails"
import FollowUpDetails from "./FollowupDetails"

import BeneficiaryValidateButton from "../components/BeneficiaryValidateButton"
import BeneficiaryUpdateButton from "../components/BeneficiaryUpdateButton"
import { useDispatch, useSelector } from "react-redux"
import MODULES from "../../../utils/module.constant"
import BeneficiaryPersonalDeatils from "./BeneficiaryPersonalDeatils"
import { createFullName, titleCase } from "../../../utils/helper"
import fileDownload from "js-file-download"
import { callApiAction } from "../../../store/actions/commonAction"
import { Download } from "@mui/icons-material"
import { downloadBeneficiaryReport } from "../../../apis/beneficiary.api"
const DownloadReportComponent = ({ params }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const onClick = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await downloadBeneficiaryReport({ id: params._id }),
            (response) => {
                fileDownload(response, params.name + ".pdf")
                setLoading(false)
            },
            (err) => {
                console.log(err)
                setLoading(false)
            },
            true
        ))
    }
    if (loading)
        return <CircularProgress size={25} />
    return <IconButton onClick={onClick} >
        <Download color="primary" />
    </IconButton>
}
const DetailedViewMain = ({ data, loading, setData, modalKey, fetchFollowUps, followupLoading }) => {
    const { user } = useSelector(state => state)
    const allowedModules = user.data.modules ?? []
    const [tab, setTab] = useState(0)
    const handleTabChange = (e, newVal) => {
        setTab(newVal)
    }
    return <>
        <CustomDialog
            id={modalKey}
            loading={loading}
            title={`Beneficiary Details` + `(${!loading ? titleCase(createFullName(data.surname, data.name)):"Loading..."})`}
            closeText="Close"

        >
            <Tabs
                sx={{ width: '100%', position: "sticky", top: "0px", zIndex: 111, background: "white", borderBottom: 1, borderColor: "divider" }}
                scrollButtons
                allowScrollButtonsMobile
                value={tab}
                onChange={handleTabChange}
            >
                <Tab label="Beneficiary Details" value={0} />
                <Tab label="Hearing Details" value={1} />
                <Tab label="Followups" value={2} />
                {allowedModules.includes(MODULES.BENIFICIARY_LOGS) && <Tab label="Logs" value={3} />}
                {!loading && data && data['_id'] && allowedModules.includes(MODULES.BENIFICIARY_VALIDATE) && <BeneficiaryValidateButton value={4} status={data?.status} type="tab" />}
            </Tabs>
            {loading ? <CenteredBox><CircularProgress size={30} /></CenteredBox> :
                <Box mt={2}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }} mb={3}>
                     {data && data['_id'] &&   <DownloadReportComponent params={data} />}
                        <BeneficiaryUpdateButton id={data?._id} status={data?.status} type="icon" />
                    </Box>
                    {
                        tab == 0 && <BeneficiaryPersonalDeatils data={data} setData={setData} />
                    }
                    {
                        tab == 1 && <HearingDetails data={data} />
                    }
                    {
                        tab == 2 && <FollowUpDetails setData={setData} loading={followupLoading} fetchFollowUps={fetchFollowUps} data={data} />
                    }
                    {
                        tab == 3 && <LogDetails data={data} />
                    }
                    {
                        tab == 4 && <BeneficiaryValidateButton setTab={setTab} status={data?.status} id={data?._id} params={data} setParams={setData} data={data} />
                    }
                </Box>}
        </CustomDialog>
    </>
}
export default DetailedViewMain