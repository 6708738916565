import { Autocomplete, CircularProgress, } from "@mui/material"

import {  memo } from "react"
import { useSelector } from 'react-redux'



import CustomInput from "../../components/inputs/CustomInput"

import {  USER_ROLES } from "../../utils/constants"
import {  customizeVideoUrl, findObjectKeyByValue, titleCase } from "../../utils/helper"

import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"


const CreateUi = ({ title, modalKey, isUpdate, fields, setFields, loading, onSubmit }) => {
    const { user } = useSelector(state => state)

    return <>
{fields.allowed_role == USER_ROLES.SIT_TEAM &&
        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    
                       {<CustomInput
                     
                        disabled={loading}
                        value={fields.videos}
                        onChange={(e) => setFields({ ...fields, err: '', videos: e.target.value })}
                        type="text"
                        label={"Video Url*"}

                    />}
                  

                  {fields.videos && fields.videos!='' &&<iframe style={{ width: "100%", minHeight: "300px", maxHeight: "500px" }} src={customizeVideoUrl(fields.videos)} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>}

                   

                </>}

        </CustomDialog>
}
    </>
}
export default memo(CreateUi)