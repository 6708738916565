import { useState } from "react"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { useDispatch } from "react-redux"
import { closeModal } from "../../../store/actions/modalAction"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { callApiAction } from "../../../store/actions/commonAction"
import { updateBeneficiaryVideoUrlApi, updateVideoUrlApi } from "../../../apis/beneficiary.api"
import CustomInput from "../../../components/inputs/CustomInput"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants"

const UpdateVideoUrlModal = ({ url = "",video_id,followup_id, callBack = () => { } }) => {

    const modalId = 'update-video'
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [fields, setFields] = useState({
        url,
        video_id,followup_id,
        err: ''
    })

    const onClose = () => {
        dispatch(closeModal(modalId))
    }
    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        dispatch(callApiAction(
            async () => await updateVideoUrlApi({ ...fields }),
            (response) => {

                dispatch(callSnackBar("Video Url Updated successfully.",SNACK_BAR_VARIETNS.suceess))
                setLoading(false)
                onClose()
                callBack(fields)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

    return <CustomDialog
        id={modalId}

        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${'UpdateVideo Url'}`}
        onClose={onClose}
        closeText={"Close"}
        confirmText={'Update'}
    >
        <CustomInput
            autoFocus={true}
            disabled={loading}
            value={fields.url}
            onChange={(e) => setFields({ ...fields, err: '', url: e.target.value })}
            type="url"
            label={"Video Url"}

        />
    </CustomDialog>
}
export default UpdateVideoUrlModal