import { Autocomplete, Box, Grid, MenuItem, Paper, Skeleton, Typography, styled, useTheme } from "@mui/material"

import { Bar, Pie } from "react-chartjs-2"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import Counts from "./Counts"
import { formatNumberCustomPattern } from "../../utils/helper"

const HearableCounts = ({ list, loading, onBeneficiaryRedirectsClicks }) => {
    const theme = useTheme()
    const labels = ["Able To hear with HearNU", "Not able To Hear with HearNu"];
    const finalData = [0, 0]

    console.log("List ",list);
    if (list && Array.isArray(list) && list.length > 0) {
        for (let item of list) {
            if (item._id) {
                finalData[0] = item.count
            } else {
                finalData[1] = item.count
            }

        }
    }
    const options = {



    }

    const data = {
        labels,
        datasets: [
            {
                // label: 'Total Beneficiary',
                data: finalData,
                // backgroundColor: theme.palette.primary.main,
                // barPercentage:1,
                // base:0
            }
        ],
    };


    if (loading) {
        return (
            <>
                {[0, 1, 2, 3].map((item) => <Grid item xs={6} md={3} key={item}>

                    <Paper component={Box} p={2}>
                        <Skeleton variant="rounded" height="120px" width="100%" >

                        </Skeleton>
                    </Paper>
                </Grid>)}


            </>)
    }
    if (!(list && Array.isArray(list) && list.length > 0)) {
        return <></>
    }
    return <>
        <Grid item xs={6} md={3}>
            <Counts onClick={(e)=>onBeneficiaryRedirectsClicks(e,null,null)} title="Total" subtitle=" Beneficiary Tested" count={formatNumberCustomPattern(finalData[0] + finalData[1])} />

        </Grid>
        <Grid item xs={6} md={3}>
            <Counts onClick={(e)=>onBeneficiaryRedirectsClicks(e,true,null)} title="Able To Hear" subtitle="With Device" count={formatNumberCustomPattern(finalData[0])} />
        </Grid>
        <Grid item xs={6} md={3}>
            <Counts onClick={(e)=>onBeneficiaryRedirectsClicks(e,false,null)} title="Not Able To Hear" subtitle="With Device" count={formatNumberCustomPattern(finalData[1])} />
        </Grid>
        <Grid item xs={6} md={3}>
            <Counts title="Success Ratio" subtitle="Of Device" count={(finalData[0] * 100 / (finalData[0] + finalData[1]))} unit={'%'} />
        </Grid>
    </>
}
export default HearableCounts