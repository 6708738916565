import MODULES from "./module.constant";

export const getDefaultRedirect = (modules, role) => {

    if(modules && Array.isArray(modules) && modules.includes(MODULES.DASHBOARD)){
       return '/dashboard/'
    }

    if(modules && Array.isArray(modules) && modules.includes(MODULES.BENIFICIARY_ADD)){
        return '/beneficiary/beneficiary-list/'
     }
     
    if(modules && Array.isArray(modules) && modules.includes(MODULES.BENIFICIARY_VALIDATE)){
      return '/beneficiary/beneficiary-list/'
   }
     if(modules && Array.isArray(modules) && modules.includes(MODULES.BENIFICIARY_TEST)){
        return '/beneficiary/beneficiary-list/'
     }
     if(modules && Array.isArray(modules) && modules.includes(MODULES.BENIFIICIARY_DONATIONS)){
        return '/beneficiary/genral-list/'
     }
     if(modules && Array.isArray(modules) && modules.includes(MODULES.EVENT_MODULE)){
        return '/events/'
     }
     if(modules && Array.isArray(modules) && modules.includes(MODULES.FOLLOWUP_LIST)){
        return '/followups/'
     }
     return '/'
}