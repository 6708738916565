import { Autocomplete, Box, Button, ButtonBase, CircularProgress, Grid, LinearProgress, MenuItem, Paper, Typography, styled } from "@mui/material"
import { Fragment, memo, useCallback, useState } from "react"

import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import FileInput from "../../../components/inputs/FileInput"
import { Delete, HideImageOutlined, Upload } from "@mui/icons-material"
import { toTitleCase } from "../../../utils/helper"
import ImageComponent from "../../../components/ImageComponent"
import { deleteCampImagesField } from "../../../apis/camp.api"
import { useDispatch } from "react-redux"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants"
import { callApiAction } from "../../../store/actions/commonAction"
// import ImageComponent from "../../../components/inputs/ImageComponent"
// import ImageComponent from "../../../components/inputs/ImageComponent"
// import ImageComponent from "../../../components/inputs/ImageComponent"

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const ImageUploadComponent = memo(({ loading, onChange, percentage, onDeleteFile, files, name, camp_id }) => {
    const dispatch = useDispatch()
    const [deleteLoading, setDeleteLoading] = useState(false)


    const deleteRecordFromDb = async (callBack = () => { }) => {
        dispatch(
            callApiAction(
                async () => await deleteCampImagesField({ camp_id, image_name: name }),
                async (response) => {

                    callBack(response)


                },
                (err) => {

                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    callBack()
                }
            )
        )
    }

    const onDelete = () => {
        setDeleteLoading(true)
        onDeleteFile(files[0], () => {
            deleteRecordFromDb(
                () => { setDeleteLoading(false) }
            )

        })
    }



    return <Box component={Paper} sx={{ width: "100%", background: "#e5e5e5" }} p={2}>
        <Typography variant="subtitle2" lineHeight="100%" >{toTitleCase(name?.replace(/_/g, " "))}</Typography>
        <Box component={Paper} elevation={0} mt={1} mb={1} sx={{ display: "flex", width: "100%", position: 'relative', paddingTop: '75%', border: "1px solid gray" }}>
            <Box sx={{ position: "absolute", top: "0px", left: "0px", right: "0px", width: "100%", height: "100%" }}>
                {(files[0] && !loading && !deleteLoading) ?
                    <ImageComponent imgStyle={{ height: "100%", width: "100%", objectFit: "contain" }} src={files[0]} />
                    :
                    <CenteredBox>
                        {loading ?
                            <LinearProgress sx={{ zIndex: 1111, width: "100%" }} variant="determinate" value={percentage} />
                            :
                            deleteLoading ?
                                <CircularProgress />
                                : <HideImageOutlined />
                        }
                    </CenteredBox>}


            </Box>
        </Box>
        <Box>

            <Box>
                {!files[0] && <Button component="label" mt={2} startIcon={<Upload />} disableElevation disabled={loading || deleteLoading} variant="contained" fullWidth >
                    {/* <Upload />Upload */}
                    Upload
                    <VisuallyHiddenInput accept="image/*" onChange={onChange} type="file" />
                </Button>}

                {files[0] && <Button onClick={onDelete} component="label" color="error" mt={2} startIcon={<Delete />} disableElevation disabled={loading || deleteLoading} variant="contained" fullWidth >
                    {/* <Upload />Upload */}
                    Delete

                </Button>}
            </Box>

        </Box>
    </Box>
})

const CreateUi = ({ title, modalKey, createFunction, fetchLoading, isUpdate, fields, setFields, loading, onSubmit }) => {

    return <>

        <CustomDialog
            id={modalKey}
            loading={loading || fetchLoading}
            err={fields.err}

            title={`${isUpdate ? "Update Images " : "Create"} ${title}`}

        >

            {(loading || fetchLoading) && <CenteredBox><CircularProgress /></CenteredBox>}
            {!fetchLoading &&
                <>

                    <Grid container spacing={2}>
                        {
                            fields.images?.map?.((item) => <Grid item key={item.name} xs={6} md={4} >
                                {/* {console.log(item)} */}
                                <FileInput
                                    crop
                                    onlyImage
                                    noCompression
                                    defaults={item.url ? [item.url] : undefined}
                                    componentProps={{
                                        name: item?.name,
                                        url: item?.url,
                                        camp_id: fields.camp_id
                                    }}
                                    onChange={(newUrl) => {
                                        createFunction(item.name, newUrl)
                                    }}
                                    FileComponent={ImageUploadComponent}


                                />
                            </Grid>)
                        }
                    </Grid>



                </>}

        </CustomDialog>


    </>
}
export default memo(CreateUi)