import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"
import CloseCampUi from "./CloseCampUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"

import { closeModal } from "../../../store/actions/modalAction"

import { addCampApi, closeCampApi, createCampStaffApi, getCampByIdApi } from "../../../apis/camp.api"
import { toTitleCase } from "../../../utils/helper"
import moment from "moment"
import { CAMP_STATUS } from "../../../utils/beneficiary.constant"

const CloseCampController = ({ callBack=()=>{}, id, name ,start_date}) => {
    const validate = useValidate()
    const dispatch = useDispatch()

    const modalKey = 'camp-close'
    const title = "Close "+ toTitleCase(name)+ " Camp"

    const closeApi = closeCampApi


    const [loading, setLoading] = useState(false)

    const [fetchLoading, setFetchLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        end_date:moment().toISOString(),
        start_date



    })



    const validationSchemaCreate = useMemo(() => ([

      
    ]), [fields])





    const closeFun = async () => {
        const validationResponse = validate(validationSchemaCreate)
        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await closeApi({ ...fields }),
                    async (response) => {

                        await callBack({status:CAMP_STATUS.CLOSED})
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }





    const onSubmit = async (e) => {
        e.preventDefault()
        closeFun()


    }





    return <CloseCampUi modalKey={modalKey} title={title} fetchLoading={fetchLoading} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CloseCampController)