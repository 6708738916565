import { Autocomplete, Box, Grid, MenuItem, Paper, Skeleton, Typography, styled, useTheme } from "@mui/material"
// import  handleDownloadExcel from "./HandleDownloadExcel"
// import handleDownloadExcel from "./HandleDownloadExcel"
// import HandleDownloadExcel from "./DashboardUi"
import Counts from "./Counts"
import { formatNumberCustomPattern, formatNumberWithCommas } from "../../utils/helper"

const DonatedDeviceCOunts = ({ donatedList,totalFollowup, followUpList, loading,onVideosClick,onFollowupsClicks,onAvgUsageClick,onBeneficiaryRedirectsClicks }) => {
    const theme = useTheme()

    let donatedData = donatedList

    const counts = [0, 0, 0]

    // if (donatedList && Array.isArray(donatedList) && donatedList.length > 0) {
    //     for (let item of donatedList) {
    //         donatedData += item.count

    //     }
    // }
    // console.log("FIEEKE",followUpList);
    if (followUpList && Array.isArray(followUpList) && followUpList.length > 0) {
        const data = followUpList[0]

        counts[0] = data?.count ?? 0
        counts[1] = data?.avg_admin_approved_videos_per_beneficiary ?? 0
        counts[2] = (data?.benificiary_avg_device_usage * data?.benificiary_avg_device_usage_weekly) ?? 0

    }



    if (loading) {
        return (
            <>
                {[0, 1, 2, 3].map((item) => <Grid item xs={6} md={3} key={item}>

                    <Paper component={Box} p={2}>
                        <Skeleton variant="rounded" height="120px" width="100%" >

                        </Skeleton>
                    </Paper>
                </Grid>)}


            </>)
    }
  
    return <>
        <Grid item xs={6} md={3}>
            <Counts onClick={(e)=>onBeneficiaryRedirectsClicks(e,true,true)}  title="Device Donated"  count={formatNumberCustomPattern(donatedData)} />

        </Grid>
        {/* <Grid item xs={6} md={3}>
            <Counts onClick={onFollowupsClicks} title="Followups Initiated" count={counts[0]} />
        </Grid> */}
        <Grid item xs={6} md={3}>
            <Counts onClick={onFollowupsClicks} title="Total Followups" count={formatNumberCustomPattern(totalFollowup)} />
        </Grid>
        <Grid item xs={6} md={3}>
            <Counts onClick={onVideosClick} title="Benificiary Videos" count={formatNumberCustomPattern(counts[1])} />
        </Grid>
        <Grid item xs={6} md={3}>
            <Counts title="Avg Weekly Usage" onClick={onAvgUsageClick} count={counts[2]} unit="H" />
        </Grid>

    </>
}
export default DonatedDeviceCOunts