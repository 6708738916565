import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";




export const getRegionApi = async params => {
  const callResponse = await axios({
    url: endpoints.region,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addRegionApi = async data => {
  const callResponse = await axios({
    url: endpoints.region,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const deleteRegionField = async data => {
  const callResponse = await axios({
    url: endpoints.region,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateRegionApi = async data => {
  const callResponse = await axios({
    url: endpoints.region,
    method: "PATCH",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getRegionByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.regionById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
