import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal, openModal } from "../../store/actions/modalAction"

import { Box, Button, Chip, CircularProgress, IconButton, Switch, Tooltip, Typography } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./CreateController"
import { Add, AddCircle, AddPhotoAlternate, AddRounded, CheckCircle, Delete, Edit, Info } from "@mui/icons-material"
import MessageDilog from "../../components/MessageDilog"

import moment from "moment"
import { getEventApi } from "../../apis/event.api"
import DetailedViewController from "./detailedview/DetailedViewController"
import AddBeneficiaryToEventController from "./AddBeneficiaryToEventController"
import { BENIFICIARY_STATUS, EVENT_STATUS } from "../../utils/beneficiary.constant"
import CloseEventController from "./CloseEventController"
import AddImagesToEventController from "./AddImagesToEventController"
import { findObjectKeyByValue } from "../../utils/helper"
import MODULES from "../../utils/module.constant"

const ActionComponent = memo(({ params, setParams, modalKey }) => {
    const { user } = useSelector(state => state)
    const allowedModules = user.data.modules ?? []
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)


    const onViewMore = () => {
        dispatch(openModal(<DetailedViewController
            id={params._id}



        />, "md", false, "event-detailed-view"))


    }
    const onEdit = () => {
        dispatch(openModal(<CreateController
            id={params._id}
        />, "md", false, "event"))
    }

    const onAddImage = () => {
        dispatch(openModal(<AddImagesToEventController
            id={params._id}
        />, "md", false, "event-images-attach"))
    }

    const onCloseEvent = () => {
        dispatch(openModal(<CloseEventController
            id={params._id}
            callBack={(res) => { setParams({ ...params, ...res }) }}


        />, "md", true, "event-close"))
    }



    if (loading) {
        return <CircularProgress />
    }
    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <Tooltip title="View Details">
            <IconButton size="inherit" onClick={onViewMore}>
                <Info color="info" fontSize="inherit" />
            </IconButton>
        </Tooltip>
        {allowedModules.includes(MODULES.EVENT_CREATE_UPDATE) && <>
            <Tooltip title="Edit Details">
                <IconButton size="inherit" onClick={onEdit}>
                    <Edit color="info" fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <Tooltip title="Add Images">
                <IconButton size="inherit" onClick={onAddImage}>
                    <AddPhotoAlternate color="info" fontSize="inherit" />
                </IconButton>
            </Tooltip>
        </>}
        {params.status != EVENT_STATUS.CLOSED && allowedModules.includes(MODULES.EVENT_CREATE_UPDATE) && <IconButton size="inherit" onClick={onCloseEvent}>
            <CheckCircle color="success" fontSize="inherit" />
        </IconButton>}



    </Box>
})


const AddBeneficiaryComponent = memo(({ params, setParams, modalKey }) => {
    const { user } = useSelector(state => state)
    const allowedModules = user.data.modules ?? []
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)



    const onBeneficiaryAdd = () => {
        dispatch(openModal(<AddBeneficiaryToEventController
            id={params._id}
            callBack={(count) => { setParams({ ...params, beneficiaries: params.beneficiaries + count }) }}


        />, "md", true, "event-beneficiary-attach"))
    }

    if (loading) {
        return <CircularProgress />
    }
    return <Box sx={{ width: "100%", alignItems: "center", display: "flex" }}>
        {params.beneficiaries}
        {params.status != EVENT_STATUS.CLOSED && allowedModules.includes(MODULES.EVENT_BENIFICIARY_ASSIGN) && <Tooltip title="Add Beneficiaries">
            <IconButton variant="contained" size="inherit" onClick={onBeneficiaryAdd}>

                <AddCircle color="primary" />
            </IconButton>
        </Tooltip>}

    </Box>
})



const ListController = () => {
    const dispatch = useDispatch()



    const title = "Events "
    const modalKey = 'event'
    const fetchApi = getEventApi



    const columns = useMemo(() => [
        { id: 1, fieldName: 'date', label: 'Event Date', align: "left", sort: true, renderValue: (params, setParams) => moment(params.date).format("DD/MM/YYYY") },
        { id: 2, fieldName: 'name', label: 'Event Name', align: "left", sort: true },
        {
            id: 3, fieldName: 'status', label: 'Status', align: "left", sort: true,
            renderValue: (params, setParams) => <Chip
                size="small"
                color={params.status == EVENT_STATUS.CLOSED ? "success" : "warning"}
                label={params.status == EVENT_STATUS.CLOSED ? "Completed" : "Not Completed"}
            />
        },
        {
            id: 4,

            fieldName: 'action',
            minWidth: '200px',
            label: 'Total Beneficiaries',
            align: "left",
            renderValue: (params, setParams) => <AddBeneficiaryComponent modalKey={modalKey} params={params} setParams={setParams} />
        },
        {

            id: 5,
            fieldName: '',
            label: 'Action',

            align: "right",
            renderValue: (params, setParams) => <ActionComponent modalKey={modalKey} params={params} setParams={setParams} />
        },

    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['name'],
        sort: 'createdAt',

        sortDirection: -1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController callBack={async () => { fetchList() }} />, "sm", false, modalKey))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                title={title}
                onCreateBtnClick={onCreateBtnClick}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController