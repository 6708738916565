import { OpenInNew } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  ListItem,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SubmitButton from "../../components/button/SubmitButton";
import {
  StyledSearchBar,
  UserSearchBarNormal,
} from "../../components/inputs/SearchBar";

import { REGIONS_TYPE, USER_ROLES } from "../../utils/constants";
import DataTable from "../../components/tables/DataTable";
import {
  findObjectKeyByValue,
  titleCase,
  toTitleCase,
} from "../../utils/helper";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { getRegionApi } from "../../apis/region.api";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ListSummaryUi = ({
  title,
  filters,
  setFilters,
  list,
  roles,
  loading,
  onCreateBtnClick,
  columns,
}) => {
  const user = useSelector((state) => state.user);
  console.log("FILTEREEE", filters);
  console.log("COLUMS", columns);
  console.log("LOIIIIISEEE", list);
  const result = [list];
  // const result = []
//   result.push({items:"jide",row:"lope",kid:"hdjdj"});
  // const [result, setResult] = useState([]);
  // useEffect(() => {
  //     setResult(prevResult =>[...prevResult,list]);
  // }, [list]);

  // console.log("PPPPO", result);
  // console.log("PPPPO",result)

    const [previousData, setPreviousData] = useState([]);

  useEffect(() => {
      if (list && list.length > 0) {
          console.log("PLOSTDE",list)
          setPreviousData(prevData => [...prevData, {...list}])
      }
  }, [filters,list])
  console.log("LIST", list);
  console.log("RESUKTD",result)
  // const [previousData, setPreviousData] = useState([list]);

  // useEffect(() => {
  //   if (list) {
  //       // const timestamp = new Date().toISOString(); // Capture current timestamp
  //    const existingData = JSON.parse(localStorage.getItem("previousData")) || [];
  //    console.log("EXISTING ",existingData)
  //     setPreviousData([...existingData,list]);
  //     localStorage.setItem("previousData", JSON.stringify([...existingData, { ...list }]));
  //   }
  // }, [filters, list.time]);

  const newFilter = { ...filters, ...result };
  // console.log("Previous Data", previousData);
  // const newFilter = {...filters,previousData}
  console.log("NEW FITKERS", newFilter);

  return (
    <>
      <Box mb={3}>
        <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
          <Box mb={4}>
            <FilterTitleBox>
              <Typography variant="h5" mb={2}>
                {title}
              </Typography>

              {/* <Box mb={2}>
                                <SubmitButton variant="contained" onClick={onCreateBtnClick} title={"Add Document"} />
                            </Box> */}
            </FilterTitleBox>
          </Box>

          <Box sx={{ minHeight: "300px" }} className="table">
            {/* {console.log("LISTEEEE",list?.hearing_abilty?.map((item)=>item?.count))} */}
            <DataTable
              columns={columns}
              rows={newFilter.result ? newFilter.result : []}
              count={newFilter?.result?.length ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default ListSummaryUi;
