import { memo, useEffect, useMemo, useState } from "react"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { getUserByIdApi } from "../../apis/user.api"
import UserProfileViewCommon from "./UserProfileViewCommonUI"

const UserProfileViewCommonController = ({ id, getFun,role }) => {

    const dispatch = useDispatch()


    const title = " details"
    const modalKey = 'users-modal'
    const getByIdApi = getFun ?? getUserByIdApi

    const [loading, setLoading] = useState(false)



    const [data, setData] = useState({})





    const fetchById = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {


                    setData({ ...response })

                    setLoading(false)

                },
                (err) => {

                    setLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])


    return <UserProfileViewCommon modalKey={modalKey} role={role} title={title} loading={loading} data={data} />
}
export default memo(UserProfileViewCommonController)