// import { Box, Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
// import { geoCentroid } from 'd3-geo';
// import React, { useEffect, useState } from 'react';
// import { Annotation, ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';

// import 'react-tooltip/dist/react-tooltip.css'
// import { callApiAction } from '../../store/actions/commonAction';
// import { getCountsByRegionBeneficiaryApi } from '../../apis/beneficiary.api';
// import { REGIONS_TYPE } from '../../utils/constants';
// import { useDispatch } from 'react-redux';
// import { CenteredBox } from '../../components/layouts/OneViewBox';
// import { useTheme } from "@mui/material";
// import NoDataComponent from '../../components/layouts/NoDataComponent';
// import { Map } from '@mui/icons-material';
// import { openModal } from '../../store/actions/modalAction';
// import ListOfBeneficiaryByRegionController from '../beneficiary/view/ListOfBeneficiaryByRegionController';
// import ListOfBenificiaryModal from '../beneficiary/view/ListOfBenificiaryModal';

// const INDIA_TOPO_JSON = require('./india.topo.json');

// const PROJECTION_CONFIG = {
//     scale: 400,
//     center: [78.9629, 22.5937] // always in [East Latitude, North Longitude]
// };



// const DEFAULT_COLOR = '#9fb1e8';



// const getHeatMapData = () => {
//     return [
//         { id: 'AP', state: 'Andhra Pradesh', value: undefined },
//         { id: 'AR', state: 'Arunachal Pradesh', value: undefined },
//         { id: 'AS', state: 'Assam', value: undefined },
//         { id: 'BR', state: 'Bihar', value: undefined },
//         { id: 'CT', state: 'Chhattisgarh', value: undefined },
//         { id: 'GA', state: 'Goa', value: undefined },
//         { id: 'GJ', state: 'Gujarat', value: undefined },
//         { id: 'HR', state: 'Haryana', value: undefined },
//         { id: 'HP', state: 'Himachal Pradesh', value: undefined },
//         { id: 'JH', state: 'Jharkhand', value: undefined },
//         { id: 'KA', state: 'Karnataka', value: undefined },
//         { id: 'KL', state: 'Kerala', value: undefined },
//         { id: 'MP', state: 'Madhya Pradesh', value: undefined },
//         { id: 'MH', state: 'Maharashtra', value: undefined },
//         { id: 'MN', state: 'Manipur', value: undefined },
//         { id: 'ML', state: 'Meghalaya', value: undefined },
//         { id: 'MZ', state: 'Mizoram', value: undefined },
//         { id: 'NL', state: 'Nagaland', value: undefined },
//         { id: 'OD', state: 'Odisha', value: undefined },
//         { id: 'PB', state: 'Punjab', value: undefined },
//         { id: 'RJ', state: 'Rajasthan', value: undefined },
//         { id: 'SK', state: 'Sikkim', value: undefined },
//         { id: 'TN', state: 'Tamil Nadu', value: undefined },
//         { id: 'TS', state: 'Telangana', value: undefined },
//         { id: 'TR', state: 'Tripura', value: undefined },
//         { id: 'UK', state: 'Uttarakhand', value: undefined },
//         { id: 'UP', state: 'Uttar Pradesh', value: undefined },
//         { id: 'WB', state: 'West Bengal', value: undefined },
//         { id: 'WB', state: 'West Bengal', value: undefined },
//         { id: 'AN', state: 'Andaman and Nicobar Islands', value: undefined },
//         { id: 'CH', state: 'Chandigarh', value: undefined },
//         { id: 'DN', state: 'Dadra and Nagar Haveli', value: undefined },
//         { id: 'DD', state: 'Daman and Diu', value: undefined },
//         { id: 'DL', state: 'Delhi', value: undefined },
//         { id: 'JK', state: 'Jammu and Kashmir', value: undefined },
//         { id: 'LA', state: 'Ladakh', value: undefined },
//         { id: 'LD', state: 'Lakshadweep', value: undefined },
//         { id: 'PY', state: 'Puducherry', value: undefined }
//     ];
// };

// const geographyStyle = {
//     default: {
//         outline: 'none',
//         // fill: "gray"
//     },
//     hover: {
//         fill: '#1A44AD',
//         transition: 'all 250ms',
//         outline: 'none'
//     },
//     pressed: {
//         outline: 'none',
//         fill: '#9fb1e8',
//     }
// };




// function IndiaMap() {

//     const theme = useTheme()

//     const dispatch = useDispatch()
//     const [tooltipContent, setTooltipContent] = useState('');
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(true)

//     const [filters, setFilters] = useState({
//         pageNo: 1,
//         pageSize: 10,
//         search: '',
//         searchable: ['name', 'udise_no'],
//         sort: 'region.name',
//         type: REGIONS_TYPE.STATE,
//         parent: '6583fee000b86495ea8c8066',
//         sortDirection: 1,
//         validated: true

//     })

//     const fetchList = () => {
//         setLoading(true)
//         dispatch(callApiAction(
//             async () => await getCountsByRegionBeneficiaryApi({ ...filters }),
//             (response) => {

//                 const heatMapData = getHeatMapData()
//                 const obj = {}
//                 heatMapData.forEach(item => {
//                     obj[item.id] = {
//                         ...item, value: {
//                             name: item.state,
//                             active: false,
//                             donated: 0,
//                             not_donated: 0
//                         }
//                     }
//                 })
//                 response?.forEach((item) => {
//                     if (item?.region?.short_name) {
//                         obj[item?.region?.short_name] = {
//                             id: item?.region?.short_name,
//                             active: true,
//                             state: item.region?.name,
//                             value: {
//                                 state_id: item.region?._id,
//                                 name: item.region?.name,
//                                 donated: item.donated?.find((val) => val.donated)?.count ?? 0,
//                                 not_donated: item.donated?.find((val) => !val.donated)?.count ?? 0
//                             }
//                         }
//                     }
//                 })

//                 setData(Object.values(obj))
//                 setLoading(false)
//             },
//             (err) => {
//                 setLoading(false)
//             }
//         ))
//     }



//     useEffect(() => {
//         fetchList()
//     }, [])

//     const onMouseEnter = (geo, current = { value: 'NA' }) => {
//         return () => {

//             // setTooltipContent(`${geo.properties.name}: ${current.value}`);
//         };
//     };

//     const onMouseLeave = () => {
//         // setTooltipContent('');
//     };

//     const onClick = (value) => {
//         setTooltipContent(value);
//     };

//     const onViewDetailsCLick = (state_id,name) => {
//         dispatch(openModal(
//             <ListOfBenificiaryModal modal_title={name} modal_id={'region-' + state_id} parent={state_id} type={REGIONS_TYPE.DISTRICT} />, "md", false, 'region-' + state_id
//         ))
//     }

//     if (loading) {
//         return <CenteredBox sx={{ minHeight: "300px" }}  className="table" >
//             <CircularProgress />
//         </CenteredBox>
//     }
//     return (
//         <Box sx={{ position: "relative" }} >

//             <Grid container >
//                 <Grid item xs={12} sm={9}>
//                     {data && data.length > 0 &&
//                         <ComposableMap
//                             projectionConfig={PROJECTION_CONFIG}
//                             projection="geoMercator"
//                             width={600}
//                             height={220}
//                             data-tip=""
//                         >
//                             <Geographies geography={INDIA_TOPO_JSON}>
//                                 {({ geographies }) => geographies.map(geo => {


//                                     const current = data.find(s => s.id === geo.id);

//                                     return (


//                                         <Geography
//                                             key={geo.rsmKey}
//                                             geography={geo}
//                                             fill={current?.active ? "#1A44AD" : DEFAULT_COLOR}
//                                             style={geographyStyle}
//                                             onClick={(e) => {

//                                                 onClick(current?.value)
//                                             }}
//                                             glyphName={"s"}
//                                             onMouseEnter={onMouseEnter(geo, current)}
//                                             onMouseLeave={onMouseLeave}
//                                         >

//                                         </Geography>

//                                     );
//                                 })


//                                 }

//                             </Geographies>

//                         </ComposableMap>
//                     }
//                 </Grid>
//                 <Grid item xs={12} sm={3}>

//                     <Box p={2} sx={{ borderLeft: "1px solid " + theme.palette.grey.main, height: "100%" }} >
//                         {tooltipContent ? <Box p={2} >
//                             <Typography variant='h6' align='center' color="primary" fontWeight={600} >
//                                 {tooltipContent.name}
//                             </Typography>

//                             <Box sx={{ display: "flex" }} mt={3} mb={3}>
//                                 <Box sx={{ display: "flex", flex: 1, flexDirection: "column", border: "1px solid gray", borderRadius: 1 }} p={2}>
//                                     <Typography fontWeight={800} color="primary" variant='subtitle1' align='center' >
//                                         {tooltipContent.donated}
//                                     </Typography>
//                                     <Typography variant='subtitle2' align='center' color="gray" >
//                                         Donated
//                                     </Typography>
//                                 </Box>
//                                 <Box ml={2} sx={{ display: "flex", flex: 1, flexDirection: "column", border: "1px solid gray", borderRadius: 1 }} p={2}>
//                                     <Typography fontWeight={800} color="primary" variant='subtitle1' align='center' >
//                                         {tooltipContent.not_donated}
//                                     </Typography>
//                                     <Typography variant='subtitle2' align='center' color="gray" >
//                                         Yet To   Donate
//                                     </Typography>
//                                 </Box>
//                             </Box>

//                             {tooltipContent.state_id && <Button onClick={() => { onViewDetailsCLick(tooltipContent.state_id,tooltipContent.name) }} disableElevation variant='contained' fullWidth >
//                                 View Details
//                             </Button>}
//                         </Box> :
//                             <CenteredBox>
//                                 <NoDataComponent Icon={Map} message="Select State" />
//                             </CenteredBox>
//                         }
//                     </Box>
//                 </Grid>
//             </Grid>

//         </Box>
//     );
// }

// export default IndiaMap;

// // New Code:-

import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import 'react-tooltip/dist/react-tooltip.css';
import { callApiAction } from '../../store/actions/commonAction';
import { getCountsByRegionBeneficiaryApi } from '../../apis/beneficiary.api';
import { REGIONS_TYPE } from '../../utils/constants';
import { useDispatch } from 'react-redux';
import { CenteredBox } from '../../components/layouts/OneViewBox';
import { useTheme } from "@mui/material";
import NoDataComponent from '../../components/layouts/NoDataComponent';
import { Map } from '@mui/icons-material';
import { openModal } from '../../store/actions/modalAction';
import ListOfBenificiaryModal from '../beneficiary/view/ListOfBenificiaryModal';

const INDIA_TOPO_JSON = require('./india.topo.json');

const PROJECTION_CONFIG = {
    scale: 400,
    center: [78.9629, 22.5937] // always in [East Latitude, North Longitude]
};

const DONATED_COLOR = '#C1F2B0'; // Green
const NOT_DONATED_COLOR = '#F7B787'; // Orange
const DEFAULT_COLOR = '#9FB1e8'; // Default color for both donated and not donated
const DONATED_HOVER_COLOR = '#65B741'; // Green hover
const NOT_DONATED_HOVER_COLOR = '#EE7214'; // Orange hover
const DEFAULT_HOVER_COLOR = '#1A44AD'; // Default hover color

const getHeatMapData = () => {
        return [
            { id: 'AP', state: 'Andhra Pradesh', value: undefined },
            { id: 'AR', state: 'Arunachal Pradesh', value: undefined },
            { id: 'AS', state: 'Assam', value: undefined },
            { id: 'BR', state: 'Bihar', value: undefined },
            { id: 'CT', state: 'Chhattisgarh', value: undefined },
            { id: 'GA', state: 'Goa', value: undefined },
            { id: 'GJ', state: 'Gujarat', value: undefined },
            { id: 'HR', state: 'Haryana', value: undefined },
            { id: 'HP', state: 'Himachal Pradesh', value: undefined },
            { id: 'JH', state: 'Jharkhand', value: undefined },
            { id: 'KA', state: 'Karnataka', value: undefined },
            { id: 'KL', state: 'Kerala', value: undefined },
            { id: 'MP', state: 'Madhya Pradesh', value: undefined },
            { id: 'MH', state: 'Maharashtra', value: undefined },
            { id: 'MN', state: 'Manipur', value: undefined },
            { id: 'ML', state: 'Meghalaya', value: undefined },
            { id: 'MZ', state: 'Mizoram', value: undefined },
            { id: 'NL', state: 'Nagaland', value: undefined },
            { id: 'OD', state: 'Odisha', value: undefined },
            { id: 'PB', state: 'Punjab', value: undefined },
            { id: 'RJ', state: 'Rajasthan', value: undefined },
            { id: 'SK', state: 'Sikkim', value: undefined },
            { id: 'TN', state: 'Tamil Nadu', value: undefined },
            { id: 'TS', state: 'Telangana', value: undefined },
            { id: 'TR', state: 'Tripura', value: undefined },
            { id: 'UK', state: 'Uttarakhand', value: undefined },
            { id: 'UP', state: 'Uttar Pradesh', value: undefined },
            { id: 'WB', state: 'West Bengal', value: undefined },
            { id: 'WB', state: 'West Bengal', value: undefined },
            { id: 'AN', state: 'Andaman and Nicobar Islands', value: undefined },
            { id: 'CH', state: 'Chandigarh', value: undefined },
            { id: 'DN', state: 'Dadra and Nagar Haveli', value: undefined },
            { id: 'DD', state: 'Daman and Diu', value: undefined },
            { id: 'DL', state: 'Delhi', value: undefined },
            { id: 'JK', state: 'Jammu and Kashmir', value: undefined },
            { id: 'LA', state: 'Ladakh', value: undefined },
            { id: 'LD', state: 'Lakshadweep', value: undefined },
            { id: 'PY', state: 'Puducherry', value: undefined }
        ];
    };

const geographyStyle = {
    default: {
        outline: 'none',
    },
    hover: {
        transition: 'all 250ms',
        outline: 'none'
    },
    pressed: {
        outline: 'none',
    }
};

function IndiaMap() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [tooltipContent, setTooltipContent] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['name', 'udise_no'],
        sort: 'region.name',
        type: REGIONS_TYPE.STATE,
        parent: '6583fee000b86495ea8c8066',
        sortDirection: 1,
        validated: true
    });

    const fetchList = () => {
        setLoading(true);
        dispatch(callApiAction(
            async () => await getCountsByRegionBeneficiaryApi({ ...filters }),
            (response) => {
                const heatMapData = getHeatMapData();
                const obj = {};
                heatMapData.forEach(item => {
                    obj[item.id] = {
                        ...item, value: {
                            name: item.state,
                            active: false,
                            donated: 0,
                            not_donated: 0
                        }
                    };
                });
                response?.forEach((item) => {
                    if (item?.region?.short_name) {
                        obj[item?.region?.short_name] = {
                            id: item?.region?.short_name,
                            active: true,
                            state: item.region?.name,
                            value: {
                                state_id: item.region?._id,
                                name: item.region?.name,
                                donated: item.donated?.find((val) => val.donated)?.count ?? 0,
                                not_donated: item.donated?.find((val) => !val.donated)?.count ?? 0
                            }
                        };
                    }
                });

                setData(Object.values(obj));
                setLoading(false);
            },
            (err) => {
                setLoading(false);
            }
        ));
    };

    useEffect(() => {
        fetchList();
    }, []);

    const onMouseEnter = (geo, current = { value: 'NA' }) => {
        return () => {
            const stateName = current.value ? current.value.name : current.state;
            const donated = current.value && current.value.donated !== undefined ? current.value.donated : 0;
            const not_donated = current.value && current.value.not_donated !== undefined ? current.value.not_donated : 0;
    
            setTooltipContent({
                name: stateName,
                donated: donated,
                not_donated: not_donated,
                state_id: current.value ? current.value.state_id : null
            });
        };
    };

    const onMouseLeave = () => {
        setTooltipContent('');
    };

    // const onClick = (value) => {
    //     setTooltipContent(value);
    // };
    const onClick = (current) => {
        if (current) {
            const stateName = current.value ? current.value.name : current.state;
            const donated = current.value && current.value.donated !== undefined ? current.value.donated : 0;
            const not_donated = current.value && current.value.not_donated !== undefined ? current.value.not_donated : 0;
            
            setTooltipContent({
                name: stateName,
                donated: donated,
                not_donated: not_donated,
                state_id: current.value ? current.value.state_id : null
            });
        } else {
            setTooltipContent('');
        }
    };
    
    


    // const onViewDetailsCLick = (state_id, name) => {
    //     dispatch(openModal(
    //         <ListOfBenificiaryModal modal_title={name} modal_id={'region-' + state_id} parent={state_id} type={REGIONS_TYPE.DISTRICT} />, "md", false, 'region-' + state_id
    //     ));
    // };

    if (loading) {
        return <CenteredBox sx={{ minHeight: "300px" }} className="table" >
            <CircularProgress />
        </CenteredBox>;
    }

    return (
        <Box sx={{ position: "relative" }} >
            <Grid container>
                <Grid item xs={12} sm={9}>
                    {data && data.length > 0 &&
                        <ComposableMap
                            projectionConfig={PROJECTION_CONFIG}
                            projection="geoMercator"
                            width={600}
                            height={220}
                            data-tip=""
                        >
                            <Geographies geography={INDIA_TOPO_JSON}>
                                {({ geographies }) => geographies.map(geo => {
                                    const current = data.find(s => s.id === geo.id);

                                    return (
                                        <Geography
                                            key={geo.rsmKey}
                                            geography={geo}
                                            fill={current ? (current.value.donated ? DONATED_COLOR : (current.value.not_donated ? NOT_DONATED_COLOR : DEFAULT_COLOR)) : DEFAULT_COLOR}
                                            style={{
                                                ...geographyStyle,
                                                hover: {
                                                    fill: current ? (current.value.donated ? DONATED_HOVER_COLOR : (current.value.not_donated ? NOT_DONATED_HOVER_COLOR : DEFAULT_HOVER_COLOR)) : DEFAULT_HOVER_COLOR,
                                                    ...geographyStyle.hover
                                                }
                                            }}
                                            onClick={(e) => {
                                                onClick(current);
                                            }}
                                            onMouseEnter={onMouseEnter(geo, current)}
                                            onMouseLeave={onMouseLeave}
                                        />


                                    );
                                })}
                            </Geographies>
                        </ComposableMap>
                    }
                    <Box sx={{ position: 'absolute', bottom: 0, left: '10px', margin: '10px' }}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                    <Box sx={{ width: '15px', height: '15px', backgroundColor: DONATED_HOVER_COLOR, borderRadius: '50%', marginRight: '5px' }}></Box>
                                    <Typography variant="body2">  Donation In Progress</Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                    <Box sx={{ width: '15px', height: '15px', backgroundColor: NOT_DONATED_HOVER_COLOR, borderRadius: '50%', marginRight: '5px' }}></Box>
                                    <Typography variant="body2">Yet To Donate</Typography>
                                </Box>
                            </Grid>

                            <Grid item>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                    <Box sx={{ width: '15px', height: '15px', backgroundColor: DEFAULT_HOVER_COLOR, borderRadius: '50%', marginRight: '5px' }}></Box>
                                    <Typography variant="body2">Assessment Pending</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box p={2} sx={{ borderLeft: "1px solid " + theme.palette.grey.main, height: "100%" }} >
                        {tooltipContent ? <Box p={2} >
                            <Typography variant='h6' align='center' color="primary" fontWeight={600} >
                                {tooltipContent.name}
                            </Typography>

                            <Box sx={{ display: "flex" }} mt={3} mb={3}>
                                <Box sx={{ display: "flex", flex: 1, flexDirection: "column", border: "1px solid gray", borderRadius: 1 }} p={2}>
                                    <Typography fontWeight={800} color="primary" variant='subtitle1' align='center' >
                                        {tooltipContent.donated}
                                    </Typography>
                                    <Typography variant='subtitle2' align='center' color="gray" >
                                        Donated
                                    </Typography>
                                </Box>
                                <Box ml={2} sx={{ display: "flex", flex: 1, flexDirection: "column", border: "1px solid gray", borderRadius: 1 }} p={2}>
                                    <Typography fontWeight={800} color="primary" variant='subtitle1' align='center' >
                                        {tooltipContent.not_donated}
                                    </Typography>
                                    <Typography variant='subtitle2' align='center' color="gray" >
                                        Yet To Donate
                                    </Typography>
                                </Box>
                            </Box>
                        </Box> :
                            <CenteredBox>
                                <NoDataComponent Icon={Map} message="Select State" />
                            </CenteredBox>
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default IndiaMap;
//New code:-
// import { Box, CircularProgress, Grid, Typography } from '@mui/material';
// import React, { useEffect, useState } from 'react';
// import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
// import 'react-tooltip/dist/react-tooltip.css';
// import { callApiAction } from '../../store/actions/commonAction';
// import { getCountsByRegionBeneficiaryApi } from '../../apis/beneficiary.api';
// import { REGIONS_TYPE } from '../../utils/constants';
// import { useDispatch } from 'react-redux';
// import { CenteredBox } from '../../components/layouts/OneViewBox';
// import { useTheme } from "@mui/material";
// import NoDataComponent from '../../components/layouts/NoDataComponent';
// import { Map } from '@mui/icons-material';
// import { openModal } from '../../store/actions/modalAction';
// import ListOfBenificiaryModal from '../beneficiary/view/ListOfBenificiaryModal';

// const INDIA_TOPO_JSON = require('./india.topo.json');

// const PROJECTION_CONFIG = {
//     scale: 400,
//     center: [78.9629, 22.5937] // always in [East Latitude, North Longitude]
// };

// const DONATED_COLOR = '#C1F2B0'; // Green
// const NOT_DONATED_COLOR = '#F7B787'; // Orange
// const DONATED_HOVER_COLOR = '#65B741'; // Green hover
// const NOT_DONATED_HOVER_COLOR = '#EE7214'; // Orange hover

// const getHeatMapData = () => {
//     return [
//         { id: 'AP', state: 'Andhra Pradesh', value: undefined },
//         { id: 'AR', state: 'Arunachal Pradesh', value: undefined },
//         { id: 'AS', state: 'Assam', value: undefined },
//         { id: 'BR', state: 'Bihar', value: undefined },
//         { id: 'CT', state: 'Chhattisgarh', value: undefined },
//         { id: 'GA', state: 'Goa', value: undefined },
//         { id: 'GJ', state: 'Gujarat', value: undefined },
//         { id: 'HR', state: 'Haryana', value: undefined },
//         { id: 'HP', state: 'Himachal Pradesh', value: undefined },
//         { id: 'JH', state: 'Jharkhand', value: undefined },
//         { id: 'KA', state: 'Karnataka', value: undefined },
//         { id: 'KL', state: 'Kerala', value: undefined },
//         { id: 'MP', state: 'Madhya Pradesh', value: undefined },
//         { id: 'MH', state: 'Maharashtra', value: undefined },
//         { id: 'MN', state: 'Manipur', value: undefined },
//         { id: 'ML', state: 'Meghalaya', value: undefined },
//         { id: 'MZ', state: 'Mizoram', value: undefined },
//         { id: 'NL', state: 'Nagaland', value: undefined },
//         { id: 'OD', state: 'Odisha', value: undefined },
//         { id: 'PB', state: 'Punjab', value: undefined },
//         { id: 'RJ', state: 'Rajasthan', value: undefined },
//         { id: 'SK', state: 'Sikkim', value: undefined },
//         { id: 'TN', state: 'Tamil Nadu', value: undefined },
//         { id: 'TS', state: 'Telangana', value: undefined },
//         { id: 'TR', state: 'Tripura', value: undefined },
//         { id: 'UK', state: 'Uttarakhand', value: undefined },
//         { id: 'UP', state: 'Uttar Pradesh', value: undefined },
//         { id: 'WB', state: 'West Bengal', value: undefined },
//         { id: 'AN', state: 'Andaman and Nicobar Islands', value: undefined },
//         { id: 'CH', state: 'Chandigarh', value: undefined },
//         { id: 'DN', state: 'Dadra and Nagar Haveli', value: undefined },
//         { id: 'DD', state: 'Daman and Diu', value: undefined },
//         { id: 'DL', state: 'Delhi', value: undefined },
//         { id: 'JK', state: 'Jammu and Kashmir', value: undefined },
//         { id: 'LA', state: 'Ladakh', value: undefined },
//         { id: 'LD', state: 'Lakshadweep', value: undefined },
//         { id: 'PY', state: 'Puducherry', value: undefined }
//     ];
// };

// const geographyStyle = {
//     default: {
//         outline: 'none',
//     },
//     hover: {
//         transition: 'all 250ms',
//         outline: 'none'
//     },
//     pressed: {
//         outline: 'none',
//     }
// };

// function IndiaMap() {
//     const theme = useTheme();
//     const dispatch = useDispatch();
//     const [tooltipContent, setTooltipContent] = useState('');
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(true);

//     const [filters, setFilters] = useState({
//         pageNo: 1,
//         pageSize: 10,
//         search: '',
//         searchable: ['name', 'udise_no'],
//         sort: 'region.name',
//         type: REGIONS_TYPE.STATE,
//         parent: '6583fee000b86495ea8c8066',
//         sortDirection: 1,
//         validated: true
//     });

//     const fetchList = () => {
//         setLoading(true);
//         dispatch(callApiAction(
//             async () => await getCountsByRegionBeneficiaryApi({ ...filters }),
//             (response) => {
//                 const heatMapData = getHeatMapData();
//                 const obj = {};
//                 heatMapData.forEach(item => {
//                     obj[item.id] = {
//                         ...item, value: {
//                             name: item.state,
//                             active: false,
//                             donated: 0,
//                             not_donated: 0
//                         }
//                     };
//                 });
//                 response?.forEach((item) => {
//                     if (item?.region?.short_name) {
//                         obj[item?.region?.short_name] = {
//                             id: item?.region?.short_name,
//                             active: true,
//                             state: item.region?.name,
//                             value: {
//                                 state_id: item.region?._id,
//                                 name: item.region?.name,
//                                 donated: item.donated?.find((val) => val.donated)?.count ?? 0,
//                                 not_donated: item.donated?.find((val) => !val.donated)?.count ?? 0
//                             }
//                         };
//                     }
//                 });

//                 setData(Object.values(obj));
//                 setLoading(false);
//             },
//             (err) => {
//                 setLoading(false);
//             }
//         ));
//     };

//     useEffect(() => {
//         fetchList();
//     }, []);

//     const onMouseEnter = (geo, current = { value:'NA' }) => {
//         return () => {
//             setTooltipContent(`${geo.properties.name}: ${current.value}`);
//         };
//     };

//     const onMouseLeave = () => {
//         setTooltipContent('');
//     };

//     const onClick = (value) => {
//         setTooltipContent(value);
//     };

//     const onViewDetailsCLick = (state_id, name) => {
//         dispatch(openModal(
//             <ListOfBenificiaryModal modal_title={name} modal_id={'region-' + state_id} parent={state_id} type={REGIONS_TYPE.DISTRICT} />, "md", false, 'region-' + state_id
//         ));
//     };

//     if (loading) {
//         return <CenteredBox sx={{ minHeight: "300px" }} className="table" >
//             <CircularProgress />
//         </CenteredBox>;
//     }

//     return (
//         <Box sx={{ position: "relative" }} >
//             <Grid container>
//                 <Grid item xs={12} sm={9}>
//                     {data && data.length > 0 &&
//                         <ComposableMap
//                             projectionConfig={PROJECTION_CONFIG}
//                             projection="geoMercator"
//                             width={600}
//                             height={220}
//                             data-tip=""
//                         >
//                             <Geographies geography={INDIA_TOPO_JSON}>
//                                 {({ geographies }) => geographies.map(geo => {
//                                     const current = data.find(s => s.id === geo.id);

//                                     return (
//                                         <Geography
//                                             key={geo.rsmKey}
//                                             geography={geo}
//                                             fill={current?.value?.donated ? DONATED_COLOR : NOT_DONATED_COLOR}
//                                             style={{
//                                                 ...geographyStyle,
//                                                 hover: {
//                                                     fill: current?.value?.donated ? DONATED_HOVER_COLOR : NOT_DONATED_HOVER_COLOR,
//                                                     ...geographyStyle.hover
//                                                 }
//                                             }}
//                                             onClick={(e) => {
//                                                 onClick(current?.value);
//                                             }}
//                                             onMouseEnter={onMouseEnter(geo, current)}
//                                             onMouseLeave={onMouseLeave}
//                                         />
//                                     );
//                                 })}
//                             </Geographies>
//                         </ComposableMap>
//                     }
//                     <Box sx={{ position: 'absolute', bottom: 0, left: '10px', margin: '10px' }}>
//                         <Grid container direction="column" spacing={1}>
//                             <Grid item>
//                                 <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
//                                     <Box sx={{ width: '15px', height: '15px', backgroundColor: DONATED_HOVER_COLOR, borderRadius: '50%', marginRight: '5px' }}></Box>
//                                     <Typography variant="body2">Donated</Typography>
//                                 </Box>
//                             </Grid>
//                             <Grid item>
//                                 <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
//                                     <Box sx={{ width: '15px', height: '15px', backgroundColor: NOT_DONATED_HOVER_COLOR, borderRadius: '50%', marginRight: '5px' }}></Box>
//                                     <Typography variant="body2">Yet To Donate</Typography>
//                                 </Box>
//                             </Grid>
//                         </Grid>
//                     </Box>
//                 </Grid>
//                 <Grid item xs={12} sm={3}>
//                     <Box p={2} sx={{ borderLeft: "1px solid " + theme.palette.grey.main, height: "100%" }} >
//                         {tooltipContent ? <Box p={2} >
//                             <Typography variant='h6' align='center' color="primary" fontWeight={600} >
//                                 {tooltipContent.name}
//                             </Typography>

//                             <Box sx={{ display: "flex" }} mt={3} mb={3}>
//                                 <Box sx={{ display: "flex", flex: 1, flexDirection: "column", border: "1px solid gray", borderRadius: 1 }} p={2}>
//                                     <Typography fontWeight={800} color="primary" variant='subtitle1' align='center' >
//                                         {tooltipContent.donated}
//                                     </Typography>
//                                     <Typography variant='subtitle2' align='center' color="gray" >
//                                         Donated
//                                     </Typography>
//                                 </Box>
//                                 <Box ml={2} sx={{ display: "flex", flex: 1, flexDirection: "column", border: "1px solid gray", borderRadius: 1 }} p={2}>
//                                     <Typography fontWeight={800} color="primary" variant='subtitle1' align='center' >
//                                         {tooltipContent.not_donated}
//                                     </Typography>
//                                     <Typography variant='subtitle2' align='center' color="gray" >
//                                         Yet To Donate
//                                     </Typography>
//                                 </Box>
//                             </Box>
//                         </Box> :
//                             <CenteredBox>
//                                 <NoDataComponent Icon={Map} message="Select State" />
//                             </CenteredBox>
//                         }
//                     </Box>
//                 </Grid>
//             </Grid>
//         </Box>
//     );
// }

// export default IndiaMap;