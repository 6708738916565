import {  CircularProgress, MenuItem } from "@mui/material"
import {  memo } from "react"

import CustomInput from "./../../components/inputs/CustomInput"
import CustomDialog from "./../../components/layouts/common/CustomDialog"
import { CenteredBox } from "./../../components/layouts/OneViewBox"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import CreatableRegionComponent from "../../components/custom/CreateableRegionComponent"
import { REGIONS_TYPE, USER_ROLES } from "../../utils/constants"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import {  getUserApi } from "../../apis/user.api"
import CreatableDropDownComponent from "../../components/custom/CreateableDropodown"
import CreateController from "../user/CreateController"







const CreateUi = ({ title, modalKey, fetchLoading, isUpdate, fields, setFields, loading, onSubmit }) => {

    return <>

        <CustomDialog
            id={modalKey}
            loading={loading || fetchLoading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {(loading || fetchLoading) && <CenteredBox><CircularProgress /></CenteredBox>}
            {!fetchLoading &&
                <>
                    <CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.name}
                        onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                        type="text"
                        label={"Camp Name*"}

                    />

                    <CustomInput

                        disabled={loading}
                        value={fields.total_expected_children}
                        onChange={(e) => setFields({ ...fields, err: '', total_expected_children: e.target.value })}
                        type="number"
                        label={"Total Expected Children*"}

                    />

                    <DesktopDatePicker
                        value={moment(fields.start_date)}
                        inputFormat="DD/MM/YYYY"

                        onChange={(val) => {
                            setFields({ ...fields, err: '', start_date: val?.toISOString() })
                        }}
                        label="Select Camp Date*"
                        renderInput={(params) => <CustomInput placeholder="Select Camp Date*"  {...params} />}
                    />

                    <AsyncDropDown

                        defaultVal={fields.camp_head}
                        lazyFun={async (para) => { return await getUserApi({ ...para, team: true }) }}

                        OptionComponent={({ option, ...rest }) => {
                            return <MenuItem {...rest} sx={{ textTransform: "capitalize" }} >{option.name}</MenuItem>
                        }}
                        onChange={async (changedVal) => {
                            setFields({ ...fields, camp_head: changedVal ? changedVal._id : null, })
                        }}
                        titleKey={'name'}
                        valueKey={"_id"}
                        InputComponent={(params) => <CustomInput placeholder={"Camp Head From Team*"} {...params} />}
                    />
                    {/* <AsyncDropDown

                        defaultVal={fields.camp_coordinator}
                        lazyFun={async (para) => { return await getUserApi({ ...para, role: USER_ROLES.CAMP_COORDINATOR }) }}

                        OptionComponent={({ option, ...rest }) => {
                            return <MenuItem {...rest} sx={{ textTransform: "capitalize" }} >{option.name}</MenuItem>
                        }}
                        onChange={async (changedVal) => {
                            setFields({ ...fields, camp_coordinator: changedVal ? changedVal._id : null, })
                        }}
                        titleKey={'name'}
                        valueKey={"_id"}
                        InputComponent={(params) => <CustomInput placeholder={"Camp Coordinator*"} {...params} />}
                    /> */}
                    <CreatableDropDownComponent

                        createComponent={<CreateController role={USER_ROLES.CAMP_COORDINATOR} callBack={()=>{
                            
                        }} />}
                        modalKey='users'
                        disabled={loading}
                        value={typeof fields.camp_coordinator == "object" ? fields.camp_coordinator : null}
                        label={"Camp Coordinator*"}
                        getApi={async (para) => { return await getUserApi({ ...para, role: USER_ROLES.CAMP_COORDINATOR }) }}
                        onChange={async (changedVal) => {

                            setFields({ ...fields, camp_coordinator: changedVal ? changedVal._id : null, })
                        }}
                    />
                    <CustomInput

                        disabled={loading}
                        value={fields.venue}
                        onChange={(e) => setFields({ ...fields, err: '', venue: e.target.value })}
                        type="text"
                        multiline
                        label={"Venue*"}

                    />
                    <CreatableRegionComponent
                        disabled={loading}
                        value={typeof fields.country == "object" ? fields.country : null}
                        label={"Select Country*"}
                        type={REGIONS_TYPE.COUNTRY}
                        parent={null}
                        onChange={async (changedVal) => {

                            setFields({ ...fields, country: (changedVal ? changedVal._id : null), state: null, district: null, taluka: null })
                        }}
                    />



                    <CreatableRegionComponent
                        disabled={loading || !fields.country || fields.country == null}
                        value={typeof (fields.state) == "object" ? fields.state : null}

                        type={REGIONS_TYPE.STATE}

                        onChange={async (changedVal) => {

                            setFields({ ...fields, state: changedVal ? changedVal._id : null, district: null, taluka: null })
                        }}
                        parent={fields.country && typeof (fields.country) == "object" ? fields.country['_id'] : fields.country}
                        label={"Select State*"}

                    />


                    <CreatableRegionComponent
                        disabled={loading || !fields.state || fields.state == null}
                        value={typeof fields.district == "object" ? fields.district : null}
                        type={REGIONS_TYPE.DISTRICT}

                        onChange={async (changedVal) => {
                            setFields({ ...fields, district: changedVal ? changedVal._id : null, taluka: null })
                        }}
                        label={"Select District*"}
                        parent={fields.state && typeof (fields.state) == "object" ? fields.state['_id'] : fields.state}
                    />

                    <CreatableRegionComponent
                        disabled={loading || !fields.district || fields.district == null}
                        value={typeof fields.taluka == "object" ? fields.taluka : null}
                        type={REGIONS_TYPE.TALUKA}


                        onChange={async (changedVal) => {

                            setFields({ ...fields, taluka: changedVal ? changedVal._id : null })
                        }}
                        label={"Select Taluka*"}
                        parent={fields.district && typeof (fields.district) == "object" ? fields.district['_id'] : fields.district}
                    />




                </>}

        </CustomDialog>


    </>
}
export default memo(CreateUi)