
import {Paper,  Typography, styled } from "@mui/material"
import { Box } from "@mui/system"

import { useDispatch, useSelector } from "react-redux"



import SubmitButton from "./../../components/button/SubmitButton"
import { StyledSearchBar } from "./../../components/inputs/SearchBar"


import { USER_ROLES } from "./../../utils/constants"
import DataTable from "./../../components/tables/DataTable"

import PaddingBoxInDesktop from "./../../components/layouts/PaddingBoxInDesktop"
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list, roles, loading, onCreateBtnClick, columns }) => {

    const user = useSelector(state => state.user)

    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} >
                        <FilterTitleBox>
                            <Typography variant="h5" mb={2} >{title}</Typography>

                            <Box mb={2}>
                                <SubmitButton variant="contained" onClick={onCreateBtnClick} title={"Add "+title} />
                            </Box>

                        </FilterTitleBox>

                        <FiltersBox mt={3} >
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <TimeRangeSelector size="small" placeholder="Filter By Camp Date" onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />
                            
                            </PaddingBoxInDesktop>
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} pl={3} >
                                <StyledSearchBar fullWidth title="Search Name " size="small" placeholder={"Search Name "} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} />
                            </PaddingBoxInDesktop>



                        </FiltersBox>

                    </Box>

                    <Box sx={{ minHeight: "300px" }}  className="table">
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi