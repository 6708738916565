import { Autocomplete, CircularProgress, ListItem, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo } from "react"
import { useDispatch, useSelector } from 'react-redux'

import SubmitButton from "../../components/button/SubmitButton"

import CustomInput from "../../components/inputs/CustomInput"
import { StyledSearchBar, UserSearchBarNormal } from "../../components/inputs/SearchBar"
import { closeModal } from "../../store/actions/modalAction"
import { REGIONS_TYPE, THIRD_PART_APPS_USED, USER_ROLES } from "../../utils/constants"
import { findNameByRole, findObjectKeyByValue, titleCase, toTitleCase } from "../../utils/helper"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getOpenSolarUserApi } from "../../apis/user.api"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { getRegionApi } from "../../apis/region.api"



const CreateUi = ({ title, modalKey, isUpdate, fields, setFields, loading, onSubmit }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    {<CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.name}
                        onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                        type="text"
                        label={"Product Name*"}

                    />}
                    {/* {<CustomInput
                        
                        disabled={loading}
                        value={fields.short_name}
                        onChange={(e) => setFields({ ...fields, err: '', short_name: e.target.value })}
                        type="text"
                        label={"Short Name*"}

                    />} */}
{/* 


                    {!isUpdate && <Autocomplete

                        disableClearable

                        value={fields.type ? findObjectKeyByValue(fields.type, REGIONS_TYPE) : null}

                        onChange={(e, newVal) => {
                            setFields({ ...fields, type: newVal ? newVal._id : null, parent: null })
                        }}
                        options={[...Object.keys(REGIONS_TYPE).map((key) => ({ label: titleCase(key), _id: REGIONS_TYPE[key] }))]}
                        sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                        renderInput={(params) => <CustomInput placeholder="Select Region Type*" {...params} label="Select Region Type*" />}
                    />} */}

                    {/* {fields.type != REGIONS_TYPE.COUNTRY && <AsyncDropDown
                        disablePortal
                        key={fields.type}
                        id={"Select " + findObjectKeyByValue(fields.type - 1, REGIONS_TYPE).toLowerCase()}
                        name={"Select " + findObjectKeyByValue(fields.type - 1, REGIONS_TYPE).toLowerCase()}
                        lazyFun={async (para) => { return await getRegionApi({ ...para, type: fields.type - 1 }) }}
                        label={"Select " + findObjectKeyByValue(fields.type - 1, REGIONS_TYPE).toLowerCase()}
                        OptionComponent={({ option, ...rest }) => {
                            return <ListItem {...rest}>{option.name}</ListItem >
                        }}
                        onChange={async (changedVal) => {
                            setFields({ ...fields, parent: changedVal ? changedVal._id : null, })
                        }}
                        titleKey={'name'}
                        valueKey={"_id"}
                    />} */}

                </>}

        </CustomDialog>
    </>
}
export default memo(CreateUi)
