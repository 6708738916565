import CustomDialog from "../../../components/layouts/common/CustomDialog"
import ListOfBeneficiaryByRegionController from "./ListOfBeneficiaryByRegionController"
import ListOfDonationController from "./ListOfDonationController"


const ListOfDonationModal = ({ ...props }) => {

    return <CustomDialog
        title={"Beneficiary Data"}
        id={'beneficiary-donation-list'}
    >
        <ListOfDonationController {...props} />
    </CustomDialog>
}
export default ListOfDonationModal