import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"


import UpdateBeneficiaryStatusUi from "./UpdateFollowupStatusUI"
import { updateFollowUpApi, updateInclusiveFollowUpApi } from "../../apis/followup.api"
import { BENIFICIARY_LOG_STATUS, FOLLOWUP_TYPE, FOLLOW_UP_LOG_TYPE, FOLLOW_UP_STATUS, INCLUSION_STATE } from "../../utils/beneficiary.constant"
import moment from "moment"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"

const UpdateFollowupStatusController = ({ callBack = () => { }, id, type,params }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    // const params = useParams()

    const title = " Followup Status "
    const modalKey = 'followup-update'

    const updateApi = updateFollowUpApi


    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        type,
        status: FOLLOW_UP_LOG_TYPE.CALL_ANSWERED,
        remarks: "",
        videos: '',
        date: moment().toISOString(),
        hearing_state: null,
        inclusion_state: null,
        speech_state: null,
        daily_usage: null,
        weekly_usage: null,
        followup_no:null,
    })


    const validationSchema = useMemo(() => ([
        {
            // required: true,
            value: fields.donors,
            custom: () => {
                if (fields.status == FOLLOW_UP_LOG_TYPE.CALL_ANSWERED && (!fields.hearing_state || fields.hearing_state == '')) {
                    return "Hearing  State "
                }
                if (fields.status == FOLLOW_UP_LOG_TYPE.CALL_ANSWERED && (!fields.speech_state || fields.speech_state == '')) {
                    return "Speech Progress "
                }

                if (fields.status == FOLLOW_UP_LOG_TYPE.CALL_ANSWERED && (!fields.daily_usage || isNaN(fields.daily_usage))) {

                    return "Average Daily Usage "

                } else {

                    if ((fields.daily_usage < 0 || fields.daily_usage > 24)) {
                        return "Invalid Average Daily Usage"
                    }

                }


                if (fields.status == FOLLOW_UP_LOG_TYPE.CALL_ANSWERED && (!fields.weekly_usage || isNaN(fields.weekly_usage))) {

                    return "Average Weekly Usage "

                } else {

                    if ((fields.weekly_usage < 0 || fields.weekly_usage > 7)) {
                        return "Invalid Average Weekly Usage"
                    }

                }

                return true
            },

        },
        {
            value: fields.donors,
            custom: () => {
                if (fields.status != FOLLOW_UP_LOG_TYPE.CALL_ANSWERED && (!fields.date || fields.date == '')) {
                    return "Reschedule date"
                }

                return true
            }
        },
        {
            required: true,
            value: fields.remarks,
            field: 'Remarks '
        }

    ]), [fields])





    const updateFunFunction = async () => {
        const validationResponse = validate(validationSchema)

        if (validationResponse === true) {
            const updatableData = {
                id: fields.id,
                status: fields.status,
                remarks: fields.remarks,
                videos: fields.videos
            }
            if (fields.status != FOLLOW_UP_LOG_TYPE.CALL_ANSWERED) {
                updatableData['date'] = fields.date
            }

            if (fields.status == FOLLOW_UP_LOG_TYPE.CALL_ANSWERED) {
                updatableData['hearing_state'] = fields.hearing_state
                updatableData['speech_state'] = fields.speech_state
                updatableData['daily_usage'] = fields.daily_usage
                updatableData['weekly_usage'] = fields.weekly_usage
            }

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(updatableData),
                    async (response) => {

                        const updatableObj = {
                            status: fields.status == FOLLOW_UP_LOG_TYPE.CALL_ANSWERED ? FOLLOW_UP_STATUS.FOLLOWED_UP : FOLLOW_UP_STATUS.PENDING,
                        }
                        if (fields.status != FOLLOW_UP_LOG_TYPE.CALL_ANSWERED) {
                            updatableObj['scheduled_date'] = fields.date
                        }
                        await callBack(updatableObj)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                        dispatch(callSnackBar('Followup Updated successfully', SNACK_BAR_VARIETNS.suceess))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }



    const inclusiveValidationSchema = useMemo(() => ([
        {


            custom: () => {
                if (fields.status == FOLLOW_UP_LOG_TYPE.CALL_ANSWERED && (!fields.inclusion_state || fields.inclusion_state == '')) {
                    return "Inclusive  State "
                }


                if (fields.status == FOLLOW_UP_LOG_TYPE.CALL_ANSWERED && (!fields.daily_usage || isNaN(fields.daily_usage))) {

                    return "Average Daily Usage "

                } else {

                    if ((fields.daily_usage < 0 || fields.daily_usage > 24)) {
                        return "Invalid Average Daily Usage"
                    }

                }


                if (fields.status == FOLLOW_UP_LOG_TYPE.CALL_ANSWERED && (!fields.weekly_usage || isNaN(fields.weekly_usage))) {

                    return "Average Weekly Usage "

                } else {

                    if ((fields.weekly_usage < 0 || fields.weekly_usage > 7)) {
                        return "Invalid Average Weekly Usage"
                    }

                }

                return true
            },

        },
        {

            custom: () => {
                if (fields.status != FOLLOW_UP_LOG_TYPE.CALL_ANSWERED && (!fields.date || fields.date == '')) {
                    return "Reschedule date"
                }
                if (fields.status == FOLLOW_UP_LOG_TYPE.CALL_ANSWERED && fields.inclusion_state != INCLUSION_STATE.I3 && (!fields.date || fields.date == '')) {
                    return "Next Followup date"
                }
                return true
            }
        },
        {
            required: true,
            value: fields.remarks,
            field: 'Remarks '
        }

    ]), [fields])





    const updateInclusiveFollowupFunction = async () => {
        const validationResponse = validate(inclusiveValidationSchema)

        if (validationResponse === true) {
            const updatableData = {
                id: fields.id,
                status: fields.status,
                remarks: fields.remarks,
                videos: fields.videos
            }

            updatableData['date'] = fields.date


            if (fields.status == FOLLOW_UP_LOG_TYPE.CALL_ANSWERED) {
                updatableData['inclusion_state'] = fields.inclusion_state               
                updatableData['daily_usage'] = fields.daily_usage
                updatableData['weekly_usage'] = fields.weekly_usage
            }

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateInclusiveFollowUpApi(updatableData),
                    async (response) => {

                        const updatableObj = {
                            status: fields.status == FOLLOW_UP_LOG_TYPE.CALL_ANSWERED ? FOLLOW_UP_STATUS.FOLLOWED_UP : FOLLOW_UP_STATUS.PENDING,
                        }
                       
                        await callBack(updatableObj)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                        dispatch(callSnackBar('Followup Updated successfully', SNACK_BAR_VARIETNS.suceess))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }




    const onSubmit = async (e) => {
        e.preventDefault()

        if (type == FOLLOWUP_TYPE.INCLUSIVE) {
            updateInclusiveFollowupFunction()
        } else {
            updateFunFunction()
        }


    }



    return <UpdateBeneficiaryStatusUi modalKey={modalKey} title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} params={params}/>
}
export default memo(UpdateFollowupStatusController)