import { memo, useCallback, useEffect, useRef, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal, openModal } from "../../store/actions/modalAction"

import { Box, Button, Chip, CircularProgress, ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Switch, Typography } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./CreateController"
import { Check, Delete, Edit, Info, MoreVert } from "@mui/icons-material"

import { deleteBeneficiaryApi, getBeneficiaryApi, validateBeneficiaryApi } from "../../apis/beneficiary.api"
import moment from "moment"
import DetailedViewController from "./detailedview/DetailedViewController"
import { BENIFICIARY_STATUS } from "../../utils/beneficiary.constant"

import { createFullName, findObjectKeyByValue } from "../../utils/helper"
import BeneficiaryUpdateButton from "./components/BeneficiaryUpdateButton"
import BeneficiaryTestButton from "./components/BeneficiaryTestButton"
import fileDownload from "js-file-download"
import UpdateHearingTestDetailsController from "./UpdateHearingTestDetailsController"
import MessageDilog from "../../components/MessageDilog"

export const BeneficiaryActionComponent = memo(({ params, setParams, modalKey }) => {
    const dispatch = useDispatch()
    const anchorRef = useRef(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [open, setOpen] = useState(false)

    const [loading, setLoading] = useState(false)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const onViewMore = () => {
        dispatch(openModal(<DetailedViewController
            id={params._id}

        />, "md", false, "beneficiary-detailed-view"))
    }

    // useEffect(() => {
    //     if (open) {
    //         // Focus on the Popper when it opens
    //         anchorRef.current.focus();
    //     }
    // }, [open]);


    useEffect(() => {
        if (modalOpen) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [modalOpen]);

    return <Box sx={{ width: "100%", display: "flex" }}>


        <IconButton fullWidth color="info" disableElevation size="inherit" onClick={onViewMore}>
            <Info />
        </IconButton>

        <IconButton
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? 'composition-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
        >
            <MoreVert />
        </IconButton>

        <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="right-start"
            transition
        disablePortal
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom-start' ? 'left top' : 'right top',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                                autoFocusItem={open}
                                id="composition-menu"
                                aria-labelledby="composition-button"

                            >
                                <BeneficiaryUpdateButton
                                    id={params._id}
                                    status={params.status}
                                    type="menuitem"
                                />
                                <BeneficiaryTestButton
                                    id={params._id}
                                    status={params.status}
                                    params={params}
                                    setParams={setParams}
                                />
                                <MenuItem onClick={onViewMore}>
                                    View Details
                                </MenuItem>

                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>


    </Box>
})

const DeleteBeneficiaryButton = memo(({ params, setParams, modalKey }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)


    const onSubmit = (e) => {
        e.preventDefault()
        dispatch(closeModal())
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteBeneficiaryApi({ id: params?._id }),
            (response) => {
                setLoading(false)
                setParams({})
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onClick = () => {
        dispatch(openModal(<MessageDilog
            
            title={"Are you sure to delete " + params.name}
            message="On click of delete beneficiary will be deleted and also remnoved from camp & event"
            onSubmit={onSubmit}

        />, "sm"))
    }



    if (loading)
        return <CircularProgress />

    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
{/* 
        <IconButton onClick={onClick} >
            <Delete />
        </IconButton> */}
    </Box>
})

// const UpdateBeneficiaryDetailsComponent = memo(({ params, setParams, modalKey }) => {
//     return <></>
// })



const ListController = () => {
    const dispatch = useDispatch()



    const title = "Beneficiary Data "
    const modalKey = 'beneficiary'
    const fetchApi = getBeneficiaryApi



    const columns = useMemo(() => [
        { id: 0, fieldName: 'camp_date', label: 'Camp Date', align: "left", sort: true, renderValue: (fields) => fields.camp_date ? moment(fields.camp_date).format("DD/MM/YYYY") : "NA" },
        { id: 2, fieldName: 'createdAt', label: 'Created Date', align: "left", sort: true, renderValue: (fields) => fields.createdAt ? moment(fields.createdAt).format("DD/MM/YYYY") : "NA" },
        { id: 1, fieldName: 'name', label: 'Name', align: "left", sort: true, renderValue: (params) => createFullName(params.surname, params.name) },
        { id: 3, fieldName: 'phone', label: 'Phone No.', align: "left" },
        { id: 3, fieldName: 'district.name', label: 'District', align: "left", sort: true, renderValue: (params) => params.district?.name },
        { id: 8, fieldName: 'status', label: 'Status', align: "left", sort: true, renderValue: (params, setParams) => <Chip size="small" label={findObjectKeyByValue(params.status, BENIFICIARY_STATUS)} /> },
        { id: 7, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <BeneficiaryActionComponent params={params} setParams={setParams} /> },

        // { id: 8, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <DeleteBeneficiaryButton params={params} setParams={setParams} /> },
    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['name', 'surname', 'phone'],
        sort: 'camp_date',
        donation_event_id: null,
        camp: null,
        country: null,
        state: null,
        district: null,
        status: null,
        taluka: null,

        udise_file: null,
        photo: null,
        aadhar_file: null,
        income_certi: null,

        sortDirection: -1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [exportLoading, setexportLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = (isExport) => {
        if (!isExport) {
            setLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters }),
                (response) => {
                    setList(response)
                    setLoading(false)
                },
                (err) => {
                    setLoading(false)
                }
            ))
        } else {
            setexportLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters, csv: true }),
                (response) => {
                    fileDownload(response, "beneficiary.csv")
                    setexportLoading(false)
                },
                (err) => {
                    console.log(err)
                    setexportLoading(false)
                },
                true
            ))
        }
    }

    // const fetchList = (isExport) => {
    //     // setLoading(true)
    //     // dispatch(callApiAction(
    //     //     async () => await fetchApi({ ...filters }),
    //     //     (response) => {
    //     //         setList(response)
    //     //         setLoading(false)
    //     //     },
    //     //     (err) => {
    //     //         setLoading(false)
    //     //     }
    //     // ))

    //     if (!isExport) {
    //         setLoading(true)
    //         dispatch(callApiAction(
    //             async () => await fetchApi({ ...filters }),
    //             (response) => {
    //                 setList(response)
    //                 setLoading(false)
    //             },
    //             (err) => {
    //                 setLoading(false)
    //             }
    //         ))
    //     } else {
    //         setexportLoading(true)
    //         dispatch(callApiAction(
    //             async () => await fetchApi({ ...filters, csv: true }),
    //             (response) => {
    //                 fileDownload(response, "customers.csv")
    //                 setexportLoading(false)
    //             },
    //             (err) => {
    //                 console.log(err)
    //                 setexportLoading(false)
    //             },
    //             true
    //         ))
    //     }
    // }
    const callBackFun = useCallback(({ _id, name, surname }) => {

        fetchList();
    }, [filters])
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController callBack={async () => { fetchList() }} />, "sm", false, modalKey))
    }

    const onExportBtnClick = () => {
        fetchList(true)
    }

    useEffect(() => {
        fetchList()
    }, [filters])




    return (
        <>
            <ListUi
                callBackFun={callBackFun}
                title={title}
                onCreateBtnClick={onCreateBtnClick}
                exportLoading={exportLoading}
                onExportBtnClick={onExportBtnClick}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController