import { Box } from "@mui/system";
import {
  styled,
  ListItem,
  Paper,
  Grid,
  Skeleton,
  Typography,
  ButtonBase,
  Autocomplete,
} from "@mui/material";

import { REGIONS_TYPE, USER_ROLES } from "../../utils/constants";
import DataTable from "../../components/tables/DataTable";

import {
  findObjectKeyByValue,
  titleCase,
  toTitleCase,
} from "../../utils/helper";
import { StyledSearchBar } from "../../components/inputs/SearchBar";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { getRegionApi } from "../../apis/region.api";
import CreatableRegionComponent from "../../components/custom/CreateableRegionComponent";

import { getEventApi } from "../../apis/event.api";
import { getDonorsApi } from "../../apis/user.api";
import {
  getDashboardApi,
  getHearingStateCountApi,
} from "../../apis/dashboard.api";
import { useDispatch, useSelector } from "react-redux";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import SubmitButton from "../../components/button/SubmitButton";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { HEARING_STATE, SPEECH_STATE } from "../../utils/beneficiary.constant";
import CustomInput from "../../components/inputs/CustomInput";
import { useEffect, useState } from "react";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space- tween",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const DemoPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: 120,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "center",
}));

const CountBoxContainer = styled(Box)(({ theme, active }) => ({
  background: active ? theme.palette.primary.main : theme.palette.light.main,
  border: "1px solid " + theme.palette.grey.main,
  borderRadius: theme.shape.borderRadius,
  height: "100%",
  "*": {
    color: active ? theme.palette.light.main + "!important" : "",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ListUi = ({
  title,
  filters,
  setFilters,
  list,
  modalKey,
  loading,
  exportLoading,
   columns,
  onExport,
}) => {
  //  const arrIndex = list.forEach((item)=>llast = item);
  // console.log("ARRLISt",arrIndex);
  const user = useSelector((state) => state.user);
  const [filteredData, setFilteredData] = useState([]);

  // const lastResultItem = list?.result?.[list.result.length - 1];
  // console.log("Last Result Item:", lastResultItem);
  // const arrList = list?.total;
  // console.log("LIST",);
  // let arrList;
  // if(list?.result){
  //   let arrList=  list?.result?.length-1;
  // }
  // const arrIndex = list.reduce((acc, current) => current);
  // const arrIndex = list.map((item) => 
  //   item);
 
  // console.log("OOOOO",list?.result);
//   const avg_daily_usage_data = list?.result?.map((item) =>
//     item.benificiary_avg_device_usage.toFixed(2)
//   );
//   let avgDailyusage = 0;
//   for (let i = 0; i < avg_daily_usage_data?.length; i++) {
//     avgDailyusage += parseFloat(avg_daily_usage_data[i]);
//   }

//   console.log("AVGDAILY USAGE",avgDailyusage);
//   const avg_weekly_usage_data = list?.result?.map((item) =>
//     item.benificiary_avg_device_usage_weekly.toFixed(2)
//   );
//   let avgWeeklyusage = 0;
//   for (let i = 0; i < avg_weekly_usage_data?.length; i++) {
//     avgWeeklyusage += parseFloat(avg_weekly_usage_data[i]);
//   }
// console.log("AVGWEEKUSAGE",list)

  return (
    <>
      <CustomDialog
        id={modalKey}
        loading={loading}
        title={`${title}`}
        closeText="Close"
      >

        <Box component={Paper} p={3} mb={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
            }}
          >
            <FiltersBox mt={3}>
              <PaddingBoxInDesktop
                mb={2}
                sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                <AsyncDropDown
                  lazyFun={getEventApi}
                  OptionComponent={({ option, ...rest }) => {
                    return <ListItem {...rest}>{option.name}</ListItem>;
                  }}
                  onChange={async (changedVal) => {
                    setFilters({
                      ...filters,
                      donation_event_id: changedVal ? changedVal._id : null,
                    });
                  }}
                  titleKey={"name"}
                  valueKey={"_id"}
                  InputComponent={(params) => (
                    <StyledSearchBar
                      placeholder={"Select Event"}
                      {...params}
                      margin="none"
                    />
                  )}
                />
              </PaddingBoxInDesktop>

              <PaddingBoxInDesktop
                mb={2}
                // mt={1}
                sx={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  "*": {
                    justifyContent: "center",
                  },
                }}
                pl={3}
              >
                {/* <AsyncDropDown
                  // key={filters.donor}
                  lazyFun={async (para) => {
                    return await getDonorsApi({ ...para });
                  }}
                  OptionComponent={({ option, ...rest }) => {
                    return <ListItem {...rest}>{option.name}</ListItem>;
                  }}
                  onChange={async (changedVal) => {
                    setFilters({
                      ...filters,
                      donor: changedVal ? changedVal._id : null,
                    });
                  }}
                  titleKey={"name"}
                  valueKey={"_id"}
                  InputComponent={(params) => (
                    <StyledSearchBar
                      placeholder={"Select Donor"}
                      {...params}
                      margin="none"
                    />
                  )}
                /> */}
                 <AsyncDropDown
                        // key={filters.donor}
                        lazyFun={async (para) => {
                          console.log("DONER PARA", para);
                          return await getDonorsApi({ ...para });
                        }}
                        OptionComponent={({ option, ...rest }) => {
                          console.log("DONER OPTIONS", option);
                          return <ListItem {...rest}>{option.name}</ListItem>;
                        }}
                        onChange={async (changedVal) => {
                          console.log("DONOR CHANGE", changedVal);
                          setFilters({
                            ...filters,
                            donor: changedVal ? changedVal._id : null,
                          });
                        }}
                        titleKey={"name"}
                        valueKey={"_id"}
                        InputComponent={(params) => (
                          console.log("donor", params),
                          (
                            <StyledSearchBar
                              placeholder={"Select Donor"}
                              {...params}
                              margin="none"
                            />
                          )
                        )}
                      />
              </PaddingBoxInDesktop>
            </FiltersBox>
            <FiltersBox>
              <PaddingBoxInDesktop
                mb={2}
                // mt={1}
                sx={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  "*": {
                    justifyContent: "center",
                  },
                }}
                // pl={3}
              >
                <Autocomplete
                  disableClearable
                  onChange={(e, newVal) => {
                    setFilters({
                      ...filters,
                      hearing_state: newVal ? newVal._id : null,
                    });
                  }}
                  options={[
                    { label: "All", _id: null },
                    ...Object.keys(HEARING_STATE).map((key) => ({
                      label: titleCase(key),
                      _id: HEARING_STATE[key],
                    })),
                  ]}
                  sx={{
                    width: "100%",
                    display: "flex",
                    "*": { display: "flex", justifyContent: "center", flex: 1 },
                  }}
                  renderInput={(params) => (
                    <StyledSearchBar
                      placeholder="Select Hearing State"
                      {...params}
                    />
                  )}
                  // renderInput={(params) => <CustomInput label="Select Hearing State*" {...params} />}
                />
              </PaddingBoxInDesktop>
              <PaddingBoxInDesktop
                mb={2}
                // mt={1}
                sx={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  "*": {
                    justifyContent: "center",
                  },
                }}
                pl={3}
              >
                <Autocomplete
                  disableClearable
                  onChange={(e, newVal) => {
                    setFilters({
                      ...filters,
                      speech_state: newVal ? newVal._id : null,
                    });
                  }}
                  options={[
                    { label: "All", _id: null },
                    ...Object.keys(SPEECH_STATE).map((key) => ({
                      label: titleCase(key),
                      _id: SPEECH_STATE[key],
                    })),
                  ]}
                  sx={{
                    width: "100%",
                    display: "flex",
                    "*": { display: "flex", justifyContent: "center", flex: 1 },
                  }}
                  renderInput={(params) => (
                    <StyledSearchBar
                      placeholder="Select Speech State"
                      {...params}
                    />
                  )}
                />
              </PaddingBoxInDesktop>
            </FiltersBox>
            <FilterTitleBox>
              <PaddingBoxInDesktop
                mb={2}
                sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                <CreatableRegionComponent
                  onlyDropDown
                  disabled={loading}
                  value={
                    typeof filters.country == "object" ? filters.country : null
                  }
                  label={"Select Country*"}
                  type={REGIONS_TYPE.COUNTRY}
                  parent={null}
                  onChange={async (changedVal) => {
                    setFilters({
                      ...filters,
                      country: changedVal ? changedVal._id : null,
                      state: null,
                      district: null,
                      taluka: null,
                    });
                  }}
                />
              </PaddingBoxInDesktop>

              <PaddingBoxInDesktop
                pl={2}
                mb={2}
                sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                <CreatableRegionComponent
                  onlyDropDown
                  disabled={
                    loading || !filters.country || filters.country == null
                  }
                  value={
                    typeof filters.state == "object" ? filters.state : null
                  }
                  type={REGIONS_TYPE.STATE}
                  onChange={async (changedVal) => {
                    setFilters({
                      ...filters,
                      state: changedVal ? changedVal._id : null,
                      district: null,
                      taluka: null,
                    });
                  }}
                  parent={
                    filters.country && typeof filters.country == "object"
                      ? filters.country["_id"]
                      : filters.country
                  }
                  label={"Select State*"}
                />
              </PaddingBoxInDesktop>

              <PaddingBoxInDesktop
                pl={2}
                mb={2}
                sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                <CreatableRegionComponent
                  onlyDropDown
                  disabled={loading || !filters.state || filters.state == null}
                  value={
                    typeof filters.district == "object"
                      ? filters.district
                      : null
                  }
                  type={REGIONS_TYPE.DISTRICT}
                  onChange={async (changedVal) => {
                    setFilters({
                      ...filters,
                      district: changedVal ? changedVal._id : null,
                      taluka: null,
                    });
                  }}
                  label={"Select District*"}
                  parent={
                    filters.state && typeof filters.state == "object"
                      ? filters.state["_id"]
                      : filters.state
                  }
                />
              </PaddingBoxInDesktop>

              <PaddingBoxInDesktop
                pl={2}
                mb={2}
                sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                <CreatableRegionComponent
                  onlyDropDown
                  disabled={
                    loading || !filters.district || filters.district == null
                  }
                  value={
                    typeof filters.taluka == "object" ? filters.taluka : null
                  }
                  type={REGIONS_TYPE.TALUKA}
                  onChange={async (changedVal) => {
                    setFilters({
                      ...filters,
                      taluka: changedVal ? changedVal._id : null,
                    });
                  }}
                  label={"Select Taluka*"}
                  parent={
                    filters.district && typeof filters.district == "object"
                      ? filters.district["_id"]
                      : filters.district
                  }
                />
              </PaddingBoxInDesktop>
            </FilterTitleBox>
          </Box>

          <Box sx={{ display: "flex", gap: "10px" }}>
            <Paper sx={{ height: "100%", width: "100%" }} mx={2} p={6}>
              <CenteredBox sx={{ flexDirection: "column" }}>
                <Typography
                  variant="h2"
                  lineHeight="100%"
                  color="primary"
                  sx={{ display: "flex", alignItems: "flex-end" }}
                ></Typography>
                <Typography variant="h2" color="primary" align="center">
                  {/* {(lastResultItem?.daily_usage ?? 0)} */}
                  {((list?.result?.[0]?.average_daily_usage)?.toFixed(2) ?? 0)}
                </Typography>
                <Typography variant="h5">Daily Usage Average</Typography>
              </CenteredBox>
            </Paper>

            <Paper sx={{ height: "100%", width: "100%" }} m={2} p={6}>
              <CenteredBox sx={{ flexDirection: "column" }}>
                <Typography
                  variant="h2"
                  lineHeight="100%"
                  color="primary"
                  sx={{ display: "flex", alignItems: "flex-end" }}
                ></Typography>
                <Typography variant="h2" color="primary" align="center">
                  {/* {(lastResultItem?.weekly_usage ?? 0)} */}
                   {((list?.result?.[0]?.average_weekly_usage)?.toFixed(2) ?? 0)}
                </Typography>
                <Typography variant="h5">Weekly Usage Average</Typography>
              </CenteredBox>
            </Paper>
          </Box>
        </Box>
{/* 
        <Box sx={{ minHeight: "300px" }} className="table">
          <DataTable
            columns={columns}
            rows={list.result ? list.result : [] ?? 0}
            count={list.total ?? 0}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
          />
        </Box> */}
      </CustomDialog>
    </>
  );
};
export default ListUi;
