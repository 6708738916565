import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"

import { closeModal } from "../../../store/actions/modalAction"
import { useParams } from "react-router-dom"

import {  getGovByIdApi,  updateGovField } from "../../../apis/user.api"
import { validatePhone } from "../../../utils/helper"

const CreateController = ({ callBack=()=>{}, id }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()
    const modalKey = 'users'
    const title = "Goverment "
    
    const updateApi =  updateGovField
    const getByIdApi = getGovByIdApi

    const [loading, setLoading] = useState(false)

    const [fetchLoading, setFetchLoading] = useState(true)

    const [fields, setFields] = useState({
        err: '',
        id,
        name: '',
        
        contact_person_phone:"",
        contact_person_name:"",
        
        photo:'',
        

    })
    const [originalDocument, setOriginalDocument] = useState({


    })


    

    const validationSchemaForUpdate = useMemo(() => ([

        {
            required: true,
            value: fields.name,
            field: 'NGO Name',
        },
        
        {
            required: true,
            value: fields.contact_person_name,
            field: 'Contact Person Name',
        },
        {
            required: true,
            value: fields.contact_person_phone,
            field: 'Contact Person Phone',
            custom:()=>{
                if ( !validatePhone(fields.contact_person_phone)) {
                    return "Contact person phone no is invalid."
                }
                return true
            }
        },
       
        // {
        //     required: true,
        //     value: fields.photo,
        //     field: 'Profile picture ',
        // },
       
    ]), [fields])



    const createFunction = async () => {
       
    }


    const updateFunction = async () => {
        
        const validationResponse = validate(validationSchemaForUpdate)
        let updatedData = { id }

        for (let field in originalDocument) {

            if (originalDocument[field] && fields[field] && fields[field] != originalDocument[field]) {
                const fieldVal = fields[field]
                updatedData[field] = typeof fieldVal == "object" ? fieldVal['_id'] : fieldVal
            }
        }
        

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(updatedData),
                    async (response) => {

                        await callBack(response, updatedData)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }



    const onSubmit = async (e) => {
        e.preventDefault()
        if (id)
            updateFunction()
        

    }

    const fetchById = (id) => {
        setFetchLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {


                    setFields({ ...fields, ...response })
                    setOriginalDocument(response)
                    setFetchLoading(false)

                },
                (err) => {
                    setFields({ ...fields, err })
                    setFetchLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])


    return <CreateUi modalKey={modalKey} title={title} fetchLoading={fetchLoading} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)