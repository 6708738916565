import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";




export const getProductApi = async params => {
  const callResponse = await axios({
    url: endpoints.product,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)

    .catch(err => err.response.data);

  return callResponse;
};


export const addProductApi = async data => {
  const callResponse = await axios({
    url: endpoints.product,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const deleteProductField = async data => {
  const callResponse = await axios({
    url: endpoints.product,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


