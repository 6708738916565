import { Add, Analytics, AssignmentReturn, AutoMode, BarChartOutlined, Bloodtype, Business, Campaign, ChatRounded, CheckCircle, Contacts, Dashboard, Diversity1, Diversity2, Engineering, EventAvailable, EventSeat, Folder, Key, Leaderboard, List, LocalPolice, Map, MapRounded, Message, Money, PaymentOutlined, Pending, PendingActions, People, Person2, School, Settings, SupportAgentOutlined, VideoCameraBack, VideoLabel, VolunteerActivism } from "@mui/icons-material"

import { Navigate } from "react-router-dom"
import AppContainer from "./components/layouts/common/AppContainer"

import SignInController from "./pages/signin/SignInController"
import UserListController from "./pages/user/ListController"
import FollowupListController from "./pages/followup/ListController"

import TeacherListController from "./pages/user/techers/ListController"
import DonorListController from "./pages/user/donor/ListController"
import NGOListController from "./pages/user/ngo/ListController"
import GovListController from "./pages/user/gov/ListController"
import DocumentsListController from "./pages/documents/ListController"
import CampController from "./pages/camp/ListController"
import EventListController from "./pages/event/ListController"
import BeneficiaryCreateController from "./pages/beneficiary/CreateController"
import BeneficiaryListController from "./pages/beneficiary/ListController"

import { USER_ROLES } from "./utils/constants"
import PagenotFound from "./components/layouts/PagenotFound"
import ListController from "./pages/secrets/ListController"
import RegionListController from "./pages/region/ListController"
import DashboardController from "./pages/dashboard/DashboardController"
import MODULES from "./utils/module.constant"
import NotAllowedModule from "./components/layouts/NotAllowedComponent"
import NotAllowedComponent from "./components/layouts/NotAllowedComponent"
import ListOfDonationController from "./pages/beneficiary/view/ListOfDonationController"
import ListOfBeneficiaryByRegionController from "./pages/beneficiary/view/ListOfBeneficiaryByRegionController"
import AssignBeneficiaryFollowUpController from "./pages/followup/AssignBeneficiaryFollowUpController"
import { getDefaultRedirect } from "./utils/routinghelper"
import { memo } from "react"
import ListOfBenificiaryByVideoAvaiblitynUi from "./pages/beneficiary/videos/ListOfBenificiaryByVideoAvaiblityUi"
import ListOfBenificiaryByVideoAvaiblityController from "./pages/beneficiary/videos/ListOfBenificiaryByVideoAvaiblityController"
import ListVideoController from "./pages/beneficiary/videos/ListVideoController"
import { FOLLOWUP_TYPE } from "./utils/beneficiary.constant"
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import ProductListController from "./pages/products/ListController";
import ListMainVideoController from "./pages/beneficiary/videos/mainvideos/ListVideoController"
import ListSummaryController from "./pages/summary/ListSummaryController";
import ListEventController from "./pages/beneficiary/videossummary/ListController"

const loggedInPathElementRender = (login, allowed = [], permittedModule = [], Component, defaultRedirect, hideInPannel = false) => {

    const obj = {
        hideInPannel,
        element: Component,
    }
    if (!login) {
        obj['element'] = <Navigate replace to={'/sign-in'} />
    } else {

        let found = false
        for (let module of allowed) {
            for (let allowedModule of permittedModule) {
                if (module == allowedModule) {
                    found = true
                    break;
                }
            }
        }
        if (!found) {
            obj['hideInPannel'] = true
            obj['element'] = <NotAllowedComponent />
        }
    }
    return obj
}

const defineRoutes = (user) => {
    const allowedModules = user.data.modules ?? []
    const defaultRedirect = getDefaultRedirect(allowedModules, user.data.role)
    return ([
        {
            path: "sign-in",
            element: !user.isLoggedIn ? <SignInController /> : <Navigate replace to={defaultRedirect} />,
            hideInPannel: true

        },
        {
            path: "",
            element: user.isLoggedIn ? <Navigate replace to={defaultRedirect} /> : <Navigate replace to="/sign-in" />,
            hideInPannel: true

        },
        {
            path: "dashboard",
            icon: <Dashboard />,
            title: "Dashboard",
            ...loggedInPathElementRender(user.isLoggedIn, [MODULES.DASHBOARD], allowedModules, <AppContainer ><DashboardController /></AppContainer>, defaultRedirect)

        },

        {
            path: "beneficiary",
            icon: <Diversity1 />,
            title: "Beneficiary",
            ...loggedInPathElementRender(user.isLoggedIn, [MODULES.BENIFIICIARY_MODULE], allowedModules, <AppContainer />, defaultRedirect),


            children: [
                {
                    path: "add",
                    icon: Add,
                    title: "Add Beneficiary",
                    // element: user.isLoggedIn ? <BeneficiaryCreateController /> : <Navigate replace to="/sign-in" />,
                    ...loggedInPathElementRender(user.isLoggedIn, [MODULES.BENIFICIARY_ADD], allowedModules, <BeneficiaryCreateController />, defaultRedirect),
                },

                {
                    path: "beneficiary-data",
                    icon: BarChartOutlined,
                    title: "Beneficiary Data",
                    ...loggedInPathElementRender(user.isLoggedIn, [MODULES.BENIFIICIARY_DONATIONS], allowedModules, <ListOfDonationController />, defaultRedirect)
                    // element: user.isLoggedIn ? <ListOfDonationController /> : <Navigate replace to="/sign-in" />
                },
                // {
                //     path: "beneficiary-video",
                //     icon: VideoCameraBack,
                //     title: "Beneficiary Videos",
                //     element:<ListOfBenificiaryByVideoAvaiblityController />
                //     // ...loggedInPathElementRender(user.isLoggedIn, [MODULES.BENIFICIARY_ADD, MODULES.BENIFICIARY_TEST, MODULES.BENIFICIARY_VALIDATE], allowedModules, <BeneficiaryListController />, defaultRedirect)
                //     // element: user.isLoggedIn ? <BeneficiaryListController /> : <Navigate replace to="/sign-in" />
                // },
                {
                    path: "beneficiary-video",
                    icon: VideoCameraBack,
                    title: "Beneficiary Videos",
                    // element: <ListMainVideoController />,
                    ...loggedInPathElementRender(user.isLoggedIn, [MODULES.VIDEO_MODULE], allowedModules, <ListMainVideoController />, defaultRedirect)
                    // element: user.isLoggedIn ? <BeneficiaryListController /> : <Navigate replace to="/sign-in" />
                },
                {
                    path: "event-video-summary",
                    icon:  VideoLabel,
                    title: "Event Videos Summary",
                    // element: <ListMainVideoController />,
                    ...loggedInPathElementRender(user.isLoggedIn, [MODULES.VIDEO_MODULE], allowedModules, <ListEventController />, defaultRedirect)
                    // element: user.isLoggedIn ? <BeneficiaryListController /> : <Navigate replace to="/sign-in" />
                },
              
                {



                    path: "beneficiary-raw-data",
                    icon: Diversity2,
                    title: "Beneficiary Raw Data",
                    ...loggedInPathElementRender(user.isLoggedIn, [MODULES.BENIFICIARY_ADD, MODULES.BENIFICIARY_TEST, MODULES.BENIFICIARY_VALIDATE], allowedModules, <BeneficiaryListController />, defaultRedirect)
                    // element: user.isLoggedIn ? <BeneficiaryListController /> : <Navigate replace to="/sign-in" />
                },
                {

                    path: "beneficiary-raw-video",
                    icon: VideoCameraBack,
                    title: "Beneficiary Raw Videos",
                    // element: <ListVideoController />,
                    ...loggedInPathElementRender(user.isLoggedIn, [MODULES.BENIFICIARY_ROW_VIDEO], allowedModules, <ListVideoController/>, defaultRedirect)
                    // element: user.isLoggedIn ? <BeneficiaryListController /> : <Navigate replace to="/sign-in" />
                },
                {



                    path: "by-region",
                    icon: MapRounded,
                    title: " Region Wise Data",
                    ...loggedInPathElementRender(user.isLoggedIn, [MODULES.BENIFIICIARY_DONATIONS], allowedModules, <ListOfBeneficiaryByRegionController />, defaultRedirect)
                    // element: user.isLoggedIn ? <ListOfBeneficiaryByRegionController /> : <Navigate replace to="/sign-in" />
                },
            ],
        },

        {
            path: "events",
            icon: <EventAvailable />,
            title: "Events",
            ...loggedInPathElementRender(user.isLoggedIn, [MODULES.EVENT_MODULE], allowedModules, <AppContainer ><EventListController /></AppContainer>, defaultRedirect)
            // element: user.isLoggedIn ? <AppContainer ><EventListController /></AppContainer> : <Navigate replace to="/sign-in" />
        },
        // {
        //     path: "summary",
        //     icon: <Analytics />,
        //     title: "Summary",
        //     ...loggedInPathElementRender(user.isLoggedIn, [MODULES.DASHBOARD], allowedModules, <AppContainer ><ListSummaryController /></AppContainer>, defaultRedirect)
        //     // element: user.isLoggedIn ? <AppContainer ><EventListController /></AppContainer> : <Navigate replace to="/sign-in" />
        // },
        // {



        //     path: "assign-followup",
        //     icon: <AssignmentReturn />,
        //     title: "Assign Followup",
        //     ...loggedInPathElementRender(user.isLoggedIn, [MODULES.ASSIGN_FOLLOWUP], allowedModules, <AppContainer ><AssignBeneficiaryFollowUpController /></AppContainer>, defaultRedirect)
        //     // element: user.isLoggedIn ? <AppContainer ><AssignBeneficiaryFollowUpController /></AppContainer> : <Navigate replace to="/sign-in" />
        // },
        // {



        //     path: "followups",
        //     icon: <Contacts />,
        //     title: " Followup List",
        //     ...loggedInPathElementRender(user.isLoggedIn, [MODULES.FOLLOWUP_LIST], allowedModules, <AppContainer ><FollowupListController /></AppContainer>, defaultRedirect)
        //     // element: user.isLoggedIn ? <AppContainer ><FollowupListController /></AppContainer> : <Navigate replace to="/sign-in" />
        // },



        {
            path: "followups",
            icon: <Contacts />,
            title: "FollowUps",
            ...loggedInPathElementRender(user.isLoggedIn, [MODULES.FOLLOWUP_LIST,MODULES.FOLLOWUP_LIST], allowedModules, <AppContainer />, defaultRedirect),


            children: [
                {



                    path: "assign",
                    icon: AssignmentReturn,
                    title: "Assign Followup",
                    ...loggedInPathElementRender(user.isLoggedIn, [MODULES.ASSIGN_FOLLOWUP], allowedModules,<AssignBeneficiaryFollowUpController />, defaultRedirect)
                    
                },
                {



                    path: "",
                    icon: List,
                    title: " Followup List",
                    ...loggedInPathElementRender(user.isLoggedIn, [MODULES.FOLLOWUP_LIST], allowedModules, <FollowupListController />, defaultRedirect)
                    
                },
                {



                    path: "inclusive",
                    icon: List,
                    title: "Inclusive Followup List",
                    ...loggedInPathElementRender(user.isLoggedIn, [MODULES.FOLLOWUP_LIST], allowedModules, <FollowupListController key={FOLLOWUP_TYPE.INCLUSIVE} type={FOLLOWUP_TYPE.INCLUSIVE} />, defaultRedirect)
                    
                },
            ],
        },





        {
            path: "regions",
            icon: <Map />,
            title: "Regions",
            ...loggedInPathElementRender(user.isLoggedIn, [MODULES.REGIONS], allowedModules, <AppContainer ><RegionListController /></AppContainer>, defaultRedirect)
            // hideInPannel: !allowedModules.includes(MODULES.REGIONS),
            // element: user.isLoggedIn ? allowedModules.includes(MODULES.REGIONS) ? <AppContainer ><RegionListController /></AppContainer> : <NotAllowedComponent /> : <Navigate replace to="/sign-in" />
        },

        {
            path: "products",
            icon: <ProductionQuantityLimitsIcon />,
            title: "Products",
            ...loggedInPathElementRender(user.isLoggedIn, [MODULES.PRODUCTS], allowedModules, <AppContainer ><ProductListController /></AppContainer>, defaultRedirect)
            // hideInPannel: !allowedModules.includes(MODULES.REGIONS),
            // element: user.isLoggedIn ? allowedModules.includes(MODULES.REGIONS) ? <AppContainer ><RegionListController /></AppContainer> : <NotAllowedComponent /> : <Navigate replace to="/sign-in" />
        },

        {



            path: "camps",
            icon: <Campaign />,
            title: "Camps",
            ...loggedInPathElementRender(user.isLoggedIn, [MODULES.CAMPS], allowedModules, <AppContainer ><CampController /></AppContainer>, defaultRedirect)
            // element: user.isLoggedIn ? <AppContainer ><CampController /></AppContainer> : <Navigate replace to="/sign-in" />
        },
        {
            path: "admin",
            icon: <Settings />,
            title: "Admin Area",
            // hideInPannel: user.data.role != USER_ROLES.ADMIN,
            // element: user.isLoggedIn ? <AppContainer /> : <Navigate replace to="/sign-in" />,
            ...loggedInPathElementRender(user.isLoggedIn, [MODULES.ADMIN_AREA], allowedModules, <AppContainer />, defaultRedirect),

            children: [



                {
                    path: "secrets",
                    title: "Secrets Management",
                    icon: Key,

                    element: <ListController />
                },
                {
                    path: "docs",
                    title: "Docs",
                    icon: Folder,

                    element: <DocumentsListController />
                }
            ],
        },

        {
            path: "users",
            icon: <People />,
            title: "Users",
            // hideInPannel: !allowedModules.includes(MODULES.USER_MODULE),

            // element: user.isLoggedIn ? allowedModules.includes(MODULES.USER_MODULE) ? <AppContainer ></AppContainer> : <NotAllowedModule /> : <Navigate replace to="/sign-in" />,

            ...loggedInPathElementRender(user.isLoggedIn, [MODULES.USER_MODULE], allowedModules, <AppContainer />, defaultRedirect),
            children: [
                {
                    path: "all",
                    icon: People,
                    title: "All",
                    ...loggedInPathElementRender(user.isLoggedIn, [MODULES.ALL_USER], allowedModules, <UserListController />, defaultRedirect),
                    // hideInPannel: !allowedModules.includes(MODULES.ALL_USER),

                    // element: user.isLoggedIn ? allowedModules.includes(MODULES.ALL_USER) ? <UserListController /> : <NotAllowedComponent /> : <Navigate replace to="/sign-in" />,
                },
                
                {
                    path: "teachers",
                    icon: School,
                    title: "Teachers",
                    ...loggedInPathElementRender(user.isLoggedIn, [MODULES.TEACHER], allowedModules, <TeacherListController />, defaultRedirect)
                    // hideInPannel: !allowedModules.includes(MODULES.REGION_MODULE),
                    // element: user.isLoggedIn ? <TeacherListController /> : <Navigate replace to="/sign-in" />
                },
                {
                    path: "donors",
                    icon: VolunteerActivism,
                    title: "Donors",
                    // hideInPannel: !allowedModules.includes(MODULES.REGION_MODULE),
                    // element: user.isLoggedIn ? <DonorListController /> : <Navigate replace to="/sign-in" />

                    ...loggedInPathElementRender(user.isLoggedIn, [MODULES.DONORS], allowedModules, <DonorListController />, defaultRedirect)
                },
                {
                    path: "ngo",
                    icon: Bloodtype,
                    title: "NGO",
                    // hideInPannel: !allowedModules.includes(MODULES.REGION_MODULE),
                    // element: user.isLoggedIn ? <NGOListController /> : <Navigate replace to="/sign-in" />
                    ...loggedInPathElementRender(user.isLoggedIn, [MODULES.NGOS], allowedModules, <NGOListController />, defaultRedirect)
                },
                {
                    path: "government",
                    icon: LocalPolice,
                    title: "Government",
                    ...loggedInPathElementRender(user.isLoggedIn, [MODULES.GOVEREMENT], allowedModules, <GovListController />, defaultRedirect)
                    // hideInPannel: !allowedModules.includes(MODULES.REGION_MODULE),
                    // element: user.isLoggedIn ? <GovListController /> : <Navigate replace to="/sign-in" />
                }

            ]
        },
        {
            path: "*",
            hideInPannel: true,
            element: !user.isLoggedIn ? <Navigate replace to={"/sign-in"} /> : <PagenotFound />,

        },
    ])
}
export default defineRoutes


/* 

================================================================
Demo Object
================================================================
        {
            path: "sign-in",
            element: !user.isLoggedIn ? <SignInController /> : <Navigate replace to="/leaves" />,
            
            title: "Leave Management", // it will use as name in side navbar
            component:<></>


             children: [
                {
                    path: "",
                    title: "My Leaves",// it will use as name in side navbar
                    element: <MyLeaveController />,
                    
                }
            ],
        },




================================================================
================================================================


*/