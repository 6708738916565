import { Box, Collapse, IconButton, Paper, SwipeableDrawer, useMediaQuery, useTheme } from "@mui/material"
import { useSelector, useDispatch } from 'react-redux'
import { Outlet, useLocation, useNavigate } from "react-router-dom"



import { useEffect, useMemo, useState } from "react"
import { memo } from "react"
import AppModeLabel from "../../texts/AppModeLabel"
import PopUpModal from "../../Modal"
import Logo from "./Logo"
import Header from "./Header"
import Navbar from "./Navbar"



const containerStyle = (theme) => ({ height: "100%", width: "100%", display: "flex", background: theme.palette.light.main })
const navBarOuterStyle = (theme) => ({ height: "100%", position: "relative" })
const collapsableContainerStyle = (theme) => ({ height: "100%", width: "100%", display: "flex", flexDirection: "column" })
const navbarInnerStyle = (theme) => ({
  width: "260px",
  background: "white",
  height: "100%",
  overflowY: "auto",
  borderRadius: "0px",
  scrollBarWidth: "0px",
  " -ms-overflow-style": "none",
  "::-webkit-scrollbar": {
    display: 'none'
  }
})

const logoContainer = (theme) => ({ width: "100%", position: "sticky", top: "0px", backgroundColor: theme.palette.light.main, zIndex: "1",height:"75px" })
const rightContainerStyle = (theme) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  overflowY: "scroll",

})
const workAreaStyle = (theme) => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  // overflow:"hidden",
  flex: 1,
  
  background: theme.palette.grey.main,
  borderTopLeftRadius: theme.shape.borderRadius * 1

})


const AppContainer = (props) => {

  const dispatch = useDispatch()

  const [navBar, setNavbar] = useState(true)


  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"))
  

  return (
    <>
      {
        process.env.REACT_APP_APP_MODE != 'production' && <AppModeLabel />
      }

      <PopUpModal />

      <Box sx={containerStyle}>
        {!isSmallScreen && <Box elevation={2} sx={navBarOuterStyle}>

          <Collapse orientation="horizontal" in={navBar} sx={collapsableContainerStyle}>
            <Paper elevation={0} sx={navbarInnerStyle}>
              <Box pt={1} sx={logoContainer}>
                <Logo mb={4} mt={3} />
              </Box>
              <Navbar />
              
            </Paper>
          </Collapse>
        </Box>
        }
        {
          isSmallScreen &&
          <SwipeableDrawer
            anchor={"left"}
            open={navBar}
            onClose={()=>setNavbar(false)}
            onOpen={()=>setNavbar(true)}
          >
            <Paper elevation={0} sx={navbarInnerStyle}>
              <Box pt={1} sx={logoContainer}>
                <Logo mb={4} mt={3} />
              </Box>
              <Navbar />
              
            </Paper>
          </SwipeableDrawer>
        }
        <Box sx={rightContainerStyle}>
          <Header open={navBar} setOpen={setNavbar} />
          <Box p={3} sx={workAreaStyle}>
            {props.children}
            <Outlet />
          </Box>
        </Box>
      </Box>


    </>
  )
}
export default memo(AppContainer)