import { useEffect, useState } from "react"
import DashboardUi from "./DashboardUi"
import { REGIONS_TYPE } from "../../utils/constants"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import { getDashboardApi } from "../../apis/dashboard.api"
import { AirlineSeatReclineNormalOutlined } from "@mui/icons-material"

const DashboardController = () => {

    const dispatch = useDispatch()
    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['name'],
        sort: '',
        type: REGIONS_TYPE.STATE,
        isDropDown:true,
        donation_event_id:null,
        // event:null,
        donor:null,
        sortDirection: 1,
        country:null,
        product:null
    })
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})
    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await getDashboardApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    useEffect(() => {
        fetchList()
    }, [filters])

    return <DashboardUi
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
    />
}
export default DashboardController

