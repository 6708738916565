import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListOfDonationUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"

import { openModal } from "../../../store/actions/modalAction"

import { Chip } from "@mui/material"
import { useMemo } from "react"



import { getBeneficiaryApi, getCountsOfDonationBeneficiaryApi } from "../../../apis/beneficiary.api"
import moment from "moment"
import { BeneficiaryActionComponent } from "../ListController"
import { createFullName, findObjectKeyByValue } from "../../../utils/helper"
import { BENIFICIARY_STATUS } from "../../../utils/beneficiary.constant"
import fileDownload from "js-file-download"



const ListOfDonationController = ({donated,nuResponse}) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    const title = "Beneficiary Data "
    const modalKey = 'beneficiary'
    const fetchCountsApi = getCountsOfDonationBeneficiaryApi
    const fetchApi = getBeneficiaryApi



    const columns = useMemo(() => [
        { id: 0, fieldName: 'createdAt', label: 'Date', align: "left", sort: true, renderValue: (fields) => moment(fields.createdAt).format("DD/MM/YYYY") },
        { id: 1, fieldName: 'name', label: 'Name', align: "left", sort: true, renderValue: (params) => createFullName(params.surname,params.name) },
        // { id: 4, fieldName: 'udise_no', label: 'UDISE No', align: "left", sort: true },
        { id: 3, fieldName: 'phone', label: 'Phone No.', align: "left" },
        { id: 3, fieldName: 'district.name', label: 'District', align: "left", sort: true, renderValue: (params) => params.district?.name },
        { id: 8, fieldName: 'status', label: 'Status', align: "left", sort: true, renderValue: (params, setParams) => <Chip size="small" label={findObjectKeyByValue(params.status, BENIFICIARY_STATUS)} /> },
        { id: 7, fieldName: 'action', label: 'Action', align: "left", renderValue: (params, setParams) => <BeneficiaryActionComponent params={params} setParams={setParams} /> },


    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['name', 'surname','phone'],
        sort: 'createdAt',
        validated:true,
        nuResponse,
        donated,        
        donation_event_id:null,
        camp: null,
        country: null,
        state: null,
        district: null,
        status: null,
        taluka: null,
        sortDirection: -1,
        product:null
    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})

    const [countsLoading, setCountsLoading] = useState(false)
    const [counts, setCounts] = useState([])
    const [childCount, setChildCounts] = useState([])
    const [exportLoading, setexportLoading] = useState(false)






    const fetchList = (isExport) => {
    //     setLoading(true)
    //     dispatch(callApiAction(
    //         async () => await fetchApi({ ...filters }),
    //         (response) => {
    //             setList(response)
    //             setLoading(false)
    //         },
    //         (err) => {
    //             setLoading(false)
    //         }
    //     ))
    if (!isExport) {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    } else {
        setexportLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters, csv: true }),
            (response) => {
                fileDownload(response, "beneficiary.csv")
                setexportLoading(false)
            },
            (err) => {
                console.log(err)
                setexportLoading(false)
            },
            true
        ))
    }
    }

    const fetchCounts = () => {
        setCountsLoading(true)
        dispatch(callApiAction(
            async () => await fetchCountsApi({ ...filters }),
            (response) => {
                setCounts(response)
                setCountsLoading(false)
            },
            (err) => {
                setCountsLoading(false)
            }
        ))
    }
    const onExportBtnClick = () => {
        fetchList(true)
    }

    useEffect(() => {
        fetchCounts()
    }, [filters.startDate,filters.endDate,filters.country,filters.district,filters.state,filters.taluka,filters.camp,filters.donation_event_id,filters.product])

    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
            childCount={childCount}
            setChildCounts={setChildCounts}
            exportLoading={exportLoading}
            onExportBtnClick={onExportBtnClick}
                countsLoading={countsLoading}
                counts={counts}
                title={title}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListOfDonationController