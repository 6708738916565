import { OpenInNew } from "@mui/icons-material"
import { Autocomplete, Button, ButtonGroup, Paper, ListItem, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import SubmitButton from "../../../components/button/SubmitButton"
import { StyledSearchBar, UserSearchBarNormal } from "../../../components/inputs/SearchBar"
import { REGIONS_TYPE, USER_ROLES } from "../../../utils/constants"
import DataTable from "../../../components/tables/DataTable"
import { findObjectKeyByValue, titleCase, toTitleCase } from "../../../utils/helper"
import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"
import { getRegionApi } from "../../../apis/region.api"
import { getCampApi } from "../../../apis/camp.api"
import { BENIFICIARY_STATUS } from "../../../utils/beneficiary.constant"
import TimeRangeSelector from "../../../components/layouts/common/TimeRangeSelector"
import { getProductApi } from "../../../apis/product.api"
import AsynSearchBar from "../../../components/inputs/AsynSearchBar"
import { getEventApi } from "../../../apis/event.api"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list, exportLoading, loading, onExportBtnClick, columns ,callBackFun}) => {
   
    const user = useSelector(state => state.user)
   
    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                <Box mb={4} >
                        <FilterTitleBox>
                            <Typography variant="h5" mb={2} >{title}</Typography>
                        </FilterTitleBox>
                        </Box>
                    <FiltersBox mb={3}>
                        <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }}  >
                            <Box sx={{ display: "flex",flexWrap:"wrap" }}  >
                                <Box mr={1}>
                               
                                </Box>
                                <Box>
                                {/* {<SubmitButton loading={exportLoading} variant="contained" onClick={onExportBtnClick} title={"Export CSV"} />} */}
                                </Box>
                            </Box>
                        </PaddingBoxInDesktop>

                        <PaddingBoxInDesktop mb={2} sx={{
                            display: "flex", flex: 1, justifyContent: "flex-end", "*": {
                                justifyContent: "center"
                            }
                        }} pl={2} >

                            <AsynSearchBar

                                fullWidth
                                title="Search By Event Name"
                                size="small"
                                placeholder={"Search By Event Name"}
                                defaultValue={filters.search}
                                onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                            />
                              </PaddingBoxInDesktop>
                    </FiltersBox>
                    <Box sx={{ minHeight: "300px" }} className="table">
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi