import { Box, Chip, CircularProgress, Grid, Typography, styled } from "@mui/material"
import ImageComponent from "../../../../components/inputs/ImageComponent"
import moment from "moment"
import { findObjectKeyByValue } from "../../../../utils/helper"
import { GENDER, VIDEOS_STATUS } from "../../../../utils/constants"
import FileDownloadComponent from "../../../../components/FileDownloadComponent"
import NoDataComponent from "../../../../components/layouts/NoDataComponent"
import { BENIFICIARY_LOG_STATUS } from "../../../../utils/beneficiary.constant"
import CustomDialog from "../../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../../components/layouts/OneViewBox"

const LogContainer = styled(Box)(({ theme }) => ({
    border: '1px solid ' + theme.palette.primary.main,
    padding: theme.spacing(2),
    background: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius * 2,

    width: "100%"
}))
const Body = styled(Box)(({ theme }) => ({
    border: '1px solid ' + theme.palette.primary.main,
    padding: theme.spacing(2),

    marginBottom: theme.spacing(2),

    borderBottomLeftRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
    width: "100%"
}))
const VideosLogDetails = ({ list,modalKey,loading }) => {

    return <>
     <CustomDialog
            id={modalKey}
            loading={loading}
            title={`Videos Logs`}
            closeText="Close"

        >   {
            loading ? <CenteredBox sx={{ minHeight: "200px" }}><CircularProgress /></CenteredBox> :
                
                <>
             {list.videos && list.videos.length &&
                 <>
            {list?.videos?.map((item)=> item.logs.length>0 ? item.logs.map((log)=>
           
           <LogContainer key={log._id} >
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={6} >
                                <Typography fontWeight="bold" variant="body2">Actioned By:</Typography>

                            </Grid>
                            <Grid item md={6} xs={6} >
                                <Typography variant="body2">{log.by ? log.by.name : "NA"}</Typography>
                            </Grid>

                            <Grid item md={6} xs={6} >
                                <Typography fontWeight="bold" variant="body2">Date:</Typography>

                            </Grid>
                            <Grid item md={6} xs={6} >
                                <Typography variant="body2">{moment(log.date).format("DD MMM YYYY")}</Typography>
                            </Grid>



                            <Grid item md={6} xs={6} >
                                <Typography fontWeight="bold" variant="body2">Vidoes Status:</Typography>

                            </Grid>
                            <Grid item md={6} xs={6} >
                                <Typography variant="body2"><Chip color="info" size="small" label={log.video_status!=null && log.video_status!=undefined  ? findObjectKeyByValue(log.video_status, VIDEOS_STATUS) : "NA"} /></Typography>
                            </Grid>

                           
                        </Grid>
                    </LogContainer>): 
                 <NoDataComponent message="No log found" /> )}
                    </> 
}
</>
}
        </CustomDialog>
    </>
}
export default VideosLogDetails