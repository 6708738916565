import { Autocomplete, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo } from "react"
import { useDispatch, useSelector } from 'react-redux'



import CustomInput from "../../components/inputs/CustomInput"
import { REGIONS_TYPE, THIRD_PART_APPS_USED, USER_ROLES } from "../../utils/constants"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import CreatableRegionComponent from "../../components/custom/CreateableRegionComponent"
import CreatableDropDownComponent from "../../components/custom/CreateableDropodown"
import UserCreateController from "../user/CreateController"
import { getDonorsApi, getNgosApi } from "../../apis/user.api"
import { MobileDatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import FileInput from "../../components/inputs/FileInput"
import { EVENT_STATUS } from "../../utils/beneficiary.constant"
import MODULES from "../../utils/module.constant"



const CreateUi = ({ title, modalKey, isUpdate, fields, setFields, loading, onSubmit, fetchLoading }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog
            id={modalKey}
            loading={loading || fetchLoading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {(loading || fetchLoading) && <CenteredBox><CircularProgress /></CenteredBox>}
            {!fetchLoading && <>
                {<CustomInput
                    autoFocus={true}
                    disabled={loading}
                    value={fields.name}
                    onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                    type="text"
                    label={"Event Name*"}

                />}


                {<CustomInput
                    
                    disabled={loading}
                    value={fields.description}
                    onChange={(e) => setFields({ ...fields, err: '', description: e.target.value })}
                    multiline
                    rows={3}
                    type="text"
                    label={"Event Description*"}

                />}
                {(!isUpdate || (isUpdate && fields.status != EVENT_STATUS.CLOSED)) && <MobileDatePicker
                    value={moment(fields.date)}
                    inputFormat="DD/MM/YYYY"
                    onChange={(val) => {
                        setFields({ ...fields, err: '', date: val.toISOString() })
                    }}
                    label="Event Date*"
                    renderInput={(params) => <CustomInput placeholder="Event Date*"  {...params} />}
                />}
                {<CustomInput
                    
                    disabled={loading}
                    value={fields.venue}
                    onChange={(e) => setFields({ ...fields, err: '', venue: e.target.value })}
                    multiline
                    rows={3}
                    type="text"
                    label={"Event Venue*"}

                />}
                <CreatableRegionComponent
                    disabled={loading}
                    value={typeof fields.country == "object" ? fields.country : null}
                    label={"Select Country*"}
                    type={REGIONS_TYPE.COUNTRY}
                    parent={null}
                    onChange={async (changedVal) => {

                        setFields({ ...fields, country: (changedVal ? changedVal._id : null), state: null, district: null, taluka: null })
                    }}
                />



                <CreatableRegionComponent
                    disabled={loading || !fields.country || fields.country == null}
                    value={typeof (fields.state) == "object" ? fields.state : null}

                    type={REGIONS_TYPE.STATE}

                    onChange={async (changedVal) => {

                        setFields({ ...fields, state: changedVal ? changedVal._id : null, district: null, taluka: null })
                    }}
                    parent={fields.country && typeof (fields.country) == "object" ? fields.country['_id'] : fields.country}
                    label={"Select State*"}

                />


                <CreatableRegionComponent
                    disabled={loading || !fields.state || fields.state == null}
                    value={typeof fields.district == "object" ? fields.district : null}
                    type={REGIONS_TYPE.DISTRICT}

                    onChange={async (changedVal) => {
                        setFields({ ...fields, district: changedVal ? changedVal._id : null, taluka: null })
                    }}
                    label={"Select District*"}
                    parent={fields.state && typeof (fields.state) == "object" ? fields.state['_id'] : fields.state}
                />

                <CreatableRegionComponent
                    disabled={loading || !fields.district || fields.district == null}
                    value={typeof fields.taluka == "object" ? fields.taluka : null}
                    type={REGIONS_TYPE.TALUKA}


                    onChange={async (changedVal) => {

                        setFields({ ...fields, taluka: changedVal ? changedVal._id : null })
                    }}
                    label={"Select Taluka*"}
                    parent={fields.district && typeof (fields.district) == "object" ? fields.district['_id'] : fields.district}
                />

                {!isUpdate && <CreatableDropDownComponent
                    dropdownProps={{
                        multiple: true,
                    }}
                    onlyDropDown={!user.data?.modules?.includes?.(MODULES.DONORS)}
                    createComponent={<UserCreateController role={USER_ROLES.DONOR} />}
                    modalKey='users'
                    disabled={loading}
                    value={fields.donors}
                    label={"Select Donors*"}
                    getApi={async (params) => await getDonorsApi(params)}
                    onChange={async (changedVal) => {

                        setFields({ ...fields, donors: changedVal && Array.isArray(changedVal) ? changedVal.map((item) => item._id) : [] })
                    }}

                />
                }
                {!isUpdate && <CreatableDropDownComponent
                    dropdownProps={{
                        multiple: true,
                    }}
                    onlyDropDown={!user.data?.modules?.includes?.(MODULES.NGOS)}
                    createComponent={<UserCreateController role={USER_ROLES.NGO} />}
                    modalKey='users'
                    disabled={loading}
                    value={fields.ngos}
                    label={"Select NGOs*"}
                    getApi={async (params) => await getNgosApi(params)}
                    onChange={async (changedVal) => {

                        setFields({ ...fields, ngos: changedVal && Array.isArray(changedVal) ? changedVal.map((item) => item._id) : [] })
                    }}

                />}

                <FileInput
                    onDelete={() => { setFields({ ...fields, logo: '' }) }}
                    defaults={fields.logo ? [fields.logo] : undefined}
                    title="Event Profile Image*"
                    subTitle="Only png, jpg, jpeg are required."
                    onlyImage
                    onChange={(newUrl) => {
                        setFields({ ...fields, err: '', logo: newUrl })
                    }} />

            </>}

        </CustomDialog>
    </>
}
export default memo(CreateUi)