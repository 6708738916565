import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";

export const getUserAndSystemDataApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.userAndSystemFetchByToken,
    method: "get",
    headers: getHeaders(),
    params: data
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const getUserApi = async params => {
  const callResponse = await axios({
    url: endpoints.userBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getUserByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.userById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addUserApi = async data => {
  const callResponse = await axios({
    url: endpoints.userBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateUserField = async data => {
  const callResponse = await axios({
    url: endpoints.userBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const updateUserSignature = async data => {
  const callResponse = await axios({
    url: endpoints.userSignature,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateModulesPermission = async data => {
  const callResponse = await axios({
    url: endpoints.userBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updatPasswordApi = async data => {
  const callResponse = await axios({
    url: endpoints.userPassword,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const deleteUserApi = async data => {
  const callResponse = await axios({
    url: endpoints.userBase,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


//-----------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------

export const getTeachersApi = async params => {
  const callResponse = await axios({
    url: endpoints.teacherBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getTecaherByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.teacherBase + params.id,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateTecaherField = async data => {
  const callResponse = await axios({
    url: endpoints.teacherBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


//-----------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------

export const getDonorsApi = async params => {
  const callResponse = await axios({
    url: endpoints.donorBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getDonorByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.donorBase + params.id,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateDonorField = async data => {
  const callResponse = await axios({
    url: endpoints.donorBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};



//-----------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------
export const getNgosApi = async params => {
  const callResponse = await axios({
    url: endpoints.ngoBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getNgoByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.ngoBase + params.id,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateNgoField = async data => {
  const callResponse = await axios({
    url: endpoints.ngoBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

//-----------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------

export const getGovsApi = async params => {
  const callResponse = await axios({
    url: endpoints.govBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getGovByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.govBase + params.id,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateGovField = async data => {
  const callResponse = await axios({
    url: endpoints.govBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};




//-----------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------






export default getUserAndSystemDataApi