import { memo,  useRef, useState } from "react"
import UpdateSignatureUI from "./UpdateSignatureUI"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"

import { closeModal } from "../../../store/actions/modalAction"
import {  getTecaherByIdApi,  updateTecaherField, updateUserSignature } from "../../../apis/user.api"
import { uploadImage } from "../../../apis/file.api"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants"

const UpdateSignatureController = ({ callBack = () => { }, id ,oldSignature}) => {
    
    const dispatch = useDispatch()
    
    const modalKey = 'users-signature'
    const title = "Signature "

    

    const [loading, setLoading] = useState(false)

    const [fetchLoading, setFetchLoading] = useState(false)

    const ref = useRef()

    const [fields, setFields] = useState({
        err: '',
        id,
        signature: '',


    })
  



 


    function dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        var ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], { type: mimeString });
        return blob;

    }
    const onSubmit = async (e) => {
        e.preventDefault()
        console.log(id)
        if (id && ref) {
            const dataUrlStr = ref.current.getCanvas().toDataURL('image/png')
            
            const blobData = dataURItoBlob(dataUrlStr)
            const fileVal = new File([blobData], "signature-" + new Date().getTime() + ".png")
            const formData = new FormData()
            formData.append('image',fileVal)
            formData.append('noCompression', true)
            setLoading(true)
            dispatch(callApiAction(
                async () => await uploadImage(formData),
                (response) => {                    
                    dispatch(callApiAction(
                        async () => await updateUserSignature({ id, signature: response.url }),
                        () => {

                            dispatch(closeModal(modalKey))
                            dispatch(callSnackBar("Signature Updated Successfully", SNACK_BAR_VARIETNS.suceess))
                            callBack(response.url)
                            setLoading(false)
                        },
                        (err) => {
                            dispatch(callSnackBar(err.message, SNACK_BAR_VARIETNS.error))
                            setLoading(false)
                        }
                    ))
                },
                (err) => {
                    dispatch(callSnackBar(err.message, SNACK_BAR_VARIETNS.error))
                    setLoading(false)
                }
            ))

        }


    }


    const onClear = () => {
        if (ref.current) {
            ref.current.clear()
        }
    }



    return <UpdateSignatureUI oldSignature={oldSignature} onClear={onClear} ref={ref} modalKey={modalKey} title={title} fetchLoading={fetchLoading} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(UpdateSignatureController)