import { Box, Button, ListItem } from "@mui/material"
import AsyncDropDown from "../inputs/AsyncDropDown"
import { getRegionApi } from "../../apis/region.api"
import CustomInput from "../inputs/CustomInput"
import { Add, AddBox } from "@mui/icons-material"
import { CenteredBox } from "../layouts/OneViewBox"
import { useDispatch } from "react-redux"
import { openModal } from "../../store/actions/modalAction"


const CreatableDropDownComponent = ({ onChange, value, label, createComponent, modalKey, disabled, getApi = async () => { }, dropdownProps = {},onlyDropDown }) => {
    const dispatch = useDispatch()
    const onCreateButtonClick = () => {
        dispatch(openModal(createComponent ?? <></>, "sm", false, modalKey))
    }
    return <Box sx={{ display: "flex" }} mt={3} mb={2}>
        <AsyncDropDown

            disabled={disabled}
            lazyFun={getApi}
            
            // OptionComponent={({ option, ...rest }) => {
            //     return <ListItem {...rest}>{option.name}</ListItem >
            // }}
            onChange={async (changedVal) => {

                onChange(changedVal)
            }}
            titleKey={'name'}
            valueKey={"_id"}
            defaultVal={value}
            label={label}
            InputComponent={(params) => <CustomInput size="small" placeholder={label} {...params} margin="none" />}
            {...dropdownProps}
        />
       {!onlyDropDown&& <Button onClick={onCreateButtonClick} variant="contained" size="small" sx={{ ml: 1 }} >
            <Add />
        </Button>}
    </Box>
}
export default CreatableDropDownComponent