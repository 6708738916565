import { Box, CircularProgress, Grid, Paper, Tab, Tabs, Typography, styled } from "@mui/material"
import { useState } from "react"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"

import { FOLLOW_UP_LOG_TYPE, FOLLOW_UP_STATUS, HEARING_STATE, SPEECH_STATE } from "../../utils/beneficiary.constant"
import moment from "moment"
import { findObjectKeyByValue } from "../../utils/helper"

const Header = styled(Box)(({ theme }) => ({
    border: '1px solid ' + theme.palette.primary.main,
    padding: theme.spacing(2),
    background: theme.palette.secondary.main,
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderBottom: "0px",
    borderTopRightRadius: theme.shape.borderRadius * 2,
    width: "100%"
}))
const Body = styled(Box)(({ theme }) => ({
    border: '1px solid ' + theme.palette.primary.main,
    padding: theme.spacing(2),

    marginBottom: theme.spacing(2),

    borderBottomLeftRadius: theme.shape.borderRadius * 2,
    borderBottomRightRadius: theme.shape.borderRadius * 2,
    width: "100%"
}))
const FollowUpDetailedViewUi = ({ data, loading, setData, modalKey }) => {
    const [tab, setTab] = useState(0)
    const handleTabChange = (e, newVal) => {
        setTab(newVal)
    }


    return <>
        <CustomDialog
            id={modalKey}
            loading={loading}
            title={`Followup Details`}
            closeText="Close"
            dialogProps={{ width: "100%" }}

        >

            {
                loading ? <CenteredBox><CircularProgress size={30} /></CenteredBox> : <>

                    <Header>
                        <Typography fontWeight="bold" variant="body1">
                            Details
                        </Typography>
                    </Header>
                    <Body>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>

                                <Grid container spacing={2}>
                                    <Grid item md={4} xs={6} >
                                        <Typography fontWeight="bold" variant="body2">Name:</Typography>

                                    </Grid>
                                    <Grid item md={8} xs={6} >
                                        <Typography variant="body2" textTransform="capitalize" >{data.name}</Typography>
                                    </Grid>


                                    <Grid item md={4} xs={6} >
                                        <Typography fontWeight="bold" variant="body2">Phone:</Typography>

                                    </Grid>
                                    <Grid item md={8} xs={6} >
                                        <Typography variant="body2">{data.phone}</Typography>
                                    </Grid>


                                    <Grid item md={4} xs={6} >
                                        <Typography fontWeight="bold" variant="body2">Assigned To:</Typography>

                                    </Grid>
                                    <Grid item md={8} xs={6} >
                                        <Typography variant="body2"  textTransform="capitalize">{data.assigned_to ? data.assigned_to.name : "NA"}</Typography>
                                    </Grid>


                                    <Grid item md={4} xs={6} >
                                        <Typography fontWeight="bold" variant="body2">Assigned By:</Typography>

                                    </Grid>
                                    <Grid item md={8} xs={6} >
                                        <Typography variant="body2"  textTransform="capitalize">{data.assigned_by ? data.assigned_by.name : "NA"}</Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography fontWeight="bold" variant="body2">Followup No:</Typography>

                                    </Grid>
                                    <Grid item md={8} xs={6} >
                                        <Typography variant="body2">{data.followup_no}</Typography>
                                    </Grid>


                                    <Grid item md={4} xs={6} >
                                        <Typography fontWeight="bold" variant="body2">Last Followup No:</Typography>

                                    </Grid>
                                    <Grid item md={8} xs={6} >
                                        <Typography variant="body2">{data.last_followup_no}</Typography>
                                    </Grid>

                                    <Grid item md={4} xs={6} >
                                        <Typography fontWeight="bold" variant="body2">Date:</Typography>

                                    </Grid>
                                    <Grid item md={8} xs={6} >
                                        <Typography variant="body2">{moment(data.scheduled_date).format("DD/MM/YYYY")}</Typography>
                                    </Grid>



                                    <Grid item md={4} xs={6} >
                                        <Typography fontWeight="bold" variant="body2">Last Remarks:</Typography>

                                    </Grid>
                                    <Grid item md={8} xs={6} >
                                        <Typography variant="body2">{data.last_remarks ? data.last_remarks : "NA"}</Typography>
                                    </Grid>

                                </Grid>
                            </Grid>

                        </Grid>
                    </Body>
                    {data.status == FOLLOW_UP_STATUS.FOLLOWED_UP && <>
                        <Header>
                            <Typography fontWeight="bold" variant="body1">
                                Progress
                            </Typography>
                        </Header>
                        <Body>
                            <Grid container spacing={2}>
                                <Grid item md={4} xs={6} >
                                    <Typography fontWeight="bold" variant="body2">Hearing Progress : </Typography>

                                </Grid>
                                <Grid item md={8} xs={6} >
                                    <Typography variant="body2">{findObjectKeyByValue(data.hearing_state, HEARING_STATE) ?? "NA"}</Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography fontWeight="bold" variant="body2">Speech Progress : </Typography>

                                </Grid>
                                <Grid item md={8} xs={6} >
                                    <Typography variant="body2">{findObjectKeyByValue(data.speech_state, SPEECH_STATE) ?? "NA"}</Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography fontWeight="bold" variant="body2"> Daily Usage : </Typography>

                                </Grid>
                                <Grid item md={8} xs={6} >
                                    <Typography variant="body2">{data.daily_usage?data.daily_usage+" H":0}</Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography fontWeight="bold" variant="body2"> Weekly Usage : </Typography>

                                </Grid>
                                <Grid item md={8} xs={6} >
                                    <Typography variant="body2">{data.weekly_usage?data.weekly_usage+" D":0}</Typography>
                                </Grid>

                                <Grid item md={4} xs={6} >
                                    <Typography fontWeight="bold" variant="body2">Weekly Average Usage : </Typography>

                                </Grid>
                                <Grid item md={8} xs={6} >
                                    <Typography variant="body2">{data.weekly_usage?(data.weekly_usage * data.daily_usage)+" H":0}</Typography>
                                </Grid>
                            </Grid>
                        </Body>

                    </>}

                    <Header>
                        <Typography fontWeight="bold" variant="body1">
                            Logs
                        </Typography>
                    </Header>
                    <Body p={3}>

                        {
                            data.logs && data.logs.length > 0 && data.logs.map((item) => {
                                return (
                                    <Paper mb={2} elevation={0} key={item._id} component={Box} p={2} sx={{border:1}} >
                                        <Grid container spacing={2}>
                                            <Grid item md={3} xs={6} >
                                                <Typography fontWeight="bold" variant="body2">Log Date : </Typography>

                                            </Grid>
                                            <Grid item md={3} xs={6} >
                                                <Typography variant="body2">{moment(item.date).format("DD/MM/YYYY")}</Typography>
                                            </Grid>


                                            <Grid item md={3} xs={6} >
                                                <Typography fontWeight="bold" variant="body2">Log Type : </Typography>

                                            </Grid>
                                            <Grid item md={3} xs={6} >
                                                <Typography variant="body2">{findObjectKeyByValue(item.type, FOLLOW_UP_LOG_TYPE) ?? "NA"}</Typography>
                                            </Grid>

                                            <Grid item md={12} xs={12} >
                                                <Typography fontWeight="bold" variant="body2">Remarks : </Typography>

                                            </Grid>
                                            <Grid item md={12} xs={12} >
                                                <Typography variant="body2">{item.remarks??"NA"}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>)

                            })
                        }
                    </Body>

                </>
            }
        </CustomDialog>
    </>
}
export default FollowUpDetailedViewUi