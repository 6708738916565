import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"
import { addBeneficiaryToEventApi, addImagesToEventApi, getEventByIdApi } from "../../apis/event.api"

import { closeModal } from "../../store/actions/modalAction"
import AddImagesToEventUi from "./AddImagesToEventUi"

const AddImagesToEventController = ({ id, callBack=()=>{} }) => {
    const dispatch = useDispatch()
    const [data, setData] = useState({})
    const modalKey = 'event-images-attach'
    const [loading, setLoading] = useState(false)




    const fetchById = () => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getEventByIdApi({ id, images: true }),
                async (response) => {
                    const { ...otherDetails } = response



                    setData({ ...otherDetails })
                    setLoading(false)
                },
                (err) => {
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    setLoading(false)
                }
            )
        )
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)

        dispatch(
            callApiAction(
                async () => await addImagesToEventApi({ ...data, id }),
                async (response) => {

                    
                    setLoading(false)
                    dispatch(callSnackBar('Images added to Event ' + data.name, SNACK_BAR_VARIETNS.suceess))
                    dispatch(closeModal(modalKey))
                  
                },
                (err) => {
                    setLoading(false)
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                }
            )
        )
    }
    
    useEffect(() => {
        fetchById()
    }, [id])

    const onImagesChange = (images)=>{
        setData({...data,images})
    }

    return <AddImagesToEventUi
 
        id={id}
        onSubmit={onSubmit}
        loading={loading}
        data={data}
        setData={setData}
        onImagesChange={onImagesChange}
        modalKey={modalKey}
    />
}
export default AddImagesToEventController