import { useState } from "react";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/actions/modalAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { callApiAction } from "../../../store/actions/commonAction";
import {
  updateBeneficiaryVideoRatingAndTagsApi,
  updateBeneficiaryVideoRatingsApi,
  updateBeneficiaryVideoUrlApi,
  updateVideoStatusApi,
} from "../../../apis/beneficiary.api";
import CustomInput from "../../../components/inputs/CustomInput";
import { SNACK_BAR_VARIETNS, VIDEOS_STATUS, VIDEOS_TAGS } from "../../../utils/constants";
import { Autocomplete, Box, Rating, Typography } from "@mui/material";
import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop";
import { StyledSearchBar } from "../../../components/inputs/SearchBar";
import { titleCase } from "../../../utils/helper";
import MessageDilog from "../../../components/MessageDilog";

const UpdateVideoStatusModal = ({
  // rating = 1,
  modalKey,
  beneficiary_name,
  video_id,
  // tags,
  followup_id,
  video_status,
  callBack = () => {},
}) => {
  const modalId = "update-video-status";
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    // rating,
    // tags,
    video_id,
    followup_id,
    video_status,
    err: "",
  });

  const onClose = () => {
    dispatch(closeModal(modalId));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(closeModal(modalId))
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await updateVideoStatusApi({ ...fields ,video_status:VIDEOS_STATUS.MANAGER_REJECT}),
        (response) => {
          dispatch(
            callSnackBar(
              "Video Status Updated successfully.",
              SNACK_BAR_VARIETNS.suceess
            )
          );
          setLoading(false);
          onClose(modalId);
          // callBack(fields);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  return (

    <CustomDialog
    id={modalId}
    loading={loading}
    err={fields.err}
    onSubmit={onSubmit}
    // title={`${"Update Video Status"}`}
    title={"Are you sure to update beneficiary video status " + beneficiary_name}
    onClose={onClose}
    message="On click of Update Status Manager Rejected"
    closeText={"Close"}
    confirmText={"Update"}
    >
    {/* <MessageDilog
            title={"Are you sure to update beneficiary video status " + beneficiary_name}
            message={"On click of Update Status Manager Rejected"}
            onSubmit={onSubmit}
            loading={loading}
            // confirmText={}.
            onClose={onClose}
            modalKey={"update-video-status"}
          />  */}
  
     </CustomDialog>

//  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}> 
// <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }} mx={2}>
//   <Typography>Video Rating</Typography>
//   <Rating
    
//     name="ratings-controlled"
//     value={fields.rating}
//     onChange={(event, newValue) => {
//       setFields({ ...fields, rating: newValue });
//     }}
//   />
// </Box>
// <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
//   <Typography mx={2}>Video Status</Typography>
//   <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} pl={2}>
//     <Autocomplete
//       disableClearable
//       onChange={(e, changedVal) => {
//         setFields({
//           ...fields,
//           video_status: changedVal ? changedVal._id : null,
//         });
//       }}
//       options={[
//         // { label: "All", _id: null },
//         ...Object.keys(VIDEOS_STATUS).map((key) => ({
//           label: titleCase(key),
//           _id: VIDEOS_STATUS[key],
//         })),
//       ]}
//       sx={{
//         width: "100%",
//         display: "flex",
//         "*": { display: "flex", justifyContent: "center" },
//       }}
//       renderInput={(params) => (
//         <StyledSearchBar placeholder="Select Status" {...params} />
//       )}
//     />
//   </PaddingBoxInDesktop>
// </Box>
// </Box>
  );
};
export default UpdateVideoStatusModal;
