import { Box, Button, ButtonGroup, Chip, CircularProgress, Grid, ListItem, Paper, Typography } from "@mui/material"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import NoDataComponent from "../../components/layouts/NoDataComponent"
import { NoAccounts } from "@mui/icons-material"
import { useDispatch } from "react-redux"
import BeneficiaryDetailedViewController from "../beneficiary/detailedview/DetailedViewController"
import { openModal } from "../../store/actions/modalAction"
import { useCallback } from "react"
import BeneficiaryFetchController from "../event/beneficiarymodal/BeneficiaryFetchController"
import { BENIFICIARY_STATUS } from "../../utils/beneficiary.constant"
import { getUserApi } from "../../apis/user.api"
import { USER_ROLES } from "../../utils/constants"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import SubmitButton from "../../components/button/SubmitButton"
// import BeneficiaryFetchCampController from "./beneficiarymodal/BeneficiaryFetchController"

const AssignBeneficiaryFollowupUi = ({ data, loading, onSubmit, benficiaryList, onDeleteBeneficiary, onAddBeneficiary, setFields, fields }) => {
    const dispatch = useDispatch()

    const onBenificaryClick = useCallback((beneficiaryId) => {
        dispatch(openModal(<BeneficiaryDetailedViewController
            id={beneficiaryId}

        />, "md", false, "beneficiary-detailed-view"))
    }, [])

    const onAddFromCampClick = useCallback((beneficiaryId) => {
        dispatch(openModal(<BeneficiaryFetchController

            status={BENIFICIARY_STATUS.DONATED}
            callBack={onAddBeneficiary}

        />, "sm", false, "beneficiary-by-paramas"))
    }, [onAddBeneficiary])


    const onAddFromRegionClick = useCallback((beneficiaryId) => {
        dispatch(openModal(<BeneficiaryFetchController
            type="region"
            status={BENIFICIARY_STATUS.DONATED}
            callBack={onAddBeneficiary}

        />, "sm", false, "beneficiary-by-paramas"))
    }, [onAddBeneficiary])

    const onEventClick = useCallback((beneficiaryId) => {
        dispatch(openModal(<BeneficiaryFetchController
            type="event"
            status={BENIFICIARY_STATUS.DONATED}
            callBack={onAddBeneficiary}

        />, "sm", false, "beneficiary-by-paramas"))
    }, [onAddBeneficiary])


    return (


        <Box mb={1} sx={{ display: "flex", flex: 1 }}>
            <Paper elevation={2} sx={{ width: "100%", padding: 4, paddingBottom: 2, display: "flex", flex: 1, flexDirection: "column" }} >
                <Typography variant="h5" mb={3}>Assign Beneficiaries Followup</Typography>
                <Box mb={3}>
                    <AsyncDropDown


                        lazyFun={async (para) => await getUserApi({ ...para, role: USER_ROLES.SIT_TEAM })}

                        OptionComponent={({ option, ...rest }) => {
                            return <ListItem {...rest}>{option.name}</ListItem >
                        }}
                        onChange={async (changedVal) => {
                            setFields({ ...fields, assigned_to: changedVal ? changedVal._id : null, })
                        }}
                        titleKey={'name'}
                        valueKey={"_id"}
                        InputComponent={(params) => <StyledSearchBar placeholder={"Assign Person*"} {...params} margin="none" />}
                    />
                </Box>
                <Box component="form" onSubmit={onSubmit} sx={{ width: '100%', display: "flex", flex: 1, flexDirection: "column" }} mb={3} >
                    {
                        loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                            <Box sx={(theme) => ({ height: "100%", width: "100%", display: "flex", border: "1px solid", overflow: "hidden", [theme.breakpoints.down('md')]: { flexDirection: "column" } })}>

                                <Box sx={{ display: "flex", flex: 1, overflow: "hidden", flexDirection: "column" }}>
                                    <Box sx={{ width: "100%" }}>
                                        <ButtonGroup disableElevation variant="contained" sx={{ width: "100%", display: "flex", "*": { display: "flex", flex: 1, borderRadius: 0 } }}>
                                            <Button onClick={onAddFromCampClick} >Add From Camp</Button>
                                            <Button onClick={onAddFromRegionClick}>Add From Region</Button>
                                            <Button onClick={onEventClick}>Add From Event</Button>
                                        </ButtonGroup>
                                    </Box>
                                    <Box p={2} sx={{ display: "flex" }}>
                                        <Typography variant="body2" fontWeight="bold" >
                                            Assigned Beneficiaries:({benficiaryList && benficiaryList.length})
                                        </Typography>
                                    </Box>
                                    <Box p={2} sx={{ display: "flex", overflowY: "auto", flexWrap: "wrap", justifyContent: "flex-start", alignItems: "flex-start" }}>

                                        {
                                            benficiaryList && Array.isArray(benficiaryList) && benficiaryList.length > 0 ? <>

                                                {
                                                    benficiaryList.map((item) => <Chip onDelete={() => { onDeleteBeneficiary(item._id) }} sx={{ m: 1 }} onClick={() => {
                                                        onBenificaryClick(item._id)
                                                    }} key={item._id} label={item._id ? (item.name + " " + item.surname) : null} />)
                                                }

                                            </> : <NoDataComponent Icon={NoAccounts} message="No Beneficiary Assigned" />
                                        }
                                    </Box>
                                </Box>
                            </Box>
                    }

                    <SubmitButton type='submit' loading={loading} title="Assign" sx={{mt:2}} />
                </Box>
            </Paper>
        </Box>

    )

}
export default AssignBeneficiaryFollowupUi