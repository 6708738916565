import CustomInput from "../../../components/inputs/CustomInput"

import FileInput from "../../../components/inputs/FileInput"

const DonorFields = ({ fields, setFields, loading }) => {
  return <>

    <CustomInput
      disabled={loading}
      value={fields.head_name}
      onChange={(e) => setFields({ ...fields, err: '', head_name: e.target.value })}

      type="text"
      label={"Company Head Name*"}

    />


    <CustomInput
      disabled={loading}
      value={fields.company_description}
      onChange={(e) => setFields({ ...fields, err: '', company_description: e.target.value })}
      multiline
      rows={3}
      type="text"
      label={"Company Description*"}

    />
    <CustomInput
      disabled={loading}
      value={fields.contact_person_name}
      onChange={(e) => setFields({ ...fields, err: '', contact_person_name: e.target.value })}

      type="text"
      label={"Contact Person Name*"}

    />
    <CustomInput
      disabled={loading}
      value={fields.contact_person_phone}
      onChange={(e) => setFields({ ...fields, err: '', contact_person_phone: e.target.value })}

      type="tel"
      label={"Contact Person Phone*"}

    />
    <FileInput
      defaults={fields.photo ? [fields.photo] : undefined}
      title="Donor Company Logo"
      subTitle="Only png, jpg, jpeg are required."
      onlyImage
      onChange={(newUrl) => {
        setFields({ ...fields, photo: newUrl })
      }} />


  </>
}
export default DonorFields